"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkflowActionV2 = exports.ScreenshotAction = exports.OpenAction = void 0;
var zod_1 = require("zod");
var keyboard_shortcut_1 = require("./keyboard-shortcut");
var open_1 = require("./open");
var screenshot_1 = require("./screenshot");
__exportStar(require("./keyboard-shortcut"), exports);
var open_2 = require("./open");
Object.defineProperty(exports, "OpenAction", { enumerable: true, get: function () { return open_2.OpenAction; } });
var screenshot_2 = require("./screenshot");
Object.defineProperty(exports, "ScreenshotAction", { enumerable: true, get: function () { return screenshot_2.ScreenshotAction; } });
exports.WorkflowActionV2 = zod_1.z.union([
    keyboard_shortcut_1.KeyboardShortcutAction,
    open_1.OpenAction,
    screenshot_1.ScreenshotAction,
]);
