import React, { type ReactNode } from 'react';
import MuiSelect, { type SelectProps } from '@mui/material/Select';
import MenuItem from '../Menu/MenuItem';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import Radio from '@mui/material/Radio';
import Wrapper from '../Input/Wrapper';
import type { Classes } from '../Input/types';

interface ModifiedInputBaseProps {
  value: string | string[];
  doesInputHaveValue: (value: string | string[]) => boolean;
}

const ModifiedInputBase = styled(InputBase)<ModifiedInputBaseProps>(({
  theme,
  value,
  doesInputHaveValue,
}) => {
  const inputHasValue = doesInputHaveValue(value);

  return {
    '& .MuiInputBase-input': {
      border: `1px solid ${inputHasValue ? theme.palette.secondary.light : '#e6e7eb'}`,
      backgroundColor: inputHasValue ? '#f7f9ff' : '#ffffff',
      fontSize: 14,
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      width: '9rem',
      padding: '1.25rem 1.625rem 0.875rem 0.75rem',
      height: 'auto',
      minHeight: '1.4em',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      userSelect: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      font: 'inherit',
      letterSpacing: 'inherit',
      animationDuration: '10ms',

      '&:focus': {
        borderRadius: 4,
        borderColor: 'primary.light',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  };
});

interface RadioSelectProps {
  options: string[];
  isChecked: (item: string) => boolean;
  getFilterTitle: (item: string) => ReactNode;
  getFilterListValue: (item: string) => ReactNode;
  getIsItemPersistent: (item: string) => boolean;
  classes?: Classes;
  label?: string | ReactNode;
  floatingLabel?: boolean;
  errorText?: string;
  showErrorText?: boolean;
  required?: boolean;
}

type ExtendedRadioSelectProps = RadioSelectProps &
  Pick<ModifiedInputBaseProps, 'doesInputHaveValue'>;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 400,
    },
    sx: {
      '& .MuiTypography-body1': {
        fontSize: 14,
      },
    },
  },
  BackdropProps: {
    classes: { root: 'no-backdrop' },
  },
};

export default function CheckboxSelect(
  props: ExtendedRadioSelectProps & SelectProps,
) {
  const {
    options,
    isChecked,
    getFilterListValue,
    getIsItemPersistent,
    doesInputHaveValue,
    placeholder,
    classes,
    label,
    floatingLabel,
    error,
    errorText,
    showErrorText,
    required,
    id,
    ...rest
  } = props;

  return (
    <Wrapper
      className={classes?.wrapper}
      error={error}
      errorText={errorText}
      floatingLabel={floatingLabel}
      id={id}
      label={label}
      required={required}
      showErrorText={showErrorText}
    >
      <MuiSelect
        {...rest}
        input={
          <ModifiedInputBase
            doesInputHaveValue={doesInputHaveValue}
            value={rest.value as string | string[]}
          />
        }
        style={{ fontSize: '14px' }}
        placeholder={placeholder}
        MenuProps={MenuProps}
        //   label={rest.label}
        {...rest}
      >
        {options.map((item, index) => (
          <MenuItem key={item} value={item}>
            <RadioRow
              checked={isChecked(item)}
              label={getFilterListValue(item)}
              hasDivider={index === options.length - 1}
              getIsItemPersistent={getIsItemPersistent}
              value={item}
            />
          </MenuItem>
        ))}
      </MuiSelect>
    </Wrapper>
  );
}

function RadioRow({
  checked,
  label,
  hasDivider,
  onChange,
  getIsItemPersistent,
  value,
}: {
  checked: boolean;
  label: ReactNode;
  hasDivider?: boolean;
  onChange?: (newChecked: boolean) => void;
  getIsItemPersistent: (item: string) => boolean;
  value: string;
}) {
  const isItemPersistent = getIsItemPersistent(value);
  return (
    <div
      className="flex flex-row gap-2 items-center w-full"
      style={
        hasDivider
          ? {
              borderTop: '1px solid #bdcad5',
            }
          : {}
      }
      {...(isItemPersistent
        ? {
            role: 'presentation',
            onClick: (e) => {
              e.preventDefault();
              e.stopPropagation();
            },
          }
        : {})}
    >
      <div>
        <Radio
          checked={checked}
          onChange={(e, newChecked) => {
            onChange?.(newChecked);
          }}
          value="a"
          name="radio-buttons"
          inputProps={{ 'aria-label': 'A' }}
        />
      </div>
      <div>{label}</div>
    </div>
  );
}
