import {
  type UseQueryResult,
  useQuery,
  useQueryClient,
  useQueries,
} from '@tanstack/react-query';
import { type GetParagonUserTokenResponse } from 'api-types-shared';
import { useAPI } from '../../hooks/useApi';
import { handleException } from 'sentry-browser-shared';
import { useEffect, useState, useCallback } from 'react';
import {
  type AuthenticatedConnectUser,
  type IConnectCredential,
  type InstallIntegrationOptions,
  type InstallOptions,
  ParagonIntegrationEnum,
  SDK_EVENT,
} from 'dashboard-shared';
import { paragonProjectId } from '../../utils/env';
import { notify, AlertVariant } from 'ui-kit';

export const useGetParagonUserToken = (
  userId?: string,
): UseQueryResult<GetParagonUserTokenResponse> => {
  const { miscSDK: sdk } = useAPI();

  return useQuery({
    queryKey: ['paragonToken', userId],
    queryFn: async () => {
      try {
        const response = await sdk.getParagonUserToken(userId);
        if (!response.token) {
          throw new Error('No token received from Paragon service');
        }
        return response;
      } catch (err: unknown) {
        handleException(err, {
          name: 'Paragon user token fetch failed',
          source: 'useGetParagonUserToken',
          extra: { endpoint: sdk.endpoint, userId },
        });
        throw err;
      }
    },
    retry: 2,
    staleTime: 60 * 60 * 1000,
  });
};

export const useParagonAuth = (
  paragonUserToken?: string,
): UseQueryResult<AuthenticatedConnectUser> => {
  const { paragonSDK: sdk } = useAPI();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!paragonUserToken) return;

    const events = [
      SDK_EVENT.ON_INTEGRATION_INSTALL,
      SDK_EVENT.ON_INTEGRATION_UNINSTALL,
    ] as const;

    const handleIntegrationEvent = () => {
      const promise = queryClient.invalidateQueries({
        queryKey: ['paragonAuth', paragonUserToken],
        exact: true,
      });
      void promise;
    };

    events.forEach((event) => {
      sdk.subscribe(event, handleIntegrationEvent);
    });
    return () => {
      events.forEach((event) => {
        sdk.unsubscribe(event, handleIntegrationEvent);
      });
    };
  }, [sdk, paragonUserToken, queryClient]);

  return useQuery({
    queryKey: ['paragonAuth', paragonUserToken],
    queryFn: async () => {
      try {
        if (!paragonUserToken) {
          throw new Error('No authentication token available');
        }

        await sdk.authenticate(paragonProjectId, paragonUserToken);
        const authedUser = sdk.getUser();

        if (!authedUser?.authenticated) {
          throw new Error('Failed to authenticate user');
        }

        return authedUser;
      } catch (err: unknown) {
        handleException(err, {
          name: 'Failed to authenticate Paragon user',
          source: 'useParagonAuth',
          extra: {
            hasToken: Boolean(paragonUserToken),
            projectId: paragonProjectId,
          },
        });
        throw err;
      }
    },
    retry: false,
    enabled: Boolean(paragonUserToken),
    staleTime: 5 * 60 * 1000, // 5 minutes
  });
};

interface AccountIdentifier {
  primary: string;
  secondary?: string;
}

interface AccountIdentifiersResult {
  data: Record<string, AccountIdentifier>;
  isLoading: boolean;
}

interface AccountIdentifierResult {
  id: string;
  data?: AccountIdentifier;
  error?: unknown;
}

export const useAccountIdentifiers = (
  accounts: IConnectCredential[],
  integrationType: ParagonIntegrationEnum,
): AccountIdentifiersResult => {
  const { paragonSDK } = useAPI();
  const queryClient = useQueryClient();

  // Subscribe to integration install events to refresh identifiers
  useEffect(() => {
    const handleIntegrationInstall = () => {
      // Invalidate all account identifier queries for this integration type
      const promise = queryClient.invalidateQueries({
        queryKey: ['accountIdentifier'],
      });
      void promise;
    };

    try {
      paragonSDK.subscribe(
        SDK_EVENT.ON_INTEGRATION_INSTALL,
        handleIntegrationInstall,
      );
    } catch (err: unknown) {
      handleException(err, {
        name: 'Failed to subscribe to integration install events',
        source: 'useAccountIdentifiers.subscribe',
        extra: { integrationType },
      });
    }

    return () => {
      try {
        paragonSDK.unsubscribe(
          SDK_EVENT.ON_INTEGRATION_INSTALL,
          handleIntegrationInstall,
        );
      } catch (err: unknown) {
        handleException(err, {
          name: 'Failed to unsubscribe from integration install events',
          source: 'useAccountIdentifiers.cleanup',
          extra: { integrationType },
        });
      }
    };
  }, [paragonSDK, queryClient, integrationType]);

  // Use parallel queries for better caching - each account gets its own cache entry
  const queries = useQueries({
    queries: accounts.map((account) => ({
      queryKey: ['accountIdentifier', account.id, integrationType],
      queryFn: async (): Promise<AccountIdentifierResult> => {
        try {
          const identifier = await paragonSDK.getAccountIdentifier(
            account,
            integrationType,
          );
          return {
            id: account.id,
            data: identifier,
          };
        } catch (err: unknown) {
          handleException(err, {
            name: 'Failed to get account identifier',
            source: 'useAccountIdentifiers',
            extra: { accountId: account.id, integrationType },
          });
          return { id: account.id, error: err };
        }
      },
      enabled: accounts.length > 0,
      staleTime: 60 * 1000,
      retry: 2,
    })),
  });

  // Combine the results into the expected format
  const isLoading = queries.some((query) => query.isLoading);
  const data = queries.reduce<Record<string, AccountIdentifier>>(
    (acc, query) => {
      // Only process the result if it exists
      if (query.data) {
        const result = query.data; // TypeScript now knows this is AccountIdentifierResult
        if (result.data) {
          acc[result.id] = result.data;
        }
      }
      return acc;
    },
    {},
  );

  return {
    data,
    isLoading,
  };
};

interface UseAccountOperationsResult {
  disconnectingIds: Set<string>;
  reconnectingIds: Set<string>;
  isAddingAccount: boolean;
  handleDisconnect: (accountId: string) => Promise<void>;
  handleAddAccount: () => Promise<void>;
  handleReconnect: (accountId: string) => Promise<void>;
}

const getRedirectOptions = (
  integrationType: ParagonIntegrationEnum,
): InstallOptions => {
  return [
    ParagonIntegrationEnum.GMAIL,
    ParagonIntegrationEnum.GOOGLE_DRIVE,
  ].includes(integrationType)
    ? { overrideRedirectUrl: `${window.location.origin}/paragon` }
    : {};
};

export const useAccountOperations = (
  integrationType: ParagonIntegrationEnum,
): UseAccountOperationsResult => {
  const { paragonSDK: sdk } = useAPI();
  const [disconnectingIds, setDisconnectingIds] = useState<Set<string>>(
    new Set(),
  );
  const [reconnectingIds, setReconnectingIds] = useState<Set<string>>(
    new Set(),
  );
  const [isAddingAccount, setIsAddingAccount] = useState(false);

  const handleDisconnect = useCallback(
    async (accountId: string) => {
      try {
        setDisconnectingIds((prev) => new Set(prev).add(accountId));
        await sdk.uninstallIntegration(integrationType, {
          selectedCredentialId: accountId,
        });
        notify({
          message: 'Account disconnected successfully',
          variant: AlertVariant.SUCCESS,
        });
      } catch (err: unknown) {
        handleException(err, {
          name: 'Failed to disconnect account',
          source: 'useAccountOperations.handleDisconnect',
          extra: { accountId, integrationType },
        });
        notify({
          message: 'Failed to disconnect account',
          variant: AlertVariant.ERROR,
        });
      } finally {
        setDisconnectingIds((prev) => {
          const next = new Set(prev);
          next.delete(accountId);
          return next;
        });
      }
    },
    [integrationType, sdk],
  );

  const handleAddAccount = useCallback(async () => {
    try {
      setIsAddingAccount(true);
      await sdk.installIntegration(integrationType, {
        ...getRedirectOptions(integrationType),
        allowMultipleCredentials: true,
      });
      notify({
        message: 'Account connected successfully',
        variant: AlertVariant.SUCCESS,
      });
    } catch (err) {
      handleException(err, {
        name: 'Failed to install new Paragon account',
        source: 'useAccountOperations.handleAddAccount',
        extra: { integrationType },
      });
      notify({
        message: 'Failed to connect account',
        variant: AlertVariant.ERROR,
      });
      setIsAddingAccount(false); // Only set to false on error, success is handled by event
    }
  }, [integrationType, sdk]);

  const handleReconnect = useCallback(
    async (selectedCredentialId: string) => {
      try {
        setReconnectingIds((prev) => new Set(prev).add(selectedCredentialId));
        await sdk.installIntegration(integrationType, {
          selectedCredentialId,
          allowMultipleCredentials: true,
          ...getRedirectOptions(integrationType),
        } as InstallIntegrationOptions);
        notify({
          message: 'Account connected successfully',
          variant: AlertVariant.SUCCESS,
        });
      } catch (err: unknown) {
        handleException(err, {
          name: 'Failed to reconnect account',
          source: 'useAccountOperations.handleReconnect',
          extra: { selectedCredentialId, integrationType },
        });
        notify({
          message: 'Failed to reconnect account',
          variant: AlertVariant.ERROR,
        });
      } finally {
        setReconnectingIds((prev) => {
          const next = new Set(prev);
          next.delete(selectedCredentialId);
          return next;
        });
      }
    },
    [integrationType, sdk],
  );

  // Handle portal events
  useEffect(() => {
    const handlePortalClose = () => {
      setIsAddingAccount(false);
    };

    const handleInstallSuccess = () => {
      setIsAddingAccount(false);
    };

    try {
      sdk.subscribe(SDK_EVENT.ON_PORTAL_CLOSE, handlePortalClose);
      sdk.subscribe(SDK_EVENT.ON_INTEGRATION_INSTALL, handleInstallSuccess);
    } catch (err: unknown) {
      handleException(err, {
        name: 'Failed to subscribe to Paragon events',
        source: 'useAccountOperations.subscribe',
        extra: {
          events: [SDK_EVENT.ON_PORTAL_CLOSE, SDK_EVENT.ON_INTEGRATION_INSTALL],
        },
      });
    }

    return () => {
      try {
        sdk.unsubscribe(SDK_EVENT.ON_PORTAL_CLOSE, handlePortalClose);
        sdk.unsubscribe(SDK_EVENT.ON_INTEGRATION_INSTALL, handleInstallSuccess);
      } catch (err: unknown) {
        handleException(err, {
          name: 'Failed to unsubscribe from Paragon events',
          source: 'useAccountOperations.cleanup',
          extra: {
            events: [
              SDK_EVENT.ON_PORTAL_CLOSE,
              SDK_EVENT.ON_INTEGRATION_INSTALL,
            ],
          },
        });
      }
    };
  }, [sdk]);

  return {
    disconnectingIds,
    reconnectingIds,
    isAddingAccount,
    handleDisconnect,
    handleAddAccount,
    handleReconnect,
  };
};
