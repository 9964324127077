import { SignalTypeEnum } from 'api-types-shared';
import {
  Tooltip,
  IconButton,
  Button,
  HelpOutlineOutlined,
  modalEventChannel,
  ReplayIcon,
} from 'ui-kit';
import { executionSignalsConfig, FeatureFlag } from '../../../utils/constants';
import { contactModalEventChannel } from '../../../utils/contactModal';
import { isAdmin } from '../../../utils/env';
import { useFeatureFlag } from '../../../utils/helper';
import { useRetryExecution } from '../hooks';
import { type ScreenshotUrl } from '../utils';
import { useMemo } from 'react';
import { NodeTypesEnum } from 'types-shared/workflowTypes';

const modalTitles: Partial<Record<SignalTypeEnum, string>> = {
  [SignalTypeEnum.Done]: 'Mark execution as success',
  [SignalTypeEnum.Stop]: 'Cancel execution',
  [SignalTypeEnum.Terminate]: 'Terminate execution',
};

interface ExecutionControlsProps {
  selectedIndex?: number;
  screenshotUrls: ScreenshotUrl[];
  isSignalLoading: boolean;
  executionInFinalState: boolean;
  downloadingExecution: boolean;
  onSignalClick: (signalType: SignalTypeEnum) => void;
  setDownloadingExecution: (newValue: boolean) => void;
  workflowId: string;
  workflowName?: string;
  allowExecutionRetry: boolean;
  executionId: string;
  refetchExecutionDetails: () => Promise<unknown>;
  canResumeExecution: boolean;
}
export default function ExecutionControls({
  selectedIndex,
  screenshotUrls,
  workflowId,
  isSignalLoading,
  executionInFinalState,
  executionId,
  onSignalClick,
  downloadingExecution,
  setDownloadingExecution,
  workflowName,
  allowExecutionRetry,
  refetchExecutionDetails,
  canResumeExecution,
}: ExecutionControlsProps) {
  const demoFeatureEnabled = useFeatureFlag(FeatureFlag.DemoFeatures);
  const { mutateAsync: retryExecution, status: retryExecutionStatus } =
    useRetryExecution({ retryInPlace: isAdmin });
  const retryingExecution = retryExecutionStatus === 'pending';
  const _selectedIndex = useMemo(() => {
    if (selectedIndex === -1) {
      return screenshotUrls.length - 1;
    }
    return selectedIndex ?? 0;
  }, [selectedIndex, screenshotUrls]);

  const selectedScreenshot = useMemo(() => {
    return screenshotUrls[_selectedIndex] ?? {};
  }, [_selectedIndex, screenshotUrls]);

  const selectedItemType = selectedScreenshot.nodeType;

  const showWarning = (currentSignal: SignalTypeEnum) => {
    modalEventChannel.emit('open', {
      title: modalTitles[currentSignal] ?? '',
      descriptions: [
        `Are you sure you want to ${executionSignalsConfig.find((s) => s.signal === currentSignal)?.tooltipText.toLowerCase() ?? ''}?`,
      ],
      actions: [
        {
          text: 'Continue',
          variant: 'contained',
          onClick: () => {
            modalEventChannel.emit('close');
            onSignalClick(currentSignal);
          },
        },
        {
          text: 'Cancel',
          variant: 'outlined',
          onClick: () => {
            modalEventChannel.emit('close');
          },
        },
      ],
    });
  };

  const handleExecutionRetry = async () => {
    await retryExecution({ executionId });
    void refetchExecutionDetails();
  };

  return (
    <div className="flex items-center space-x-2">
      {isAdmin
        ? executionSignalsConfig.map(({ Icon, signal, tooltipText }) => {
            const disabled =
              isSignalLoading ||
              executionInFinalState ||
              ((!canResumeExecution ||
                selectedItemType === NodeTypesEnum.Conditional) &&
                signal === SignalTypeEnum.Resume);

            return (
              <Tooltip
                title={
                  executionInFinalState
                    ? "Can't send signals to execution in Queue, Failed, Terminated, or Success"
                    : tooltipText
                }
                key={signal}
              >
                <span>
                  <IconButton
                    className={disabled ? 'opacity-50' : ''}
                    onClick={() => {
                      if (
                        [
                          SignalTypeEnum.Done,
                          SignalTypeEnum.Terminate,
                          SignalTypeEnum.Stop,
                        ].includes(signal)
                      ) {
                        showWarning(signal);
                      } else {
                        onSignalClick(signal);
                      }
                    }}
                    disabled={disabled}
                  >
                    <Icon className="!w-5 !h-5 !text-black hover:!text-info" />
                  </IconButton>
                </span>
              </Tooltip>
            );
          })
        : null}
      <Tooltip
        title={
          allowExecutionRetry
            ? 'Retry Execution'
            : "Can't retry execution not in Success, Failed, Timeout, Canceled, or Terminated state"
        }
      >
        <span>
          <IconButton
            className={
              !allowExecutionRetry || retryingExecution ? 'opacity-50' : ''
            }
            onClick={handleExecutionRetry}
            disabled={!allowExecutionRetry || retryingExecution}
          >
            <ReplayIcon className="!-scale-x-100 !rotate-45 !text-black hover:!text-info" />
          </IconButton>
        </span>
      </Tooltip>
      {demoFeatureEnabled ? (
        <Button
          color="secondary"
          loading={downloadingExecution}
          onClick={() => {
            setDownloadingExecution(true);
            setTimeout(() => {
              setDownloadingExecution(false);
            }, 10000);
          }}
          variant="contained"
        >
          Download Video
        </Button>
      ) : null}
      <IconButton
        className="order-5 ml-2 rounded-lg group"
        onClick={() => {
          contactModalEventChannel.emit('open', {
            workflowId,
            workflowName,
          });
        }}
      >
        <HelpOutlineOutlined className="!w-5 !h-5 text-info" />
      </IconButton>
    </div>
  );
}
