import { useFeatureFlagPayload } from 'posthog-js/react';
import { FeatureFlag } from '../../../utils/constants';
import { useFeatureFlag } from '../../../utils/helper';

export default function useLiveViewBasedonWorkflow() {
  const liveViewBasedOnWorkflowEnabled = useFeatureFlag(
    FeatureFlag.LiveViewBasedonWorkflow,
  );
  const liveViewBasedonWorkflowPayload = useFeatureFlagPayload(
    FeatureFlag.LiveViewBasedonWorkflow,
  ) as { workflowIds: string[] } | undefined;
  return {
    workflowIds: liveViewBasedonWorkflowPayload?.workflowIds,
    liveViewBasedOnWorkflowEnabled,
  };
}
