import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {
  FormControlLabel,
  MailIcon,
  Radio,
  RadioGroup,
  Switch,
  Input,
  Tooltip,
  WarningAmberOutlined,
  Button,
  notify,
  AlertVariant,
} from 'ui-kit';
import { type ReactNode, useEffect, useState, useMemo } from 'react';
import {
  convertNotificationDataToBackendPayload,
  convertNotificationDataToUpdatePayload,
  convertBackendPayloadToNotificationData,
  type NotificationData,
  NotificationTabIdEnum,
  NotifyAboutEnum,
} from '../helper';
import {
  useCreateWorkflowNotification,
  useGetWorkflowNotifications,
  useGetTeamSlackChannelId,
  useGetSlackChannelName,
  useDeleteNotification,
  useUpdateNotification,
} from '../useNotificationData.gql';
import { handleException } from 'sentry-browser-shared';
import { isEqual, pick } from 'lodash';
import Skeleton from '@mui/material/Skeleton';
import {
  // eslint disable next line camelcase
  Notifications_Enum_Platform_Enum as NotificationsEnumPlatformEnum,
  // eslint disable next line camelcase
  type Notifications_Workflow_Configuration_Events_Insert_Input as NotificationsWorkflowConfigurationEventsInsertInput,
} from 'hasura-gql';
import { SlackPlatformMetadataSchema } from 'types-shared';

const defaultNotificationData: NotificationData = {
  slackChannel: '',
  slackNotificationEmails: [],
  notifyAbout: NotifyAboutEnum.Custom,
  failedExecutions: false,
  improvementDetected: false,
  successfulExecutions: false,
  workflowExecutionCompleted: false,
};

const URGENT_NOTIFICATION_STATE = {
  failedExecutions: true,
  improvementDetected: true,
  successfulExecutions: false,
} as const;

export default function Notifications({
  workflowId,
  ownerId,
}: {
  workflowId: string;
  ownerId: string;
}) {
  const [saveAttempted, setSaveAttempted] = useState(false);
  const { channelId, loading: channelIdLoading } =
    useGetTeamSlackChannelId(workflowId);

  const {
    notifications,
    loading: fetchNotificationsLoading,
    refetchNotifications,
  } = useGetWorkflowNotifications(workflowId);

  const activeNotification = useMemo(() => {
    return notifications.find((notification) => !notification.deletedAt);
  }, [notifications]);

  const maybeMetadata = activeNotification?.platformMetadata;
  const conversationId = maybeMetadata ? maybeMetadata.conversationId : '';

  const { channelName, loading: channelNameLoading } = useGetSlackChannelName(
    conversationId ? conversationId : channelId,
  );

  const { createNotificationConfig, loading: notificationCreationLoading } =
    useCreateWorkflowNotification();

  const { deleteNotificationConfig, loading: deleteNotificationLoading } =
    useDeleteNotification();
  const [selectedTab, setSelectedTab] = useState<NotificationTabIdEnum>(
    NotificationTabIdEnum.Slack,
  );
  const {
    updateNotificationConfig,

    loading: updateNotificationLoading,
  } = useUpdateNotification();

  const isLoading =
    fetchNotificationsLoading ||
    notificationCreationLoading ||
    updateNotificationLoading;
  const [notificationData, setNotificationData] = useState<NotificationData>(
    defaultNotificationData,
  );

  const channelLoading =
    channelIdLoading || channelNameLoading || deleteNotificationLoading;

  const isUrgentMode = useMemo(() => {
    return isEqual(
      pick(notificationData, Object.keys(URGENT_NOTIFICATION_STATE)),
      URGENT_NOTIFICATION_STATE,
    );
  }, [notificationData]);

  const isWorkflowExecutionCompleted = useMemo(() => {
    return (
      notificationData.failedExecutions || notificationData.successfulExecutions
    );
  }, [
    notificationData.failedExecutions,
    notificationData.successfulExecutions,
  ]);

  const slackActive = useMemo(() => {
    return [
      // notificationData.improvementDetected,
      notificationData.successfulExecutions,
      notificationData.failedExecutions,
    ].some((v) => v);
  }, [
    notificationData.successfulExecutions,
    notificationData.failedExecutions,
  ]);

  const [rawEmailInput, setRawEmailInput] = useState('');

  const emailsInvalid = useMemo(() => {
    const emailValidation =
      SlackPlatformMetadataSchema.shape.userEmails.element;
    return notificationData.slackNotificationEmails.some(
      (email) => !emailValidation.safeParse(email).success,
    );
  }, [notificationData.slackNotificationEmails]);

  const showEmailError = useMemo(() => {
    return emailsInvalid && saveAttempted;
  }, [emailsInvalid, saveAttempted]);

  const updateNotificationData = (
    key: keyof NotificationData | 'slackActive',
    value: string | boolean,
  ) => {
    switch (key) {
      case 'slackActive':
        setNotificationData((prev) => ({
          ...prev,
          failedExecutions: Boolean(value),
          improvementDetected: Boolean(value),
          successfulExecutions: Boolean(value),
        }));
        break;
      case 'notifyAbout':
        // Set to urgent default values
        if (value === NotifyAboutEnum.Urgent) {
          setNotificationData((prev) => ({
            ...prev,
            ...URGENT_NOTIFICATION_STATE,
          }));
        } else if (value === NotifyAboutEnum.Custom) {
          // Otherwise, custom sets to all true
          setNotificationData((prev) => ({
            ...prev,
            failedExecutions: true,
            improvementDetected: true,
            successfulExecutions: true,
          }));
        }
        break;

      // If the user enables/disables the workflowExecutionCompleted,
      // we correspondingly enable/disable the subtype-notifications: failedExecutions and successfulExecutions
      case 'workflowExecutionCompleted': {
        const newValue = Boolean(value);
        setNotificationData((prev) => ({
          ...prev,
          failedExecutions: newValue,
          successfulExecutions: newValue,
        }));
        break;
      }

      case 'slackNotificationEmails':
        if (typeof value === 'string') {
          setRawEmailInput(value);
          // Also update the actual data with processed emails
          const emails = value
            .split(',')
            .map((email) => email.trim())
            .filter((email) => email.length > 0);
          setNotificationData((prev) => ({
            ...prev,
            slackNotificationEmails: emails,
          }));
        }
        break;

      default:
        setNotificationData((prev) => ({ ...prev, [key]: value }));
    }
  };

  const onUpdateNotificationData = async () => {
    try {
      if (!activeNotification) {
        return;
      }
      const configId = activeNotification.events[0]?.configurationId;
      if (!configId) {
        return;
      }

      const notificationPayload = convertNotificationDataToUpdatePayload({
        workflowId,
        ownerId,
        notificationData,
        notificationId: activeNotification.id,
        configurationId: configId,
      });

      await updateNotificationConfig(notificationPayload);
      notify({
        message: 'Notification settings updated successfully',
        variant: AlertVariant.SUCCESS,
      });
    } catch (error) {
      handleException(error, {
        name: 'Failed to update notification config',
        source: 'Notification settings',
        extra: {
          workflowId,
          ownerId,
          notificationData,
        },
      });
    }
  };

  const onDeleteNotificationData = async () => {
    try {
      if (!activeNotification) {
        return;
      }
      const notificationPayload = convertNotificationDataToUpdatePayload({
        workflowId,
        ownerId,
        notificationData,
        notificationId: activeNotification.id,
        configurationId: activeNotification.events[0]?.configurationId,
      });

      await deleteNotificationConfig({
        ...notificationPayload,
        platform: NotificationsEnumPlatformEnum.Slack,
        events: (Array.isArray(notificationPayload.events)
          ? notificationPayload.events.map((e) => ({ event: e.event }))
          : []) as NotificationsWorkflowConfigurationEventsInsertInput[],
      });

      await refetchNotifications();
      setNotificationData(defaultNotificationData);
      notify({
        message: 'Notification settings deleted successfully',
        variant: AlertVariant.SUCCESS,
      });
      setNotificationData(defaultNotificationData);
    } catch (error) {
      handleException(error, {
        name: 'Failed to delete notification config',
        source: 'Notification settings',
        extra: {
          workflowId,
          ownerId,
          notificationData,
        },
      });
    }
  };

  const onSave = async () => {
    try {
      const notificationPayload = convertNotificationDataToBackendPayload({
        workflowId,
        notificationData,
        ownerId,
      });

      await createNotificationConfig(notificationPayload);

      notify({
        message: 'Notification settings saved successfully',
        variant: AlertVariant.SUCCESS,
      });
    } catch (error) {
      handleException(error, {
        name: 'Failed to save notification config',
        source: 'Notification settings',
        extra: {
          workflowId,
          ownerId,
          notificationData,
        },
      });
    }
  };

  const handleSaveChanges = async () => {
    setSaveAttempted(true);
    const isDelete = !slackActive;

    // Only fail if the emails are invalid and the user is saving a new config or updating an existing one
    if (emailsInvalid && slackActive) {
      notify({
        message: 'Please fix the invalid email addresses before saving.',
        variant: AlertVariant.ERROR,
      });
      return;
    }

    if (isDelete) {
      await onDeleteNotificationData();
    } else if (activeNotification?.id) {
      await onUpdateNotificationData();
    } else {
      await onSave();
    }

    setSaveAttempted(false);
  };

  const handleCancel = () => {
    const newDefault = activeNotification
      ? convertBackendPayloadToNotificationData(activeNotification)
      : defaultNotificationData;
    setNotificationData(newDefault);
    setRawEmailInput(newDefault.slackNotificationEmails.join(', '));
  };

  useEffect(() => {
    if (!notificationData.slackChannel && channelId) {
      updateNotificationData('slackChannel', channelId);
    }
  }, [channelId, notificationData.slackChannel]);

  useEffect(() => {
    const stillUsingDefault = isEqual(notificationData, {
      ...defaultNotificationData,
      slackChannel: channelId,
    });

    if (activeNotification && stillUsingDefault) {
      const newData =
        convertBackendPayloadToNotificationData(activeNotification);
      setNotificationData(newData);
      setRawEmailInput(newData.slackNotificationEmails.join(', '));
    }
  }, [notifications, notificationData, channelId, activeNotification]);

  return (
    <div className="flex py-8 space-x-10 gap-6">
      <div>
        <Box sx={{ display: 'flex' }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={selectedTab}
            onChange={(_, newValue) => {
              setSelectedTab(newValue as NotificationTabIdEnum);
            }}
            sx={{
              borderRight: 1,
              borderColor: 'divider',
              '& .MuiTab-root': {
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                gap: '12px',
                minHeight: '48px',
                textAlign: 'left',
                paddingRight: '6rem',
              },
              '& .MuiTabs-indicator': {
                backgroundColor: '#2196F3',
              },
              '& .Mui-selected': {
                color: '#2196F3 !important',
              },
            }}
          >
            <Tab
              icon={
                <img src="/slack_icon.png" alt="Slack" width={24} height={24} />
              }
              label="SLACK"
              iconPosition="start"
              value={NotificationTabIdEnum.Slack}
            />

            <Tooltip
              arrow
              placement="right"
              title="Customizable notifications here coming soon! Contact sales if you are interested."
            >
              <Tab
                icon={<MailIcon />}
                label="EMAIL"
                iconPosition="start"
                value={NotificationTabIdEnum.Email}
                disabled
              />
            </Tooltip>

            <Tooltip
              arrow
              placement="right"
              title="Customizable notifications here coming soon! Contact sales if you are interested."
            >
              <Tab
                icon={
                  <img
                    src="/teams_icon.png"
                    alt="teams"
                    width={24}
                    height={24}
                  />
                }
                label="TEAMS"
                iconPosition="start"
                value={NotificationTabIdEnum.Teams}
                disabled
              />
            </Tooltip>
          </Tabs>
        </Box>
      </div>
      <div className="w-full max-w-[800px] flex flex-col gap-5">
        <div className="bg-[#FFF4E5] flex space-x-2 px-4 py-3 rounded text-sm">
          <WarningAmberOutlined className="!w-5 !h-5 !text-warning !mt-0.5" />
          <span className="text-[#663C00]">
            For workflows with high execution volume, we recommend turning off
            notifications or enabling only urgent ones. Excessive notifications
            may trigger Slack's rate limits, causing delays in delivery.
          </span>
        </div>

        <SwitchRow
          checked={slackActive}
          onChange={(newChecked) => {
            updateNotificationData('slackActive', newChecked);
          }}
        >
          <h2 className="font-medium text-xl text-info-dark">
            Slack notifications
          </h2>
          <p className="text-sm text-gray-500 !mt-2">
            Set up notifications for your workflow to stay updated in real time.
          </p>
        </SwitchRow>

        <div>
          <h2 className="font-medium text-lg text-info-dark">Slack channel</h2>
          <p className="text-sm text-gray-500 mb-6 mt-2">
            This is the Slack channel where your team receives notifications.
            Below, you can configure who gets tagged in notifications. If you'd
            like to enable tag-only notifications, you can adjust this setting
            directly in Slack to limit visibility to tagged individuals.
            Otherwise, all notifications will be visible to the entire channel.
          </p>
          {channelLoading ? (
            <Skeleton variant="rounded" width={200} height={40} />
          ) : (
            <span className="text-sm text-info-dark bg-[#F5F5F5] rounded-lg p-4">
              #{channelName}
            </span>
          )}
        </div>

        <div
          className="flex flex-col gap-5"
          style={{ opacity: slackActive ? 1 : 0.5 }}
        >
          <div className="pt-2">
            <p className="text-sm text-gray-500 !mt-2 mb-2">
              Enter the email addresses of the people to tag in Slack
              notifications. You can add multiple addresses, separated by
              commas, to ensure they are notified directly.
            </p>
            <Input
              placeholder="Enter email addresses (e.g., user@sola.ai, user2@sola.ai)"
              label="Notification email recipients"
              floatingLabel
              showErrorText
              error={showEmailError}
              errorText="Some of the emails you entered are invalid. Please check and try again."
              value={rawEmailInput}
              onChange={(e) => {
                updateNotificationData('slackNotificationEmails', e);
              }}
              disabled={!slackActive}
            />
          </div>

          <h2 className="font-medium text-xl text-info-dark mt-5">
            Notify about
          </h2>
          <RadioGroup
            className="mt-2"
            name="radio-buttons-group"
            onChange={(_, val) => {
              updateNotificationData('notifyAbout', val as NotifyAboutEnum);
            }}
            value={
              isUrgentMode ? NotifyAboutEnum.Urgent : NotifyAboutEnum.Custom
            }
          >
            <FormControlLabel
              control={<Radio color="secondary" disabled={!slackActive} />}
              label={
                <span className="text-info-dark">
                  <span className="font-bold">Only Urgent:</span> Receive
                  notifications only for critical events.
                </span>
              }
              value={NotifyAboutEnum.Urgent}
            />
            <FormControlLabel
              control={<Radio color="secondary" disabled={!slackActive} />}
              label={
                <span className="text-info-dark">
                  <span className="font-bold">Customize Notifications:</span>{' '}
                  Choose specific events to be notified about.
                </span>
              }
              value={NotifyAboutEnum.Custom}
            />
          </RadioGroup>

          <div>
            <SwitchRow
              disabled={!slackActive}
              checked={isWorkflowExecutionCompleted}
              onChange={(newChecked) => {
                updateNotificationData(
                  'workflowExecutionCompleted',
                  newChecked,
                );
              }}
            >
              <h2 className="font-medium text-lg text-info-dark">
                Workflow execution completed
              </h2>
              <p className="text-sm text-gray-500 !mt-2">
                Receive a notification for every completed workflow execution.
              </p>
            </SwitchRow>

            <SwitchRow
              disabled={!slackActive}
              checked={notificationData.failedExecutions}
              onChange={(newChecked) => {
                updateNotificationData('failedExecutions', newChecked);
              }}
            >
              <h2 className=" text-info-dark pl-5">Failed executions</h2>
            </SwitchRow>

            <SwitchRow
              disabled={!slackActive}
              checked={notificationData.successfulExecutions}
              onChange={(newChecked) => {
                updateNotificationData('successfulExecutions', newChecked);
              }}
            >
              <h2 className=" text-info-dark pl-5">Successful executions</h2>
            </SwitchRow>
          </div>

          {/* <SwitchRow
          checked={notificationData.improvementDetected}
          onChange={(newChecked) => {
            updateNotificationData('improvementDetected', newChecked);
          }}
        >
          <h2 className="font-medium text-lg text-info-dark">
            Improvement detected
          </h2>
          <p className="text-sm text-gray-500 !mt-2">
            Receive a notification when the bot has implemented or detected a
            workflow improvement for your approval.
          </p>
        </SwitchRow> */}
        </div>

        <div className="flex items-center space-x-4">
          <Button
            color="secondary"
            disabled={isLoading}
            variant="contained"
            onClick={handleSaveChanges}
            loading={isLoading}
          >
            Save Changes
          </Button>
          <Button
            color="secondary"
            disabled={isLoading}
            variant="outlined"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
}

function SwitchRow({
  checked,
  onChange,
  children,
  disabled,
}: {
  checked: boolean;
  onChange: (newChecked: boolean) => void;
  children: ReactNode;
  disabled?: boolean;
}) {
  return (
    <div className="flex items-center gap-2 w-full justify-between mb-5">
      <div>{children}</div>
      <Switch
        color="secondary"
        checked={checked}
        onChange={(_, newChecked) => {
          onChange(newChecked);
        }}
        disabled={disabled}
      />
    </div>
  );
}
