"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationDefaults = void 0;
var types_1 = require("./types");
// eslint-disable-next-line @typescript-eslint/no-namespace -- for clarity
var NotificationDefaults;
(function (NotificationDefaults) {
    NotificationDefaults.WorkflowConfigurations = function (args, 
    // NOTE: By default, include all user and admin configurations
    selector) {
        if (selector === void 0) { selector = {
            user: { include: true },
            admin: { include: true, allowedTags: undefined },
        }; }
        var userConfigurations = selector.user.include
            ? NotificationDefaults.UserWorkflowConfigurations(args)
            : [];
        var adminConfigurations = selector.admin.include
            ? NotificationDefaults.AdminWorkflowConfigurations(args)
            : [];
        var allowedAdminTags = selector.admin.allowedTags;
        if (allowedAdminTags) {
            adminConfigurations = adminConfigurations.filter(function (config) {
                return allowedAdminTags.includes(config.tag);
            });
        }
        return __spreadArray(__spreadArray([], userConfigurations, true), adminConfigurations, true);
    };
    // MARK: Default Admin / non-user-configurable Configurations
    NotificationDefaults.AdminWorkflowConfigurations = function (_args) {
        return [
        // TODO: Add admin configurations here
        // TODO: Add non-user configurable configurations here
        ];
    };
    // MARK: Default User (configurable) Configurations
    NotificationDefaults.UserWorkflowConfigurations = function (args) {
        return [
            NotificationDefaults.UserSlackWorkflowConfiguration(args),
            // Add more default user configurations here (email, teams, etc.)
        ];
    };
    NotificationDefaults.UserSlackWorkflowConfiguration = function (args) {
        var userEmails = args.shouldTagOwner ? [args.ownerEmail] : [];
        return {
            tag: 'user',
            platform: types_1.PlatformType.Slack,
            platformMetadata: {
                platform: types_1.PlatformType.Slack,
                userEmails: userEmails,
                conversationId: args.teamChannelId,
            },
            events: [
                { event: types_1.WorkflowEventType.ExecutionFailed },
                { event: types_1.WorkflowEventType.ExecutionSuccess },
                // TODO: Add WorkflowImported
                // TODO: Add WorkflowProcessing
                // TODO: Add WorkflowImprovementDetected
            ],
        };
    };
})(NotificationDefaults || (exports.NotificationDefaults = NotificationDefaults = {}));
