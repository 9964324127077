export const isAdmin = Boolean(process.env.REACT_APP_IS_ADMIN);

export const workflowsTableName =
  // eslint-disable-next-line turbo/no-undeclared-env-vars
  process.env.REACT_APP_WORKFLOWS_TABLE_NAME ?? 'dev-workflows-metadata';
export const executionsMetadataTableName =
  process.env.REACT_APP_EXECUTIONS_METADATA_TABLE_NAME ??
  'dev-executions-metadata';
export const paragonRedirectUrl =
  process.env.REACT_APP_PARAGON_REDIRECT_URL ??
  'https://passport.useparagon.com/oauth';
export const paragonProjectId =
  // eslint-disable-next-line turbo/no-undeclared-env-vars
  process.env.REACT_APP_PARAGON_PROJECT_ID ?? 'paragon-dev';

export const mockUserId = process.env.REACT_APP_MOCK_USER_ID;

export const solaApiUrl = String(process.env.REACT_APP_SOLA_API_URL);

export const hasuraGraphQLEndpoint =
  process.env.REACT_APP_HASURA_GRAPHQL_ENDPOINT ?? 'http://localhost:7001';

export const adminAccessToken =
  process.env.REACT_APP_ADMIN_DASHBOARD_ACCESS_TOKEN;
export const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;
export const awsRegion = String(process.env.REACT_APP_COGNITO_REGION);
export const awsAccessKey = String(process.env.REACT_APP_ACCESS_KEY_ID);
export const awsSecretKey = String(process.env.REACT_APP_SECRET_KEY);
export const workflowDataBucket = String(
  process.env.REACT_APP_WORKFLOWS_BUCKET,
);
export const hasuraAdminSecret =
  process.env.REACT_APP_HASURA_GRAPHQL_ADMIN_SECRET ?? 'password';
export const executionBucket = String(process.env.REACT_APP_EXECUTIONS_BUCKET);

export const FALLBACK_SELENIUM_ADDRESS =
  process.env.REACT_APP_SELENIUM_REMOTE_URL_STAGING ??
  String(process.env.REACT_APP_SELENIUM_REMOTE_URL);

export const v2ApiEndpoint =
  process.env.REACT_APP_V2_API_ENDPOINT ?? 'http://localhost:5559';
