import { ActionsEnum } from 'types-shared/extensionTypes';
import { VariableTypeEnum } from 'types-shared/workflowTypes';
import type {
  WorkflowActionsOptions,
  Target,
  TemplateVariable,
  WorkflowActionV2Base,
  OpenAction,
  ScreenshotAction,
} from 'types-shared/workflowTypes';
import { v4 as uuid } from 'uuid';
import { createTarget } from '../../../utils/helper';

const createNotSetTemplateVariable = (
  id: string = uuid(),
): TemplateVariable => {
  return {
    id,
    data: ['Not Set'],
    type: VariableTypeEnum.Template,
  };
};

export const createBaseV2Action = (
  actionType: ActionsEnum,
  legacyOptions?: WorkflowActionsOptions,
): {
  variable: TemplateVariable; // Preview variable
  target: Target; // Dummy target for backwards compatibility
  action: WorkflowActionV2Base;
} => {
  const target = createTarget(uuid());
  const variable = createNotSetTemplateVariable();

  return {
    action: {
      id: uuid(),
      actionType,
      actionVersion: '2.0',
      // MARK: Backwards compatibility
      targetId: target.id,
      variableId: variable.id,
      options: legacyOptions,
    },
    target,
    variable,
  };
};

export const createDefaultOpenAction = (): {
  action: OpenAction;
  target: Target; // Dummy Target
  variable: TemplateVariable; // Variable for Uri param
} => {
  const target = createTarget(uuid());
  const variable = createNotSetTemplateVariable();
  return {
    action: {
      id: uuid(),
      actionType: ActionsEnum.Open,
      actionVersion: '2.0',
      variableId: variable.id,
      targetId: target.id,
      parameters: {
        uri: variable.data,
      },
      options: {
        adminOnly: true,
      },
      title: 'URI',
    },
    target,
    variable,
  };
};

export const createScreenshotAction = (
  legacyOptions?: WorkflowActionsOptions,
): {
  action: ScreenshotAction;
  target: Target; // Dummy Target
  variable: TemplateVariable; // Preview Variable
} => {
  const target = createTarget(uuid());
  const variable: TemplateVariable = {
    id: uuid(),
    data: ['None'],
    type: VariableTypeEnum.Template,
  };

  return {
    action: {
      id: uuid(),
      actionVersion: '2.0',
      actionType: ActionsEnum.Screenshot,
      parameters: {},
      // MARK: Backwards compatibility
      targetId: target.id,
      variableId: variable.id,
      options: legacyOptions,
      title: 'Delay',
    },
    target,
    variable,
  };
};
