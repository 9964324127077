import { useQuery, type ApolloError } from '@apollo/client';
import {
  GetWorkflowSchedulesDocument,
  type GetWorkflowSchedulesQuery,
} from 'hasura-gql';
import {
  createSchedule,
  createScheduleAdmin,
  updateSchedule,
  updateScheduleAdmin,
  deleteSchedule,
  deleteScheduleAdmin,
} from 'v2-api-client';
import { isAdmin } from '../../../utils/env';
import { validateVariables } from '../../../utils/hasura';
import { useMutation } from '@tanstack/react-query';
import { useV2Api } from '../../../providers/V2ApiProvider';

type ScheduleWithNameAndVariables =
  GetWorkflowSchedulesQuery['workflow_schedules'][number] & {
    name: string;
    // NOTE: Type may change post-codegen
    variables: Record<string, unknown> | undefined;
  };

export const useGetSchedules = (
  workflowId = '',
): {
  data?: ScheduleWithNameAndVariables[];
  refetch: () => Promise<unknown>;
  loading: boolean;
  error?: ApolloError;
} => {
  const { data, loading, error, refetch } = useQuery(
    GetWorkflowSchedulesDocument,
    {
      variables: { workflowId },
      skip: !workflowId,
    },
  );

  const schedules = data?.workflow_schedules.map((schedule) => ({
    ...schedule,
    variables: validateVariables(schedule.variables),
  }));

  return {
    data: schedules,
    loading,
    error,
    refetch,
  };
};

export const useCreateSchedule = () => {
  const client = useV2Api();
  return useMutation({
    mutationFn: async (data: {
      workflowId: string;
      rrule: string;
      ownerId?: string;
      name: string;
      variables: Record<string, string>;
    }) => {
      if (isAdmin) {
        if (!data.ownerId) {
          throw new Error('ownerId is required for admin operations');
        }
        const response = await createScheduleAdmin({
          client,
          body: {
            ...data,
            ownerId: data.ownerId,
            name: data.name,
            variables: data.variables,
          },
        });
        return response.data;
      }

      const response = await createSchedule({
        client,
        body: {
          workflowId: data.workflowId,
          rrule: data.rrule,
          name: data.name,
          variables: data.variables,
        },
      });
      return response.data;
    },
  });
};

export const useEditSchedule = () => {
  const client = useV2Api();
  return useMutation({
    mutationFn: async (data: {
      scheduleId: string;
      rrule: string;
      ownerId?: string;
      name: string;
      variables: Record<string, string>;
    }) => {
      if (isAdmin) {
        if (!data.ownerId) {
          throw new Error('ownerId is required for admin operations');
        }
        const response = await updateScheduleAdmin({
          client,
          body: {
            ...data,
            ownerId: data.ownerId,
            name: data.name,
            variables: data.variables,
          },
        });
        return response.data;
      }

      const response = await updateSchedule({
        client,
        body: {
          scheduleId: data.scheduleId,
          rrule: data.rrule,
          name: data.name,
          variables: data.variables,
        },
      });
      return response.data;
    },
  });
};

export const useDeleteSchedule = () => {
  const client = useV2Api();
  return useMutation({
    mutationFn: async (data: { scheduleId: string; ownerId?: string }) => {
      if (isAdmin) {
        if (!data.ownerId) {
          throw new Error('ownerId is required for admin operations');
        }
        const response = await deleteScheduleAdmin({
          client,
          body: { ...data, ownerId: data.ownerId },
        });
        return response.data;
      }

      const response = await deleteSchedule({
        client,
        body: {
          scheduleId: data.scheduleId,
        },
      });
      return response.data;
    },
  });
};
