import { useMemo, useState, useCallback, useEffect } from 'react';
import { ExecutionStatusEnum } from 'types-shared';
import { Button, Select, InputWithActiveBorder, CheckboxSelect } from 'ui-kit';
import { type SelectChangeEvent } from '@mui/material/Select';

import { isDeepEqual } from '@mui/x-data-grid/internals';
import { isAdmin } from '../../../utils/env';
import { hitlStatuses, statusTitleMapping } from '../../../utils/constants';
import {
  defaultFilters,
  type ExecutionListFilters,
  failedStatuses,
  runningStatuses,
} from '../utils/helper';
import uniq from 'lodash/uniq';
import upperFirst from 'lodash/upperFirst';
import debounce from '@mui/material/utils/debounce';
import ExecutionListDateFilter, {
  type DateFilterValue,
} from './ExecutionListDateFilter';
import { useGetTeams } from '../../Members/hooks.gql';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
    sx: {
      '& .MuiTypography-body1': {
        fontSize: 14,
      },
    },
  },
  BackdropProps: {
    classes: { root: 'no-backdrop' },
  },
};

const adminRunsOptions = ['All', 'true', 'false'];
const defaultStatus = 'Filter by status';

const defaultTeam = 'Filter by team';

interface ExecutionListFilterProps {
  filters: ExecutionListFilters;
  setFilters: (filters: ExecutionListFilters) => void;
  onRefresh: () => void;
  onResetPage: () => void;
  isGlobalSearch?: boolean;
}

export default function ExecutionListFilter({
  filters,
  setFilters,
  onResetPage,
  isGlobalSearch = false,
}: ExecutionListFilterProps) {
  const [id, setId] = useState<string>(filters.id ?? '');
  const [owner, setOwner] = useState<string>(filters.owner ?? '');
  const [workflowName, setWorkflowName] = useState<string>(
    filters.workflowName ?? '',
  );
  const [statusFilterUsed, setStatusFilterUsed] = useState(false);
  const [teamFilterUsed, setTeamFilterUsed] = useState(false);
  const [dateFilterValue, setDateFilterValue] = useState<DateFilterValue>();
  const { data: teams = [], loading: teamsLoading } = useGetTeams();

  const filteredTeams = useMemo(() => {
    return teams
      .filter((team) => !team.id.includes('healthcheck'))
      .map(({ name, clusterId }) =>
        clusterId ? `${name} (${clusterId})` : name,
      );
  }, [teams]);

  const teamNameToIdMap = useMemo(() => {
    return teams.reduce((acc: Record<string, string>, team) => {
      const name = team.clusterId
        ? `${team.name} (${team.clusterId})`
        : team.name;
      acc[name] = team.id;
      return acc;
    }, {});
  }, [teams]);

  const teamIdToNameMap = useMemo(() => {
    return teams.reduce((acc: Record<string, string>, team) => {
      const name = team.clusterId
        ? `${team.name} (${team.clusterId})`
        : team.name;
      acc[team.id] = name;
      return acc;
    }, {});
  }, [teams]);

  const teamFilterValue = useMemo(() => {
    return !teamFilterUsed &&
      filters.teams.length === 1 &&
      filters.teams[0] === 'All'
      ? ([defaultTeam] as unknown as string)
      : filters.teams.map((team) => teamIdToNameMap[team] ?? team);
  }, [filters.teams, teamFilterUsed, teamIdToNameMap]);

  const handleSetDateFilter = useCallback(
    (value: DateFilterValue | undefined) => {
      const payload = {
        ...filters,
        dateFrom:
          value?.dateRange?.[0] instanceof Date
            ? value.dateRange[0].toISOString()
            : value?.dateRange?.[0],
        dateTo:
          value?.dateRange?.[1] instanceof Date
            ? value.dateRange[1].toISOString()
            : value?.dateRange?.[1],
        dateQuery: value?.chosenFilter,
      };
      setFilters(payload);
    },
    [filters, setFilters],
  );

  useEffect(() => {
    const currentDateRange = dateFilterValue?.dateRange;
    const currentFilter = dateFilterValue?.chosenFilter;
    const newDateRange = [filters.dateFrom, filters.dateTo];
    const newFilter = filters.dateQuery;

    if (
      JSON.stringify(currentDateRange) !== JSON.stringify(newDateRange) ||
      currentFilter !== newFilter
    ) {
      setDateFilterValue({
        dateRange: newDateRange as DateFilterValue['dateRange'],
        chosenFilter: newFilter as DateFilterValue['chosenFilter'],
      });
    }
  }, [filters.dateFrom, filters.dateTo, filters.dateQuery, dateFilterValue]);

  const onClear = () => {
    setId('');
    setOwner('');
    setWorkflowName('');
    setFilters(defaultFilters);
    onResetPage();
    setDateFilterValue(undefined);
  };

  const updateFilters = (key: string, value: unknown) => {
    onResetPage();
    setFilters({
      ...filters,
      [key]: value,
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateFilters = useCallback(debounce(updateFilters, 300), [
    updateFilters,
  ]);

  const getFilterTitle = useCallback((status: string) => {
    const formattedStatus = status.toLowerCase().replace('_', ' ');
    const title = isAdmin
      ? formattedStatus
      : statusTitleMapping[status] ?? formattedStatus;

    if (status.toLowerCase() === defaultStatus.toLowerCase()) {
      return defaultStatus;
    }

    if ((status as ExecutionStatusEnum) === ExecutionStatusEnum.Retry) {
      return statusTitleMapping[status];
    }

    const result = isAdmin ? status : title;
    return upperFirst(result.toLowerCase()).replaceAll('_', ' ');
  }, []);

  const handleStatusChange = (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value },
    } = event;
    if (!statusFilterUsed) {
      setStatusFilterUsed(true);
    }
    let selectedStatuses =
      typeof value === 'string' ? value.split(',') : (value as string[]);

    if (!isAdmin) {
      if (selectedStatuses.includes(ExecutionStatusEnum.Running)) {
        selectedStatuses = selectedStatuses.concat(runningStatuses);
      } else {
        selectedStatuses = selectedStatuses.filter(
          (status) => !runningStatuses.includes(status as ExecutionStatusEnum),
        );
      }
      if (selectedStatuses.includes(ExecutionStatusEnum.Terminated)) {
        selectedStatuses = selectedStatuses.concat(failedStatuses);
      } else {
        selectedStatuses = selectedStatuses.filter(
          (status) => !failedStatuses.includes(status as ExecutionStatusEnum),
        );
      }
    }
    if (
      selectedStatuses[0] === defaultStatus ||
      selectedStatuses[0] === 'All'
    ) {
      selectedStatuses = selectedStatuses.slice(1);
    }
    if (selectedStatuses.length === 0) {
      selectedStatuses = ['All'];
    }
    debouncedUpdateFilters('status', uniq(selectedStatuses));
  };

  const handleTeamChange = (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value },
    } = event;
    if (!teamFilterUsed) {
      setTeamFilterUsed(true);
    }
    let selectedTeams =
      typeof value === 'string' ? value.split(',') : (value as string[]);

    if (selectedTeams.length === 0) {
      selectedTeams = ['All'];
    } else if (selectedTeams[0] === defaultTeam || selectedTeams[0] === 'All') {
      selectedTeams = selectedTeams
        .slice(1)
        .map((team) => teamNameToIdMap[team]);
    } else {
      selectedTeams = selectedTeams.map((team) => teamNameToIdMap[team]);
    }
    debouncedUpdateFilters('teams', uniq(selectedTeams));
  };

  const hasFilters = useMemo(
    () => !isDeepEqual(filters, defaultFilters),
    [filters],
  );

  const executionStatusOptions = useMemo(() => {
    let statusOpts: ExecutionStatusEnum[] | string[] = [];

    if (isAdmin) {
      statusOpts = Object.values(ExecutionStatusEnum);
    } else {
      statusOpts = Object.values(ExecutionStatusEnum).filter(
        (s) =>
          ![...runningStatuses, ...failedStatuses].includes(
            s as ExecutionStatusEnum,
          ),
      );
      statusOpts.push(ExecutionStatusEnum.Running);
      statusOpts.push(ExecutionStatusEnum.Terminated);
    }

    return statusOpts
      .filter((s) => !hitlStatuses.includes(s as ExecutionStatusEnum))
      .sort((a, b) => getFilterTitle(a).localeCompare(getFilterTitle(b)));
  }, [getFilterTitle]);

  return (
    <div className="w-full flex items-center space-x-4 mt-10 mb-5">
      <InputWithActiveBorder
        classes={{ root: 'flex-1' }}
        style={{ fontSize: '14px' }}
        isSearch
        floatingLabel
        label="Execution ID"
        placeholder="Search by Execution ID"
        onChange={(val) => {
          setId(val);
          debouncedUpdateFilters('id', val);
        }}
        value={id}
      />

      {isGlobalSearch ? (
        <InputWithActiveBorder
          classes={{ root: 'flex-1' }}
          style={{ fontSize: '14px' }}
          isSearch
          floatingLabel
          label="Workflow Name"
          placeholder="Search by Name"
          onChange={(val) => {
            setWorkflowName(val);
            debouncedUpdateFilters('workflowName', val);
          }}
          value={workflowName}
        />
      ) : null}

      <InputWithActiveBorder
        classes={{ root: 'flex-1' }}
        style={{ fontSize: '14px' }}
        isSearch
        floatingLabel
        label="Owner"
        placeholder="Search by Owner"
        onChange={(val) => {
          setOwner(val);
          debouncedUpdateFilters('owner', val);
        }}
        value={owner}
      />

      <CheckboxSelect
        // className="!mt-5"
        style={{ fontSize: '14px' }}
        multiple
        label={defaultStatus}
        floatingLabel
        placeholder={defaultStatus}
        value={
          !statusFilterUsed &&
          filters.status.length === 1 &&
          filters.status[0] === 'All'
            ? ([defaultStatus] as unknown as string)
            : (filters.status as unknown as string)
        }
        doesInputHaveValue={(value) => {
          let valueExists = false;
          if (Array.isArray(value)) {
            const valueArr = value;
            const isDefaultValue =
              valueArr.length === 1 &&
              (valueArr[0] === 'All' || valueArr[0] === defaultStatus);
            valueExists = Boolean(valueArr.length && !isDefaultValue);
          }
          if (typeof value === 'string') {
            valueExists = Boolean(value.length);
          }
          return valueExists;
        }}
        getFilterTitle={getFilterTitle}
        isChecked={(item) => filters.status.includes(item)}
        options={executionStatusOptions}
        onChange={handleStatusChange}
        renderValue={(selected) => {
          if (!Array.isArray(selected)) {
            return <>{String(selected)}</>;
          }
          const hasRunning = selected.includes(ExecutionStatusEnum.Running);
          const hasFailed = selected.includes(ExecutionStatusEnum.Terminated);
          const finalText = uniq(
            selected
              .filter(
                (item: ExecutionStatusEnum) =>
                  isAdmin ||
                  ((!hasRunning || !runningStatuses.includes(item)) &&
                    (!hasFailed || !failedStatuses.includes(item))),
              )
              .concat(hasRunning ? [ExecutionStatusEnum.Running] : [])
              .concat(hasFailed ? [ExecutionStatusEnum.Terminated] : [])
              .map((item) => getFilterTitle(item as string)),
          ).join(', ');

          return <>{finalText}</>;
        }}
        MenuProps={MenuProps}
      />

      {isGlobalSearch && isAdmin ? (
        <CheckboxSelect
          disabled={teamsLoading}
          // className="!mt-5"
          style={{ fontSize: '14px' }}
          multiple
          label={defaultTeam}
          floatingLabel
          placeholder={defaultTeam}
          value={teamFilterValue}
          doesInputHaveValue={(value) => {
            let valueExists = false;
            if (Array.isArray(value)) {
              const valueArr = value;
              const isDefaultValue =
                valueArr.length === 1 &&
                (valueArr[0] === 'All' || valueArr[0] === defaultTeam);
              valueExists = Boolean(valueArr.length && !isDefaultValue);
            }
            if (typeof value === 'string') {
              valueExists = Boolean(value.length);
            }
            return valueExists;
          }}
          getFilterTitle={getFilterTitle}
          isChecked={(item) => teamFilterValue.includes(item)}
          options={filteredTeams}
          onChange={handleTeamChange}
          renderValue={(selected) => {
            if (!Array.isArray(selected)) {
              return <>{String(selected)}</>;
            }
            const finalText = uniq(selected).join(', ');

            return <>{finalText}</>;
          }}
          MenuProps={MenuProps}
        />
      ) : null}

      {isAdmin ? (
        <Select
          className="min-w-56"
          getLabel={(op: string) => op}
          getValue={(op: string) => op}
          style={{ fontSize: '14px' }}
          label="Admin Runs"
          MenuProps={{
            sx: {
              '& .MuiTypography-body1': {
                fontSize: 14,
              },
            },
          }}
          onChange={(e) => {
            const value = e.target.value;
            const hideAdminRuns =
              value === 'All' ? undefined : value === 'true';
            debouncedUpdateFilters('hideAdminRuns', hideAdminRuns);
          }}
          options={adminRunsOptions}
          value={
            filters.hideAdminRuns === undefined
              ? 'All'
              : String(filters.hideAdminRuns)
          }
        />
      ) : null}

      <ExecutionListDateFilter
        filterValue={dateFilterValue}
        setFilterValue={handleSetDateFilter}
      />
      {/* DateTimePicker */}

      {hasFilters ? (
        <Button color="secondary" onClick={onClear} variant="text">
          Clear Filters
        </Button>
      ) : null}
    </div>
  );
}
