"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SUPPORTED_APPLICATIONS = exports.ActionPlatformEnum = void 0;
var ActionPlatformEnum;
(function (ActionPlatformEnum) {
    ActionPlatformEnum["Web"] = "web";
    ActionPlatformEnum["Windows"] = "windows";
})(ActionPlatformEnum || (exports.ActionPlatformEnum = ActionPlatformEnum = {}));
exports.SUPPORTED_APPLICATIONS = [
    { name: 'chrome', platform: ActionPlatformEnum.Web, adminOnly: true },
    { name: 'excel.exe', platform: ActionPlatformEnum.Windows, adminOnly: true },
    {
        name: 'filezilla.exe',
        platform: ActionPlatformEnum.Windows,
        adminOnly: true,
    },
];
