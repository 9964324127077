"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EXCEL_DATATAB_SHORCUTS = exports.ExcelDataTabShortcuts = void 0;
const types_shared_1 = require("types-shared");
const workflowTypes_1 = require("types-shared/workflowTypes");
const serializer_1 = require("../../serializer");
var ExcelDataTabShortcuts;
(function (ExcelDataTabShortcuts) {
    ExcelDataTabShortcuts["Filter"] = "Enable Filtering";
    ExcelDataTabShortcuts["RemoveDuplicates"] = "Remove Duplicates";
})(ExcelDataTabShortcuts || (exports.ExcelDataTabShortcuts = ExcelDataTabShortcuts = {}));
const action = new serializer_1.KeyboardActionSerializer(workflowTypes_1.KeyboardShortcutGroupEnum.ExcelDataTab);
const SHORTCUT_MAPPING = {
    [ExcelDataTabShortcuts.Filter]: [[types_shared_1.ALT, 'a', 't']],
    [ExcelDataTabShortcuts.RemoveDuplicates]: [[types_shared_1.ALT, 'a', 'm']],
};
exports.EXCEL_DATATAB_SHORCUTS = Object.entries(SHORTCUT_MAPPING).map(([shortcutName, keySeq]) => action.serialize(shortcutName, keySeq));
