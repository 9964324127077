"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlatformMetadataSchema = exports.EmailPlatformMetadataSchema = exports.SlackPlatformMetadataSchema = exports.BasePlatformMetadataSchema = void 0;
var zod_1 = require("zod");
exports.BasePlatformMetadataSchema = zod_1.z.object({
    platform: zod_1.z.string(),
});
exports.SlackPlatformMetadataSchema = exports.BasePlatformMetadataSchema.extend({
    platform: zod_1.z.literal('slack'),
    userEmails: zod_1.z.array(zod_1.z.string().email()),
    conversationId: zod_1.z.string(),
});
exports.EmailPlatformMetadataSchema = exports.BasePlatformMetadataSchema.extend({
    platform: zod_1.z.literal('email'),
    recipients: zod_1.z.array(zod_1.z.string().email()),
});
// Type for all platform metadata
exports.PlatformMetadataSchema = zod_1.z.discriminatedUnion('platform', [
    exports.SlackPlatformMetadataSchema,
    exports.EmailPlatformMetadataSchema,
    // Future platforms can be added here
]);
