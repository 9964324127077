import { ActionsEnum } from 'types-shared';
import type {
  Variable,
  OpenAction,
  WorkflowActionV2Base,
  WorkflowImageNode,
  WorkflowActionV2,
  ScreenshotAction,
} from 'types-shared/workflowTypes';
import { EditKeyboardShortcutAction } from './EditKeyboardShortcutAction';
import { EditOpenAction } from './EditOpenAction';
import { EditScreenshotAction } from './EditScreenshotAction';

interface EditV2ActionProps {
  action: WorkflowActionV2Base | WorkflowActionV2;
  node: WorkflowImageNode;
  onSaveAndExit: () => void;
  updateVariable: (variable: Variable) => void;
}

export function EditV2Action(props: EditV2ActionProps) {
  switch (props.action.actionType) {
    case ActionsEnum.KeyboardShortcut:
      return <EditKeyboardShortcutAction {...props} />;
    case ActionsEnum.Open:
      return <EditOpenAction {...props} action={props.action as OpenAction} />;
    case ActionsEnum.Screenshot:
      return (
        <EditScreenshotAction
          {...props}
          action={props.action as ScreenshotAction}
        />
      );
    default:
      throw new Error(`Invalid v2 action type: ${props.action.actionType}`);
  }
}
