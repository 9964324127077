import { useState, useMemo } from 'react';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import {
  WorkflowRunVariables,
  type WorkflowRunVariablesProps,
} from './WorkflowRunVariables';
import {
  Select,
  Button,
  GrayedOutInput,
  DateTimePicker,
  notify,
  AlertVariant,
  Input,
  Tooltip,
} from 'ui-kit';
import dayjs from '../../../config/dayjs';
import { clsx } from 'clsx';
import {
  useGetSchedules,
  useCreateSchedule,
  useEditSchedule,
  useDeleteSchedule,
} from '../hooks';
import { useParams } from 'react-router-dom';
import { handleException } from 'sentry-browser-shared';
import {
  convertScheduleToRRuleString,
  getScheduleTitle,
  parseRRuleStringToScheduleState,
  type ScheduleState,
  timezoneTitles,
  TriggerInterval,
  triggerIntervalOptsMap,
} from '../helper';
import type { GetWorkflowMetadataResponse } from 'api-types-shared';
import isNil from 'lodash/isNil';

const timezoneAliases: Record<string, string> = {
  'Africa/Asmera': 'Africa/Asmara',
  'America/Buenos_Aires': 'America/Argentina/Buenos_Aires',
  'America/Catamarca': 'America/Argentina/Catamarca',
  'America/Coral_Harbour': 'America/Atikokan',
  'America/Cordoba': 'America/Argentina/Cordoba',
  'America/Godthab': 'America/Nuuk',
  'America/Indianapolis': 'America/Indiana/Indianapolis',
  'America/Jujuy': 'America/Argentina/Jujuy',
  'America/Louisville': 'America/Kentucky/Louisville',
  'America/Mendoza': 'America/Argentina/Mendoza',
  'Asia/Calcutta': 'Asia/Kolkata',
  'Asia/Katmandu': 'Asia/Kathmandu',
  'Asia/Rangoon': 'Asia/Yangon',
  'Asia/Saigon': 'Asia/Ho_Chi_Minh',
  'Atlantic/Faeroe': 'Atlantic/Faroe',
  'Europe/Kiev': 'Europe/Kyiv',
  'Pacific/Enderbury': 'Pacific/Kanton',
  'Pacific/Ponape': 'Pacific/Pohnpei',
  'Pacific/Truk': 'Pacific/Chuuk',
};

const timezones = [
  'America/Los_Angeles',
  'America/Chicago',
  'America/New_York',
  'America/Sao_Paulo',
  'Europe/London',
  'Europe/Paris',
  'Europe/Moscow',
  'Africa/Cairo',
  'Africa/Johannesburg',
  'Asia/Dubai',
  'Asia/Kolkata',
  'Asia/Singapore',
  'Asia/Shanghai',
  'Asia/Tokyo',
  'Australia/Sydney',
  'Pacific/Auckland',
];

const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const defaultState: ScheduleState = {
  name: '',
  triggerInterval: TriggerInterval.DoesNotRepeat,
  triggerHr: '',
  customTriggerHr: undefined,
  timezone: timezoneAliases[localTimeZone] ?? localTimeZone,
};

const generateTriggerHours = (): string[] => {
  const hours = [];
  for (let i = 0; i < 24; i++) {
    const hour = i % 12 === 0 ? 12 : i % 12;
    const period = i < 12 ? 'AM' : 'PM';
    hours.push(`${hour.toString().padStart(2, '0')}:00 ${period}`);
  }
  return hours;
};

const triggerHrs = generateTriggerHours();

type Props = Omit<WorkflowRunVariablesProps, 'isSchedule' | 'isEditing'> & {
  workflowMetadata?: GetWorkflowMetadataResponse | null;
  resetVariableState: () => void;
};

export default function ScheduleRun({
  isFetchingNodeViewData,
  isPendingTest,
  workflowMetadata,
  variables,
  setVariableState,
  variableState,
  resetVariableState,
}: Props) {
  const [isCreatingNew, setIsCreatingNew] = useState(true);
  const [isEditing, setIsEditing] = useState(true);
  const { workflowId } = useParams();
  const { data: workflowSchedules, refetch: refetchSchedules } =
    useGetSchedules(workflowId);
  const [scheduleState, setScheduleState] =
    useState<ScheduleState>(defaultState);
  const { mutateAsync: deleteSchedule } = useDeleteSchedule();
  const { mutateAsync: createSchedule } = useCreateSchedule();
  const { mutateAsync: editSchedule } = useEditSchedule();

  const intervalOptions = useMemo(
    () => Object.keys(triggerIntervalOptsMap),
    [],
  );

  const schedules = useMemo(() => {
    return (workflowSchedules ?? []).map((s) => {
      const schedule = parseRRuleStringToScheduleState(s.rrule);
      const title = getScheduleTitle(schedule);
      return {
        ...s,
        title,
        timezone: schedule.timezone,
      };
    });
  }, [workflowSchedules]);

  const isSingleExecution = useMemo(
    () => scheduleState.triggerInterval === TriggerInterval.DoesNotRepeat,
    [scheduleState],
  );

  const variableNames = useMemo(() => {
    return Object.keys(variableState);
  }, [variableState]);

  const areVariableValuesMissing = useMemo(() => {
    return Object.values(variableState).some(
      (value) => isNil(value) || value === '',
    );
  }, [variableState]);

  const valuesMissing = useMemo(() => {
    let disabled = false;
    const { customTriggerHr, triggerHr, triggerInterval } = scheduleState;
    const nonRecurringSchedule =
      triggerInterval === TriggerInterval.DoesNotRepeat;
    if (nonRecurringSchedule) {
      disabled = !customTriggerHr || !triggerInterval;
    } else {
      disabled = !triggerHr || !triggerInterval;
    }
    if (!disabled) {
      disabled = areVariableValuesMissing;
    }
    return disabled;
  }, [scheduleState, areVariableValuesMissing]);

  const resetStateToEmpty = () => {
    setIsEditing(true);
    setScheduleState(defaultState);
    setIsCreatingNew(true);
    resetVariableState();
  };

  const onDelete = async (id: string) => {
    try {
      await deleteSchedule({
        scheduleId: id,
        ownerId: workflowMetadata?.userId,
      });
      resetStateToEmpty();
      notify({
        message: 'Workflow run schedule has been deleted.',
        variant: AlertVariant.WARNING,
      });
      await refetchSchedules();
    } catch (error) {
      handleException(error, {
        name: 'Failed to delete schedule',
        source: 'ScheduleRun',
      });
      notify({
        message: 'Failed to delete schedule.',
        variant: AlertVariant.ERROR,
      });
    }
  };

  const handleSubmit = async () => {
    if (!workflowId) {
      handleException(new Error('Workflow ID is not defined'), {
        name: 'Workflow ID is not defined',
        source: 'ScheduleRun',
      });
      notify({
        message: 'Failed to save schedule.',
        variant: AlertVariant.ERROR,
      });
      return;
    }

    try {
      setIsEditing(false);
      const rruleString = convertScheduleToRRuleString(scheduleState);

      if (scheduleState.id) {
        await editSchedule({
          scheduleId: scheduleState.id,
          rrule: rruleString,
          ownerId: workflowMetadata?.userId,
          name: scheduleState.name,
          variables: variableState,
        });
      } else {
        await createSchedule({
          workflowId,
          rrule: rruleString,
          ownerId: workflowMetadata?.userId,
          name: scheduleState.name,
          variables: variableState,
        });
      }
      await refetchSchedules();
      resetStateToEmpty();
      notify({
        message: 'Workflow run has been scheduled successfully.',
        variant: AlertVariant.SUCCESS,
      });
    } catch (error) {
      handleException(error, {
        name: 'Failed to save schedule',
        source: 'ScheduleRun',
      });
      notify({
        message: 'Failed to save schedule.',
        variant: AlertVariant.ERROR,
      });
    }
  };

  return (
    <div className="flex flex-row text-sm flex-1 overflow-y-hidden !mt-0">
      <div className="flex-shrink-0 w-106 max-h-full overflow-y-auto flex flex-col border-r border-gray-200 pt-5 pl-10 pb-10">
        <div
          role="presentation"
          className={clsx(
            'px-4 py-3 flex items-center gap-2 w-full cursor-pointer mb-3',
            {
              'text-secondary-blue bg-[rgba(49,116,250,0.04)] !border-secondary-blue !border-r-2':
                isCreatingNew,
              'text-gray-700': !isCreatingNew,
            },
          )}
          onClick={() => {
            setScheduleState({ ...defaultState });
            setIsEditing(true);
            setIsCreatingNew(true);
            resetVariableState();
          }}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        >
          <CalendarTodayIcon />
          <span className="truncate text-sm font-medium">NEW SCHEDULE</span>
        </div>

        {schedules.map((sRun) => {
          const hasMissingVariables = variableNames.some(
            (key) => isNil(sRun.variables?.[key]) || sRun.variables[key] === '',
          );
          const isSelected = sRun.id === scheduleState.id;

          return (
            <div
              role="presentation"
              key={sRun.id}
              className={clsx('px-3 py-5 flex flex-col w-full cursor-pointer', {
                'text-info bg-blue-50 !border-secondary-blue !border-r-2':
                  isSelected,
                'text-gray-700': sRun.id !== scheduleState.id,
                '!bg-red-50': hasMissingVariables,
                '!border-red-500': isSelected && hasMissingVariables,
              })}
              onClick={() => {
                setScheduleState({
                  id: sRun.id,
                  name: sRun.name,
                  ...parseRRuleStringToScheduleState(sRun.rrule),
                });
                setIsCreatingNew(false);
                setIsEditing(false);
                setVariableState(
                  (sRun.variables ?? {}) as Record<string, string>,
                );
              }}
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
            >
              <h2 className="text-sm font-medium text-navy-blue">
                {sRun.name}
              </h2>
              <p className="text-color-grey text-sm">{sRun.title}</p>
              <span className="text-color-grey text-xs">{sRun.timezone}</span>
            </div>
          );
        })}
      </div>

      <div className="flex-grow flex flex-col pt-5 pb-10">
        <div className="flex flex-col pl-6 overflow-y-auto pr-10">
          <span className="text-info-dark text-normal font-medium">
            Execution Date
          </span>

          <span className="text-gray-500 text-sm">
            Determine when you want to execute the workflow
          </span>

          <div className="mt-3 flex flex-col mb-3">
            {!isEditing ? (
              <GrayedOutInput label="Trigger Name" value={scheduleState.name} />
            ) : (
              <Input
                placeholder="Add a trigger name"
                floatingLabel
                label="Trigger Name"
                value={scheduleState.name}
                onChange={(val) => {
                  setScheduleState((oldState) => ({
                    ...oldState,
                    name: val,
                  }));
                }}
              />
            )}
          </div>

          <div className="mt-3 flex flex-col mb-3">
            {!isEditing ? (
              <GrayedOutInput
                label="Frequency"
                value={
                  scheduleState.triggerInterval
                    ? triggerIntervalOptsMap[scheduleState.triggerInterval]
                    : 'Does not repeat'
                }
                isSelect
              />
            ) : (
              <Select
                size="medium"
                classes={{ select: '!py-4' }}
                defaultValue={TriggerInterval.DoesNotRepeat}
                getLabel={(opt: string) => triggerIntervalOptsMap[opt] ?? opt}
                getValue={(opt: string) => opt}
                label="Frequency"
                labelId="template-select-variable-source"
                onChange={(e) => {
                  setScheduleState((oldState) => ({
                    ...oldState,
                    triggerInterval: e.target.value as TriggerInterval,
                  }));
                }}
                options={intervalOptions}
                value={scheduleState.triggerInterval}
              />
            )}
          </div>

          <div className="mt-3 flex flex-col mb-3">
            {!isEditing ? (
              <GrayedOutInput
                label="Trigger at"
                value={
                  scheduleState.customTriggerHr
                    ? dayjs(scheduleState.customTriggerHr).format(
                        'MM/DD/YYYY hh:mm A',
                      )
                    : scheduleState.triggerHr
                }
                isSelect
              />
            ) : (
              <>
                {!isSingleExecution ? (
                  <Select
                    classes={{ select: '!py-4' }}
                    defaultValue={triggerHrs[0]}
                    disabled={triggerHrs.length < 2}
                    getLabel={(opt: string) => opt}
                    getValue={(opt: string) => opt}
                    size="small"
                    label="Trigger at"
                    onChange={(e) => {
                      setScheduleState((oldState) => ({
                        ...oldState,
                        triggerHr: e.target.value,
                      }));
                    }}
                    options={triggerHrs}
                    value={scheduleState.triggerHr}
                  />
                ) : (
                  <DateTimePicker
                    label="Trigger at hour"
                    onChange={(e) => {
                      setScheduleState((oldState) => ({
                        ...oldState,
                        customTriggerHr: e.toISOString(),
                      }));
                    }}
                    value={
                      scheduleState.customTriggerHr
                        ? dayjs(scheduleState.customTriggerHr)
                        : undefined
                    }
                  />
                )}
              </>
            )}
          </div>

          <div className="mt-3 flex flex-col mb-8">
            {!isEditing ? (
              <GrayedOutInput
                label="Timezone"
                value={
                  scheduleState.timezone
                    ? timezoneTitles[scheduleState.timezone]
                    : ''
                }
                isSelect
              />
            ) : (
              <Select
                classes={{ select: '!py-4' }}
                defaultValue={timezones[0]}
                getLabel={(opt: string) => timezoneTitles[opt] ?? opt}
                getValue={(opt: string) => opt}
                size="small"
                label="Timezone"
                onChange={(e) => {
                  setScheduleState((oldState) => ({
                    ...oldState,
                    timezone: e.target.value,
                  }));
                }}
                options={timezones}
                value={scheduleState.timezone}
              />
            )}
          </div>

          <WorkflowRunVariables
            className="!overflow-y-hidden !min-h-min"
            variables={variables}
            isFetchingNodeViewData={isFetchingNodeViewData}
            isPendingTest={isPendingTest}
            setVariableState={setVariableState}
            variableState={variableState}
            isSchedule
            isEditing={isEditing}
          />
        </div>

        <div className="!mt-8 flex space-x-4 flex-row justify-between gap-4 border-t border-gray-200 pl-6 pt-6 pr-10">
          <Tooltip
            arrow
            placement="top"
            title="You must fill out all variables to create a schedule."
            hidden={!areVariableValuesMissing}
          >
            <Button
              className="px-5 !min-w-[120px]"
              color="secondary"
              loading={isPendingTest}
              onClick={() => {
                if (!isEditing) {
                  setIsEditing(true);
                } else {
                  void handleSubmit();
                }
              }}
              disabled={isFetchingNodeViewData || (isEditing && valuesMissing)}
              variant={isEditing ? 'contained' : 'outlined'}
            >
              {isEditing ? 'SCHEDULE RUN' : 'EDIT'}
            </Button>
          </Tooltip>
          {scheduleState.id && !isEditing ? (
            <Button
              color="error"
              onClick={() => {
                if (scheduleState.id) {
                  void onDelete(scheduleState.id);
                }
              }}
              variant="outlined"
              className="px-5 !min-w-[120px]"
            >
              DELETE
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
}
