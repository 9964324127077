import { RRule } from 'rrule';
import dayjs from '../../config/dayjs';
import { formatWithSuffix } from '../../utils/helper';
import isNil from 'lodash/isNil';

export enum TriggerInterval {
  DoesNotRepeat = 'Does not repeat',
  Daily = 'Daily',
  EveryWeekday = 'Every weekday',
  Monthly = 'Monthly',
  Monday = 'MO',
  Tuesday = 'TU',
  Wednesday = 'WE',
  Thursday = 'TH',
  Friday = 'FR',
  Saturday = 'SA',
  Sunday = 'SU',
}

const weeklyDays: TriggerInterval[] = [
  TriggerInterval.Monday,
  TriggerInterval.Tuesday,
  TriggerInterval.Wednesday,
  TriggerInterval.Thursday,
  TriggerInterval.Friday,
  TriggerInterval.Saturday,
  TriggerInterval.Sunday,
];

export const triggerIntervalOptsMap: Record<string, string> = {
  [TriggerInterval.DoesNotRepeat]: 'Does not repeat',
  [TriggerInterval.Daily]: 'Daily',
  [TriggerInterval.EveryWeekday]: 'Every weekday',
  [TriggerInterval.Monday]: `Weekly on Monday`,
  [TriggerInterval.Tuesday]: `Weekly on Tuesday`,
  [TriggerInterval.Wednesday]: `Weekly on Wednesday`,
  [TriggerInterval.Thursday]: `Weekly on Thursday`,
  [TriggerInterval.Friday]: `Weekly on Friday`,
  [TriggerInterval.Saturday]: `Weekly on Saturday`,
  [TriggerInterval.Sunday]: `Weekly on Sunday`,
  [TriggerInterval.Monthly]: `Monthly on the ${formatWithSuffix(dayjs().date())}`,
};

// WARN: Do not make changes here; We'll need to update the backend first before we can make changes here
// The backend is only capable of converting (a subset of) RRULEs to CRON expressions
export const getRRuleFreq = (triggerInterval: TriggerInterval | undefined) => {
  if (!triggerInterval || triggerInterval === TriggerInterval.DoesNotRepeat) {
    return RRule.DAILY;
  } else if (triggerInterval === TriggerInterval.Daily) {
    return RRule.DAILY;
  } else if (triggerInterval === TriggerInterval.EveryWeekday) {
    return RRule.DAILY;
  } else if (weeklyDays.includes(triggerInterval)) {
    return RRule.WEEKLY;
  }
  return RRule.MONTHLY;
};

export const getDayjsInstanceFromDate = (date: string) => {
  return dayjs(dayjs(date).utc().format());
};

export const getDateInstanceFromTriggerHour = (triggerHour: string) => {
  return getDayjsInstanceFromDate(
    `${dayjs().format('YYYY-MM-DD')} ${triggerHour}`,
  );
};

export const getTimeString = ({
  hours,
  minutes,
  seconds,
}: {
  hours: string;
  minutes: string;
  seconds: string;
}): string => {
  const todayDateString = dayjs().format('YYYY-MM-DD');
  return dayjs
    .utc(`${todayDateString}T${hours}:${minutes}:${seconds}`)
    .format('hh:mm A');
};

// WARN: Do not make changes here; We'll need to update the backend first before we can make changes here
// The backend is only capable of converting (a subset of) RRULEs to CRON expressions
export const computeRRuleOptions = (
  triggerInterval: TriggerInterval,
  triggerHr: string | undefined,
  customTriggerHr: string | undefined,
) => {
  if (!triggerHr && !customTriggerHr) {
    return {};
  }
  const dateInstance = customTriggerHr
    ? dayjs(customTriggerHr)
    : getDateInstanceFromTriggerHour(triggerHr ?? '');
  const options: Record<string, unknown> = {
    dtstart: new Date(
      Date.UTC(
        dateInstance.year(),
        dateInstance.month(),
        dateInstance.date(),
        dateInstance.hour(),
        dateInstance.minute(),
        dateInstance.second(),
      ),
    ),
    byhour: [dateInstance.get('hour')],
    byminute: [dateInstance.get('minute')],
    bysecond: [dateInstance.get('second')],
  };
  const todayDateInstance = dayjs().utc();
  if (triggerInterval === TriggerInterval.EveryWeekday) {
    options.byweekday = [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR];
  } else if (weeklyDays.includes(triggerInterval)) {
    // eslint-disable-next-line
    // @ts-expect-error
    options.byweekday = RRule[triggerInterval];
  } else if (triggerInterval === TriggerInterval.Monthly) {
    options.bymonthday = [todayDateInstance.date()];
  }
  return options;
};

const getFreqStringFromRRule = (rrule: RRule) => {
  const { freq, byweekday, bymonthday } = rrule.options;
  if (freq === RRule.DAILY && isNil(byweekday)) {
    return TriggerInterval.Daily;
  }
  if (freq === RRule.DAILY && byweekday.length > 1) {
    return TriggerInterval.EveryWeekday;
  }
  if (freq === RRule.WEEKLY && byweekday.length === 1) {
    return weeklyDays[byweekday[0]];
  }
  const date = bymonthday[0];
  return `Monthly on the ${formatWithSuffix(date)}`;
};

export const parseRRuleStringToScheduleState = (rruleString: string) => {
  const rrule = RRule.fromString(rruleString);
  const { count, dtstart, byhour, byminute, bysecond, tzid } = rrule.options;
  const timezone = tzid ?? undefined;
  if (count === 1) {
    return {
      triggerInterval: TriggerInterval.DoesNotRepeat,
      triggerHr: '',
      customTriggerHr: dayjs(dtstart.toISOString().slice(0, -1))
        .utc()
        .toISOString(),
      timezone,
    };
  }
  const hours = byhour[0].toString().padStart(2, '0');
  const minutes = byminute[0].toString().padStart(2, '0');
  const seconds = bysecond[0].toString().padStart(2, '0');
  return {
    triggerInterval: getFreqStringFromRRule(rrule) as TriggerInterval,
    triggerHr: getTimeString({ hours, minutes, seconds }),
    customTriggerHr: undefined,
    timezone,
  };
};

export interface ScheduleState {
  id?: string;
  name: string;
  triggerInterval?: TriggerInterval;
  triggerHr?: string;
  customTriggerHr?: string;
  timezone?: string;
}

// WARN: Do not make changes here; We'll need to update the backend first before we can make changes here
// The backend is only capable of converting (a subset of) RRULEs to CRON expressions
export const convertScheduleToRRuleString = (schedule: ScheduleState) => {
  const {
    triggerInterval = TriggerInterval.DoesNotRepeat,
    triggerHr,
    customTriggerHr,
    timezone,
  } = schedule;
  const freq = getRRuleFreq(triggerInterval);
  const count =
    triggerInterval === TriggerInterval.DoesNotRepeat ? 1 : undefined;
  return new RRule({
    freq,
    count,
    tzid: timezone,
    ...computeRRuleOptions(triggerInterval, triggerHr, customTriggerHr),
  }).toString();
};

export const timezoneTitles: Record<string, string> = {
  'America/Los_Angeles': 'Pacific Time',
  'America/Chicago': 'Central Time',
  'America/New_York': 'Eastern Time',
  'America/Sao_Paulo': 'Brasília Time',
  'Europe/London': 'Greenwich Mean Time',
  'Europe/Paris': 'Central European Time',
  'Europe/Moscow': 'Moscow Time',
  'Africa/Cairo': 'Egypt Time',
  'Africa/Johannesburg': 'South African Time',
  'Asia/Dubai': 'Gulf Time',
  'Asia/Kolkata': 'India Time',
  'Asia/Singapore': 'Singapore Time',
  'Asia/Shanghai': 'China Time',
  'Asia/Tokyo': 'Japan Time',
  'Australia/Sydney': 'Australian Eastern Time',
  'Pacific/Auckland': 'New Zealand Time',
};

export const getScheduleTitle = (schedule: Omit<ScheduleState, 'name'>) => {
  const {
    triggerInterval = TriggerInterval.DoesNotRepeat,
    triggerHr = '',
    customTriggerHr,
  } = schedule;
  if (triggerInterval === TriggerInterval.DoesNotRepeat && customTriggerHr) {
    return `AT ${dayjs(customTriggerHr).format('MM/DD/YYYY hh:mm A')}`;
  }
  if (weeklyDays.includes(triggerInterval)) {
    return `AT ${triggerHr} ${triggerIntervalOptsMap[triggerInterval]}`;
  }
  return `AT ${triggerHr} ${triggerInterval}`;
};
