import { useMemo } from 'react';
import { useEnvironment } from 'ui-kit';
import type { Options as KyOptions } from 'ky';
import { useApolloClient } from '@apollo/client';

import { AutolinkDemoSDK } from './autolinkDemoSDK';
import { DatasourceSDK } from './datasourceSDK';
import { ExecutionSDK } from './executionSDK';
import { FileSDK } from './fileSDK';
import { PythonSDK } from './pythonSDK';
import { WorkflowSDK } from './workflowSDK';
import { MiscSDK } from './miscSDK';
import { TransformSDK } from './transformSDK';
import { UserSDK } from './userSDK';
import { ParagonSDK } from './paragonSDK';

interface IUseAPI {
  pythonSDK: PythonSDK;
  fileSDK: FileSDK;
  workflowSDK: WorkflowSDK;
  datasourceSDK: DatasourceSDK;
  userSDK: UserSDK;
  autolinkDemoSDK: AutolinkDemoSDK;
  miscSDK: MiscSDK;
  executionSDK: ExecutionSDK;
  transformSDK: TransformSDK;
  paragonSDK: ParagonSDK;
}

export interface UseAPIArgs {
  kyOpts?: KyOptions;
}

export const useAPI = ({ kyOpts }: UseAPIArgs = {}): IUseAPI => {
  const { selectedEnv } = useEnvironment();
  const apolloClient = useApolloClient();

  const {
    pythonSDK,
    fileSDK,
    workflowSDK,
    datasourceSDK,
    autolinkDemoSDK,
    miscSDK,
    executionSDK,
    transformSDK,
    userSDK,
    paragonSDK,
  } = useMemo(() => {
    return {
      autolinkDemoSDK: new AutolinkDemoSDK(selectedEnv),
      pythonSDK: new PythonSDK(selectedEnv),
      fileSDK: new FileSDK(selectedEnv, kyOpts),
      workflowSDK: new WorkflowSDK(selectedEnv, apolloClient, kyOpts),
      datasourceSDK: new DatasourceSDK(selectedEnv, kyOpts),
      miscSDK: new MiscSDK(selectedEnv, kyOpts),
      executionSDK: new ExecutionSDK(selectedEnv, apolloClient, kyOpts),
      transformSDK: new TransformSDK(selectedEnv, kyOpts),
      userSDK: new UserSDK(selectedEnv, kyOpts),
      paragonSDK: new ParagonSDK(selectedEnv, apolloClient, kyOpts),
    };
  }, [kyOpts, apolloClient, selectedEnv]);

  return {
    executionSDK,
    autolinkDemoSDK,
    pythonSDK,
    fileSDK,
    workflowSDK,
    datasourceSDK,
    miscSDK,
    transformSDK,
    userSDK,
    paragonSDK,
  };
};

export * from './paragonSDK';
export * from './workflowSDK';

export {
  AutolinkDemoSDK,
  DatasourceSDK,
  ExecutionSDK,
  FileSDK,
  MiscSDK,
  ParagonSDK,
  PythonSDK,
  TransformSDK,
  UserSDK,
  WorkflowSDK,
};
