import { InfoOutlined } from 'ui-kit';
import React, { useState, useMemo, useEffect, useRef } from 'react';
import {
  type ExecutionVariables,
  NodeTypesEnum,
  type WorkflowNode,
  WorkflowConditionalNode,
  type WorkflowEdge,
  type GlobalVariable,
  type VariableMap,
  type Variable,
  type ExecutionProgress,
  WorkflowFreeformNode,
  type ExecutionBase,
  ExecutionStatusEnum,
  type DocumentVariable,
  WorkflowEmailNode,
  WorkflowRetryNode,
  WorkflowStopNode,
} from 'types-shared';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {
  type SignalTypeEnum,
  type ExecutionDocument,
  type GetExecutionResponse,
} from 'api-types-shared';
import { Output } from './components/RecordOutputs';
import { SourceOutputs } from './components/SourceOutputs';
import { RequestOutputs } from './components/RequestOutputs';
import { type ScreenshotUrl } from '../../utils';
import ConditionalInputs from './components/ConditionalInputs';
import FreeformInputs from './components/FreeformInputs';
import DefaultActions from './components/DefaultActions';
import RetryInputs from './components/RetryInputs';

import HITLModal from './components/HITLModal';
import { type StepActions } from '../../hooks/useWorkflowCurrentStepActions';
import { Skeleton } from '@mui/material';
import RerunModal from './components/HITLModal/components/RerunModal';
import DocumentVariableFullScreen from './components/DocumentVariables/components/DocumentVariableFullScreen';
import EmailStepOutputs from './components/EmailStepOutputs';
import StopStep from './components/StopStep';

const hideTabsNodeTypes = [
  NodeTypesEnum.Source,
  NodeTypesEnum.Request,
  NodeTypesEnum.Retry,
];

type NodeType = (typeof NodeTypesEnum)[keyof typeof NodeTypesEnum];

export default function WorkflowScreenshotTabs({
  workflowName,
  currentNode,
  edges,
  errorMsg,
  nodeType,
  isLastItemSelected,
  currentStepId,
  hasError,
  currentStepActions,
  executionVariables,
  onDownloadLinkData,
  executionArtifacts,
  inputFiles,
  setMenuProps,
  showHITLPrompt,
  executionMetadata,
  onSignalClick,
  variablesMap,
  globalVariablesMap,
  nodes,
  completedSteps,
  nodeOfCurrentStep,
  screenshotUrls,
  signalLoading,
  loading,
  setStopPolling,
  onUpdateStatusDesc,
  updateDescStatus,
  executionDetail,
  selectedIndex,
}: {
  executionDetail?: GetExecutionResponse;
  nodeOfCurrentStep?: WorkflowNode;
  screenshotUrls: ScreenshotUrl[];
  currentNode: WorkflowNode | undefined;
  errorMsg: string | undefined;
  nodeType: NodeType;
  isLastItemSelected: boolean;
  currentStepId: string | undefined;
  hasError: boolean;
  currentStepActions: StepActions;
  executionVariables: ExecutionVariables;
  onDownloadLinkData?: (url: string) => void;
  executionArtifacts?: ExecutionDocument[];
  inputFiles?: ExecutionDocument[];
  showHITLPrompt: boolean;
  signalLoading: boolean;
  edges: WorkflowEdge[];
  setMenuProps: React.Dispatch<
    React.SetStateAction<
      | {
          el: HTMLButtonElement | null;
          actionId: string;
          isScrape: boolean;
        }
      | null
      | undefined
    >
  >;
  executionMetadata?: ExecutionBase;
  onSignalClick?: (signal: SignalTypeEnum, payload?: object) => void;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  variablesMap: Record<string, Variable>;
  nodes: WorkflowNode[];
  completedSteps: ExecutionProgress;
  loading: boolean;
  setStopPolling: (newPolling: boolean) => void;
  onUpdateStatusDesc?: (statusDesc: string) => void;
  updateDescStatus?: 'error' | 'idle' | 'pending' | 'success';
  workflowName?: string;
  selectedIndex: number;
}) {
  const documentVariable = useRef<DocumentVariable>();
  const [showDocumentVariablePreview, setShowDocumentVariablePreview] =
    useState(false);
  const [selectedTab, setSelectedTab] = useState('actions');
  const [hitlOpen, setHitlOpen] = useState(false);
  const [showReRunModal, setShowReRunModal] = useState(false);

  const handleChange = (event: unknown, newValue: string) => {
    setSelectedTab(newValue);
  };

  const openRerunModal = () => {
    setShowReRunModal(true);
  };

  const openHITLModal = () => {
    if (executionMetadata?.status === ExecutionStatusEnum.Timeout) {
      openRerunModal();
    } else {
      setHitlOpen(true);
    }
  };

  const currentStepOutputs = useMemo(() => {
    return currentStepActions
      .filter((a) => Boolean(a.outputs))
      .map((a) => a.outputs);
  }, [currentStepActions]);

  const isConditional = useMemo(
    () => WorkflowConditionalNode.safeParse(currentNode).success,
    [currentNode],
  );

  const isFreeform = useMemo(
    () => WorkflowFreeformNode.safeParse(currentNode).success,
    [currentNode],
  );

  const isEmailStep = useMemo(
    () => WorkflowEmailNode.safeParse(currentNode).success,
    [currentNode],
  );

  const isRetryStep = useMemo(
    () => WorkflowRetryNode.safeParse(currentNode).success,
    [currentNode],
  );

  const isStopStep = useMemo(
    () => WorkflowStopNode.safeParse(currentNode).success,
    [currentNode],
  );

  useEffect(() => {
    const nodeHasInputs = isFreeform || isConditional || isEmailStep;
    if (nodeHasInputs && selectedTab !== 'inputs') {
      setSelectedTab('inputs');
    } else if (selectedTab !== 'actions') {
      setSelectedTab('actions');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConditional, isFreeform, isEmailStep]);
  // Listening for changes to selectedTab creates re-render loop

  useEffect(() => {
    if (executionMetadata?.status === ExecutionStatusEnum.Timeout) {
      openRerunModal();
    }
  }, [executionMetadata?.status]);

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        {currentNode?.type &&
        hideTabsNodeTypes.includes(currentNode.type) ? null : (
          <Tabs
            className="!px-6"
            sx={{
              '& .MuiTabs-indicator': {
                backgroundColor: '#2196F3',
              },
              '& .Mui-selected': {
                color: '#2196F3 !important',
              },
            }}
            onChange={handleChange}
            value={selectedTab}
          >
            {nodeType !== NodeTypesEnum.Source &&
            !isConditional &&
            !isFreeform &&
            !isEmailStep ? (
              <Tab label="Actions" value="actions" />
            ) : null}

            {isConditional || isFreeform || isEmailStep ? (
              <Tab label="Inputs" value="inputs" />
            ) : null}
          </Tabs>
        )}
      </Box>

      {/* // TODO: revisit this behaviour later, @neil @manu */}
      {errorMsg && isLastItemSelected ? (
        <div className="m-4 !mb-0 bg-[#FDEDED] flex space-x-2 px-6 py-4 rounded">
          <InfoOutlined className="!w-5 !h-5 !text-error !mt-0.5" />
          <p
            className="text-red-900 text-sm"
            dangerouslySetInnerHTML={{
              __html: errorMsg.replaceAll('\n', '<br />'),
            }}
          />
        </div>
      ) : null}

      {currentNode?.type === NodeTypesEnum.Source &&
      executionArtifacts &&
      inputFiles &&
      !loading ? (
        <SourceOutputs
          executionVariables={executionVariables}
          node={currentNode}
          onDownloadLinkData={onDownloadLinkData}
          executionArtifacts={executionArtifacts}
          inputFiles={inputFiles}
        />
      ) : null}

      {currentNode?.type === NodeTypesEnum.Request && !loading ? (
        <RequestOutputs
          executionVariables={{
            ...executionVariables,
          }}
          node={currentNode}
        />
      ) : null}

      {isConditional &&
      selectedTab === 'inputs' &&
      nodeOfCurrentStep &&
      !loading ? (
        <ConditionalInputs
          // Will always be a WorkflowConditionalNode because of isConditional bool
          currentNode={currentNode as WorkflowConditionalNode}
          nodes={nodes}
          nodeOfCurrentStep={nodeOfCurrentStep}
          screenshotUrls={screenshotUrls}
          completedSteps={completedSteps}
          edges={edges}
          variablesMap={variablesMap}
          globalVariablesMap={globalVariablesMap}
          showHITLPrompt={showHITLPrompt}
          openHITLModal={openHITLModal}
          signalLoading={signalLoading}
        />
      ) : null}

      {isFreeform && selectedTab === 'inputs' && !loading ? (
        <FreeformInputs
          // Will always be a WorkflowFreeformNode because of isFreeform bool
          currentNode={currentNode as WorkflowFreeformNode}
          variablesMap={variablesMap}
          globalVariablesMap={globalVariablesMap}
          showHITLPrompt={showHITLPrompt}
          freeformTitle={currentNode?.name}
          openHITLModal={openHITLModal}
          signalLoading={signalLoading}
        />
      ) : null}

      {isEmailStep &&
      selectedTab === 'inputs' &&
      !loading &&
      executionDetail?.metadata.variableData ? (
        <EmailStepOutputs
          currentNode={currentNode as WorkflowEmailNode}
          variableMetadata={executionDetail.metadata.variableData}
          executionVariables={executionDetail.variableData}
          variablesMap={variablesMap}
          globalVariablesMap={globalVariablesMap}
          onDownloadLinkData={onDownloadLinkData}
          artifacts={[
            ...executionDetail.inputFiles,
            ...executionDetail.artifacts,
          ]}
        />
      ) : null}

      {loading ? (
        <div className="flex flex-col space-y-4 p-9 overflow-y-auto">
          <Skeleton variant="text" height={30} width={300} />
          <Skeleton variant="text" height={30} width={400} className="mt-2" />
        </div>
      ) : null}

      {isRetryStep && currentNode && !loading ? (
        <RetryInputs
          currentNode={currentNode as WorkflowRetryNode}
          completedSteps={completedSteps}
          currentAttempt={executionDetail?.metadata.attempt}
          selectedIndex={selectedIndex}
          screenshotUrls={screenshotUrls}
        />
      ) : null}

      {isStopStep && currentNode && !loading ? (
        <StopStep node={currentNode as WorkflowStopNode} />
      ) : null}

      {currentNode?.type === NodeTypesEnum.Image && !loading ? (
        <>
          {selectedTab === 'outputs' ? (
            <div className="flex flex-col space-y-4 p-9 overflow-y-auto">
              {currentStepOutputs.map((output) => {
                if (!output?.description) return null;

                return (
                  <Output
                    action="copy"
                    className="!max-w-[100%]"
                    description={output.description}
                    key={output.title}
                    title={output.title}
                  />
                );
              })}
            </div>
          ) : null}

          {selectedTab === 'actions' ? (
            <DefaultActions
              openHITLModal={openHITLModal}
              hasError={hasError}
              currentStepId={currentStepId}
              currentStepActions={currentStepActions}
              setMenuProps={setMenuProps}
              showHITLPrompt={showHITLPrompt}
              signalLoading={signalLoading}
              variableMap={variablesMap}
              globalVariablesMap={globalVariablesMap}
              openDocumentVariablePreview={(_docVar) => {
                setShowDocumentVariablePreview(true);
                documentVariable.current = _docVar;
              }}
            />
          ) : null}
        </>
      ) : null}

      {executionMetadata &&
      executionArtifacts &&
      onUpdateStatusDesc &&
      updateDescStatus ? (
        <HITLModal
          open={hitlOpen}
          setOpen={setHitlOpen}
          executionMetadata={executionMetadata}
          executionArtifacts={executionArtifacts}
          currentStepActions={currentStepActions}
          onSendSignal={onSignalClick}
          onUpdateStatusDesc={onUpdateStatusDesc}
          updateDescStatus={updateDescStatus}
        />
      ) : null}
      {executionMetadata ? (
        <RerunModal
          open={showReRunModal}
          setStopPolling={setStopPolling}
          setOpen={setShowReRunModal}
          executionId={executionMetadata.executionId}
        />
      ) : null}

      {showDocumentVariablePreview && executionMetadata ? (
        <DocumentVariableFullScreen
          artifacts={executionArtifacts}
          executionName={workflowName}
          workflowId={executionMetadata.workflowId}
          executionId={executionMetadata.executionId}
          onClose={() => {
            documentVariable.current = undefined;
            setShowDocumentVariablePreview(false);
          }}
          variable={documentVariable.current ?? ({} as DocumentVariable)}
          variableMap={variablesMap}
        />
      ) : null}
    </>
  );
}
