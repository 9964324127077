import {
  Add,
  CloseIcon,
  DeleteOutlineIcon,
  Button,
  Input,
  Select,
} from 'ui-kit';
import {
  type DatasourceMetadata,
  type DatasourceTable,
  NodeStatusEnum,
  RequestIntegrationTypeEnum,
  RequestMethodEnum,
  type RequestPayloadType,
  type SourceTypeEnum,
  TemplateData,
  type TemplateVariable,
  type Variable,
  type VariableMap,
  type WorkflowRequestNode,
  type GlobalVariable,
} from 'types-shared';
import { useCallback, useEffect, useState } from 'react';
import upperCase from 'lodash/upperCase';
import type { SelectChangeEvent } from '@mui/material/Select';
import { RequestVariableInput } from './RequestVariableInput';
import Switch from '@mui/material/Switch';
import {
  createTemplateVariable,
  getRequestBlockTitle,
  getRequestNodeDescription,
} from './request.helpers';
import { NodeCheck } from '../NodeCheck';
import { isAdmin } from '../../../../utils/env';
import { VariableChip } from '../../../../components/VariableChip';
import SectionLabel from './SectionLabel';

export type WorkflowRequestNodeCoreData = Omit<
  WorkflowRequestNode['data'],
  'nodeStatus' | 'selected'
>;

interface Props {
  node: WorkflowRequestNode;
  onCancel: () => void;
  onUpdateData: (data: WorkflowRequestNodeCoreData) => void;
  variablesMap: VariableMap;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  datasourceMetadata: DatasourceMetadata | null;
  tableData: DatasourceTable | null;
  addVariable: (variable: Variable) => void;
  updateVariable: (variable: Variable) => void;
  transformApiReqStatus: 'error' | 'idle' | 'pending' | 'success' | 'loading';
  sourceType?: SourceTypeEnum;
  onTransformApiReq: (
    prompt: TemplateData,
    textToTransform: string,
    model?: string,
  ) => Promise<string | undefined>;
  updateNodeStatus: (status: NodeStatusEnum) => void;
  updateNodeName: (name: string) => void;
}

const typeOptions = Object.values(RequestMethodEnum);
const integrationTypeOptions = Object.values(RequestIntegrationTypeEnum);

export function RequestBlockAdmin({
  node,
  onCancel,
  onUpdateData,
  variablesMap,
  globalVariablesMap,
  updateNodeStatus,
  updateNodeName,
  ...props
}: Props) {
  const { updateVariable } = props;
  const requestData = node.data;
  const { urlParameters = [], headers = [], body } = requestData;
  const [hasRawJson, setHasRawJson] = useState<boolean>(true);

  const handleOnChangeMethod = (event: SelectChangeEvent) => {
    const method = event.target.value as RequestMethodEnum;
    onUpdateData({ ...requestData, method });
  };

  const addTemplateKeyValue = useCallback(
    (key: 'urlParameters' | 'headers' | 'body') => {
      onUpdateData({
        ...requestData,
        [key]: [
          ...(requestData[key] as RequestPayloadType[]),
          {
            key: {
              variableId: createTemplateVariable(props.addVariable).id,
            },
            value: {
              variableId: createTemplateVariable(props.addVariable).id,
            },
          },
        ],
      });
    },
    [onUpdateData, props.addVariable, requestData],
  );

  const deleteTemplateKeyValue = useCallback(
    (key: 'urlParameters' | 'headers' | 'body', id: string) => {
      // @Paul Implement variable deletion here.
      onUpdateData({
        ...requestData,
        [key]: (requestData[key] as RequestPayloadType[]).filter(
          (param) => param.key.variableId !== id,
        ),
      });
    },
    [onUpdateData, requestData],
  );

  const updateAuthEnabled = useCallback(
    (key: 'forUserId' | 'authKey', enabled: boolean) => {
      onUpdateData({
        ...requestData,
        auth: {
          ...requestData.auth,
          [key]: {
            enabled,
            value: requestData.auth[key].value,
          },
        },
      });
    },
    [onUpdateData, requestData],
  );

  useEffect(() => {
    if (typeof body === 'object' && !Array.isArray(body)) {
      const bodyVariable = variablesMap[body.variableId] as TemplateVariable;
      setHasRawJson(
        body.variableId && body.variableId in variablesMap
          ? TemplateData.safeParse(bodyVariable.data).success
          : false,
      );
    } else {
      setHasRawJson(false);
    }
  }, [body, variablesMap]);

  return (
    <div className="zoom-adjusted-container node-block absolute left-2 top-2 bottom-2 w-120 bg-white rounded-lg z-[10] flex flex-col justify-between space-y-5">
      <div className="overflow-auto p-8">
        <div className="flex justify-between items-center">
          <span className="text-sm text-primary-blue font-medium">
            {getRequestBlockTitle(node, true)}
          </span>
          <Button
            className="!min-w-min h-10 w-10 flex justify-center items-center !p-0 !rounded-lg"
            color="secondary"
            onClick={onCancel}
            variant="outlined"
          >
            <CloseIcon className="text-info" />
          </Button>
        </div>
        {isAdmin ? (
          <p className="text-zinc-500 text-xs leading-tight mb-0 -mt-2">
            Node Id: {node.id}
          </p>
        ) : null}
        <div className="my-6">
          <h2 className="text-cyan-900 text-lg font-medium leading-relaxed tracking-tight truncate">
            {getRequestBlockTitle(node)}
          </h2>
          <p className="text-zinc-500 text-sm leading-tight">
            {getRequestNodeDescription(node)}
          </p>
        </div>
        <div className="request-block flex-1 flex flex-col gap-4">
          {isAdmin ? (
            <Select
              classes={{ select: 'w-100' }}
              getLabel={(opt: string) => opt}
              getValue={(opt: string) => opt}
              label="Integration type"
              onChange={(event: SelectChangeEvent) => {
                const integrationType = event.target
                  .value as RequestIntegrationTypeEnum;
                const updatedData = { ...requestData, integrationType };
                onUpdateData(updatedData);
              }}
              options={integrationTypeOptions}
              value={requestData.integrationType}
            />
          ) : null}

          <Input
            floatingLabel
            label="Step name"
            onChange={(val: string) => {
              updateNodeName(val);
              updateVariable({
                ...variablesMap[requestData.responseVariableId],
                name: val,
              });
            }}
            placeholder="Step name"
            value={node.name ?? ''}
          />

          <Input
            floatingLabel
            multiline
            rows={4}
            label="Step description"
            placeholder="Description"
            value={requestData.description}
            onChange={(newDescription) => {
              onUpdateData({
                ...requestData,
                description: newDescription,
              });
            }}
          />

          {isAdmin ? (
            <div className="border border-slate-300 px-4 py-4 rounded-lg flex justify-between items-center space-x-4">
              <span className="font-medium text-neutral-600 text-sm">
                Changes requested by users are resolved
              </span>
              <Switch
                checked={requestData.changesResolved}
                color="secondary"
                onChange={(_, checked) => {
                  const updatedData = {
                    ...requestData,
                    changesResolved: checked,
                  };
                  onUpdateData(updatedData);
                }}
              />
            </div>
          ) : null}

          <Select
            classes={{ select: 'w-100' }}
            getLabel={(opt: string) => upperCase(opt)}
            getValue={(opt: string) => opt}
            label="Method"
            labelId="method"
            onChange={handleOnChangeMethod}
            options={typeOptions}
            value={requestData.method}
          />

          <RequestVariableInput
            label="URL"
            onChange={(updatedVar) => {
              updateVariable(updatedVar);
            }}
            variable={
              variablesMap[requestData.url.variableId] as TemplateVariable
            }
            variablesMap={variablesMap}
            globalVariablesMap={globalVariablesMap}
            className="w-full"
            multiline={false}
            {...props}
          />

          <SectionLabel title="URL parameters" />

          {urlParameters.map((urlParameter) => {
            return (
              <div
                className="flex w-100 justify-between space-x-3"
                key={urlParameter.key.variableId}
              >
                <RequestVariableInput
                  label="Key"
                  onChange={(updatedVariable) => {
                    updateVariable(updatedVariable);
                  }}
                  variable={
                    variablesMap[
                      urlParameter.key.variableId
                    ] as TemplateVariable
                  }
                  variablesMap={variablesMap}
                  globalVariablesMap={globalVariablesMap}
                  isHalf
                  multiline={false}
                  {...props}
                />
                <RequestVariableInput
                  label="Value"
                  onChange={(updatedVariable) => {
                    updateVariable(updatedVariable);
                  }}
                  variable={
                    variablesMap[
                      urlParameter.value.variableId
                    ] as TemplateVariable
                  }
                  variablesMap={variablesMap}
                  globalVariablesMap={globalVariablesMap}
                  isHalf
                  multiline={false}
                  {...props}
                />
                <DeleteOutlineIcon
                  className="hover:text-red-500 cursor-pointer mt-3"
                  onClick={() => {
                    deleteTemplateKeyValue(
                      'urlParameters',
                      urlParameter.key.variableId,
                    );
                  }}
                />
              </div>
            );
          })}
          <AddButton
            onClick={() => {
              addTemplateKeyValue('urlParameters');
            }}
          />

          <SectionLabel title="Headers" />

          {headers.map((header) => {
            return (
              <div
                className="flex w-100 justify-between space-x-3"
                key={header.key.variableId}
              >
                <RequestVariableInput
                  label="Key"
                  onChange={(updatedVariable) => {
                    updateVariable(updatedVariable);
                  }}
                  variable={
                    variablesMap[header.key.variableId] as TemplateVariable
                  }
                  variablesMap={variablesMap}
                  globalVariablesMap={globalVariablesMap}
                  isHalf
                  multiline={false}
                  {...props}
                />
                <RequestVariableInput
                  label="Value"
                  onChange={(updatedVariable) => {
                    updateVariable(updatedVariable);
                  }}
                  variable={
                    variablesMap[header.value.variableId] as TemplateVariable
                  }
                  variablesMap={variablesMap}
                  globalVariablesMap={globalVariablesMap}
                  isHalf
                  multiline={false}
                  {...props}
                />
                <DeleteOutlineIcon
                  className="hover:text-red-500 cursor-pointer mt-3"
                  onClick={() => {
                    deleteTemplateKeyValue('headers', header.key.variableId);
                  }}
                />
              </div>
            );
          })}
          <AddButton
            onClick={() => {
              addTemplateKeyValue('headers');
            }}
          />

          {requestData.method !== RequestMethodEnum.Get ? (
            <>
              <SectionLabel title="Body">
                <div className="flex items-center space-x-1">
                  <Switch
                    checked={hasRawJson}
                    color="secondary"
                    onChange={(e, checked) => {
                      setHasRawJson(checked);
                      onUpdateData({
                        ...requestData,
                        body: checked
                          ? {
                              variableId: createTemplateVariable(
                                props.addVariable,
                              ).id,
                            }
                          : [],
                      });
                    }}
                  />
                  <span className="text-sm text-primaryLight">Raw JSON</span>
                </div>
              </SectionLabel>

              {hasRawJson &&
              typeof body === 'object' &&
              !Array.isArray(body) ? (
                <RequestVariableInput
                  multiline
                  onChange={(val) => {
                    const bodyVariable = variablesMap[
                      body.variableId
                    ] as TemplateVariable;

                    if (!val.data.length) {
                      updateVariable({
                        ...bodyVariable,
                        data: [''],
                      } as TemplateVariable);
                    } else {
                      updateVariable({
                        ...bodyVariable,
                        data: val.data as TemplateData,
                      } as TemplateVariable);
                    }
                  }}
                  variable={variablesMap[body.variableId] as TemplateVariable}
                  variablesMap={variablesMap}
                  globalVariablesMap={globalVariablesMap}
                  placeholder={JSON.stringify(
                    {
                      object: {
                        key1: 'value1',
                        key2: 'value2',
                      },
                    },
                    null,
                    2,
                  )}
                  className="!min-h-[174px]"
                  {...props}
                />
              ) : (
                <>
                  {(body as RequestPayloadType[]).map(
                    (b: RequestPayloadType) => {
                      return (
                        <div
                          className="flex items-center w-100 justify-between"
                          key={b.key.variableId}
                        >
                          <RequestVariableInput
                            label="Key"
                            onChange={(updatedVariable) => {
                              updateVariable(updatedVariable);
                            }}
                            variable={
                              variablesMap[b.key.variableId] as TemplateVariable
                            }
                            variablesMap={variablesMap}
                            globalVariablesMap={globalVariablesMap}
                            isHalf
                            multiline={false}
                            {...props}
                          />
                          <RequestVariableInput
                            label="Value"
                            onChange={(updatedVariable) => {
                              updateVariable(updatedVariable);
                            }}
                            variable={
                              variablesMap[
                                b.value.variableId
                              ] as TemplateVariable
                            }
                            variablesMap={variablesMap}
                            globalVariablesMap={globalVariablesMap}
                            isHalf
                            multiline={false}
                            {...props}
                          />
                          <DeleteOutlineIcon
                            className="hover:text-red-500 cursor-pointer"
                            onClick={() => {
                              deleteTemplateKeyValue('body', b.key.variableId);
                            }}
                          />
                        </div>
                      );
                    },
                  )}
                  <AddButton
                    onClick={() => {
                      addTemplateKeyValue('body');
                    }}
                  />
                </>
              )}
            </>
          ) : null}

          {isAdmin ? (
            <>
              <SectionLabel title="Authentication" />

              <div className="flex w-100 justify-between">
                <RequestVariableInput
                  label="On behalf of user ID"
                  onChange={(updatedVariable) => {
                    updateVariable(updatedVariable);
                  }}
                  variable={
                    variablesMap[
                      requestData.auth.forUserId.value.variableId
                    ] as TemplateVariable
                  }
                  variablesMap={variablesMap}
                  globalVariablesMap={globalVariablesMap}
                  className="w-[344px]"
                  multiline={false}
                  {...props}
                />
                <Switch
                  className="mt-3"
                  checked={requestData.auth.forUserId.enabled}
                  color="secondary"
                  onChange={(_, checked) => {
                    updateAuthEnabled('forUserId', checked);
                  }}
                />
              </div>

              <div className="flex w-100 justify-between">
                <RequestVariableInput
                  label="Auth key"
                  onChange={(updatedVariable) => {
                    updateVariable(updatedVariable);
                  }}
                  variable={
                    variablesMap[
                      requestData.auth.authKey.value.variableId
                    ] as TemplateVariable
                  }
                  variablesMap={variablesMap}
                  globalVariablesMap={globalVariablesMap}
                  className="w-[344px]"
                  multiline={false}
                  {...props}
                />
                <Switch
                  className="mt-3"
                  checked={requestData.auth.authKey.enabled}
                  color="secondary"
                  onChange={(_, checked) => {
                    updateAuthEnabled('authKey', checked);
                  }}
                />
              </div>
            </>
          ) : null}

          <SectionLabel title="Response" />

          <div className="mb-6">
            <VariableChip
              className="!block !max-w-full !w-max !min-w-[auto]"
              variableId={requestData.responseVariableId}
              variablesMap={variablesMap}
              globalVariablesMap={globalVariablesMap}
            />
          </div>

          <div className="border border-slate-300 px-4 py-4 rounded-lg flex justify-between items-center space-x-4">
            <span className="font-medium text-neutral-600 text-sm">
              Continue the workflow if the request fails
            </span>
            <Switch
              checked={!requestData.blocking}
              color="secondary"
              onChange={(_, checked) => {
                onUpdateData({ ...requestData, blocking: !checked });
              }}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col space-y-7 px-8 pb-8">
        {!node.hideFromUser ? (
          <NodeCheck
            isChecked={node.data.nodeStatus === NodeStatusEnum.Checked}
            updateNodeStatus={updateNodeStatus}
          />
        ) : null}
        <Button
          color="secondary"
          fullWidth
          onClick={onCancel}
          variant="outlined"
        >
          Back to flow view
        </Button>
      </div>
    </div>
  );
}

function AddButton({ onClick }: { onClick: () => void }) {
  return (
    <Button
      className="!max-w-min"
      color="secondary"
      onClick={onClick}
      variant="outlined"
    >
      <Add />
    </Button>
  );
}
