"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScreenshotAction = void 0;
var zod_1 = require("zod");
var action_types_1 = require("../../../extensionTypes/action-types");
var helper_types_1 = require("../../../helper-types");
var legacy_schema_1 = require("../legacy.schema");
exports.ScreenshotAction = legacy_schema_1.WorkflowActionV2Base.extend({
    actionType: zod_1.z.literal(action_types_1.ActionsEnum.Screenshot),
    parameters: zod_1.z.object({
        delay: helper_types_1.DurationString.optional(),
    }),
});
