import {
  type WorkflowStopNode,
  StopNodeStatusEnum,
} from 'types-shared/workflowTypes';
import ExecutionChip from '../../../../Workflows/components/Chips/ExecutionChip';
import { ExecutionStatusEnum } from 'types-shared/executionTypes';

function StopStatus({ status }: { status: StopNodeStatusEnum }) {
  const isSuccess = status === StopNodeStatusEnum.Success;
  return (
    <ExecutionChip
      className="!h-6 !max-w-max"
      color={isSuccess ? 'success' : 'error'}
      label={
        <span className="normal-case">{isSuccess ? 'Success' : 'Failed'}</span>
      }
      status={
        isSuccess ? ExecutionStatusEnum.Success : ExecutionStatusEnum.Failed
      }
      sx={{
        '& .MuiChip-label': { textTransform: 'capitalize' },
      }}
      variant="filled"
    />
  );
}

interface Props {
  node: WorkflowStopNode;
}

export default function StopStep({ node }: Props) {
  if (!node.data.status) return null;

  return (
    <div className="flex flex-col gap-4 p-4 rounded-lg border border-indigo-light m-6">
      <p className="text-info-dark text-sm font-medium">
        Execution stopped and was marked as:
      </p>
      <StopStatus status={node.data.status} />
    </div>
  );
}
