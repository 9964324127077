import { useMemo, useState, useCallback } from 'react';
import type {
  NodeData,
  Target,
  TemplateVariable,
  WorkflowAction,
  WorkflowActionsOptions,
  WorkflowActionV2,
  WorkflowActionV2Base,
  WorkflowNode,
} from 'types-shared';
import {
  WorkflowImageNode,
  NodeStatusEnum,
  ActionsEnum,
  NodeTypesEnum,
} from 'types-shared';
import { EditorStore } from '../../../store/EditorState';
import { imageNodeEventChannel } from '../../NodeElement/SelectedImageNodeContent';
import { v4 as uuid } from 'uuid';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import { Action } from '../Action';
import { ActionHeader } from '../ActionHeader';
import {
  constructVariable,
  createTarget,
  removeNode,
} from '../../../utils/helper';
import { NodeCheck } from '../../NodeCheck';
import { isAdmin } from '../../../../../utils/env';
import {
  DragDropContext,
  Draggable,
  Droppable,
  type DropResult,
} from '@hello-pangea/dnd';
import { useSearchParams } from 'react-router-dom';
import { Button, Select, DragIndicator } from 'ui-kit';
import { clsx } from 'clsx';
import { CreateActionButtons } from '../CreateActionButtons';
import { type SelectChangeEvent } from '@mui/material/Select';
import { useFeatureFlag } from '../../../../../utils/helper';
import { FeatureFlag } from '../../../../../utils/constants';
import ActionsMenu from '../Action/ActionsMenu';
import { mfaActionDescription } from '../../../utils/constants';
import {
  createBaseV2Action,
  createDefaultOpenAction,
  createScreenshotAction,
} from './createActionV2';

export type SelectedAction = WorkflowAction & {
  i: number;
};

export const LEGACY_ACTION_TYPES: ActionsEnum[] = [
  ActionsEnum.Click,
  ActionsEnum.RightClick,
  ActionsEnum.Download,
  ActionsEnum.Input,
  ActionsEnum.Select,
  ActionsEnum.MultiChoice,
  ActionsEnum.Scrape,
  ActionsEnum.Arbitrary,
  ActionsEnum.KeyPress,
  ActionsEnum.KeyUnpress,
  ActionsEnum.UploadDocument,
  ActionsEnum.SwitchTab,
  ActionsEnum.NewTab,
  ActionsEnum.PickFromList,
  ActionsEnum.MultiSelect,
  ActionsEnum.MagicLoop,
  ActionsEnum.Wait,
  ActionsEnum.Refresh,
];

// TODO(michael): Remove this and just use Object.values(ActionsEnum)
const actionTypeOptions: ActionsEnum[] = [
  ...LEGACY_ACTION_TYPES,
  ActionsEnum.KeyboardShortcut,
  ActionsEnum.Open,
  ActionsEnum.Screenshot,
];

const actionTypeToTitleMap: Record<string, string> = {
  [ActionsEnum.Click]: 'Click',
  [ActionsEnum.RightClick]: 'Right Click',
  [ActionsEnum.Download]: 'Download',
  [ActionsEnum.Input]: 'Input',
  [ActionsEnum.Select]: 'Select',
  [ActionsEnum.MultiChoice]: 'Multiple Choice',
  [ActionsEnum.Scrape]: 'Scrape',
  [ActionsEnum.Arbitrary]: 'Arbitrary',
  [ActionsEnum.UploadDocument]: 'Upload Document',
  [ActionsEnum.NewTab]: 'New tab',
  [ActionsEnum.SwitchTab]: 'Switch tab',
  [ActionsEnum.PickFromList]: 'Pick from list',
  [ActionsEnum.KeyPress]: 'Press',
  [ActionsEnum.KeyUnpress]: 'Release',
  [ActionsEnum.MultiSelect]: 'Multi Select',
  [ActionsEnum.MagicLoop]: 'Magic Loop',
  [ActionsEnum.Wait]: 'Wait',
  [ActionsEnum.Refresh]: 'Refresh',
  [ActionsEnum.KeyboardShortcut]: 'Keyboard Shortcut',
};

interface Props {
  node: WorkflowImageNode;
  onClose: () => void;
  setSelectedAction: (actionData: SelectedAction) => void;
  onEditTarget?: (action: SelectedAction) => void;
  onMoveAction?: (action: SelectedAction) => void;
  selectedActions: string[];
  setSelectedActions: React.Dispatch<React.SetStateAction<string[]>>;
  actions: WorkflowAction[];
  onBulkMoveAction: () => void;
  onBulkEditTargets: () => void;
}

function ActionsList({
  selectedActions,
  setSelectedActions,
  node,
  setSelectedAction,
  onEditTarget,
  onMoveAction,
  actions,
  onBulkMoveAction,
  onBulkEditTargets,
}: Props) {
  const editorData = EditorStore();
  const {
    nodes,
    edges,
    setNodes,
    setEdges,
    variables,
    selectedNode,
    setSelectedNode,
    targets,
    globalVariables = {},
    addVariable,
    addTarget,
    updateVariableData,
  } = editorData;
  const [, setSearchParams] = useSearchParams();
  const [actionType, setActionType] = useState<ActionsEnum>(ActionsEnum.Click);
  const [isCreatingAction, setIsCreatingAction] = useState<boolean>(false);

  const downloadEditEnabled =
    useFeatureFlag(FeatureFlag.EditDownloads) ?? false;

  const updateNodeData = (data: Partial<NodeData>) => {
    setNodes(
      nodes.map((_node) => {
        if (_node.type !== NodeTypesEnum.Image) return _node;
        const updateNode = WorkflowImageNode.parse(_node);
        if (updateNode.id === node.id) {
          return {
            ...updateNode,
            data: {
              ...updateNode.data,
              ...data,
            },
          };
        }
        return updateNode;
      }),
    );
  };

  const updateAction = (
    action: WorkflowAction,
    options: WorkflowActionsOptions,
  ) => {
    const defaultDescription =
      action.description === mfaActionDescription ? '' : action.description;
    updateNodeData({
      actionData: {
        ...node.data.actionData,
        [action.id]: {
          ...action,
          description: options.mfa ? mfaActionDescription : defaultDescription,
          options,
        },
      },
    });
  };

  const onDeleteAction = (action: WorkflowAction) => {
    let actionData = omit(node.data.actionData, [action.id]);
    let actionOrder = node.data.actionOrder.filter((id) => id !== action.id);

    if (action.actionType === ActionsEnum.MagicLoop) {
      actionData = omitBy(
        actionData,
        (item: WorkflowAction) => item.variableId === action.variableId,
      );
      actionOrder = actionOrder.filter((id) => id in actionData);
    }

    updateNodeData({
      actionData,
      actionOrder,
    });
  };

  const addOrRemoveForDelete = (actionID: string) => {
    const isIncluded = selectedActions.includes(actionID);
    let newselectedActions: string[] = [];
    if (isIncluded) {
      newselectedActions = selectedActions.filter((a) => a !== actionID);
    } else {
      newselectedActions = [...selectedActions, actionID];
    }
    setSelectedActions(newselectedActions);
  };

  const handleOnDragEnd = (results: DropResult) => {
    const { source, destination, type } = results;
    if (!destination) {
      return null;
    }
    if (
      source.index === destination.index &&
      source.droppableId === destination.droppableId
    ) {
      return null;
    }
    if (type === 'ACTIONS') {
      const sourceIndex = source.index;
      const destinationIndex = destination.index;

      const [removedAction] = actions.splice(sourceIndex, 1);
      actions.splice(destinationIndex, 0, removedAction);
      const actionOrder = actions.map((action) => action.id);
      updateNodeData({ actionOrder });
    }
  };

  const onAddAction = (action: WorkflowAction) => {
    const actionId = action.id;
    const newActionOrder = [...node.data.actionOrder, actionId];
    const newActionData = {
      ...node.data.actionData,
      [actionId]: action,
    };
    updateNodeData({
      actionOrder: newActionOrder,
      actionData: newActionData,
    });
  };

  /**
   *  MARK: Start of V2 Action Handling
   */
  const createActionV2 = (options?: string[]) => {
    switch (actionType) {
      case ActionsEnum.KeyboardShortcut:
        updateWorkflowOnNewV2Action(
          createBaseV2Action(ActionsEnum.KeyboardShortcut, {
            adminOnly: true,
          }),
        );
        break;
      case ActionsEnum.Open:
        updateWorkflowOnNewV2Action(createDefaultOpenAction());
        break;
      case ActionsEnum.Screenshot:
        updateWorkflowOnNewV2Action(
          createScreenshotAction({ adminOnly: true }),
        );
        break;
      default:
        createAction(options);
    }
  };

  const updateWorkflowOnNewV2Action = (atv: {
    action: WorkflowActionV2Base | WorkflowActionV2;
    target: Target;
    variable: TemplateVariable;
  }) => {
    const { action, target, variable } = atv;
    updateNodeData({
      actionOrder: [...node.data.actionOrder, action.id],
      actionData: {
        ...node.data.actionData,
        [action.id]: action,
      },
    });
    addTarget(target);
    addVariable(variable);
    setIsCreatingAction(false);
  };

  /**
   *  MARK: End of V2 Action Handling
   */

  const createAction = (options?: string[]) => {
    const actionId = uuid();
    const targetId = uuid();
    const variableId = uuid();
    const isArbitraryAction = actionType === ActionsEnum.Arbitrary;
    const isWaitAction = actionType === ActionsEnum.Wait;
    const isRefreshAction = actionType === ActionsEnum.Refresh;
    const isDownload = actionType === ActionsEnum.Download;
    const actualActionType = isDownload ? ActionsEnum.Click : actionType;
    const isMagicLoopAction = actionType === ActionsEnum.MagicLoop;
    const isDocumentUploadAction = actionType === ActionsEnum.UploadDocument;

    // For upload document actions, we do not want to create a variable linked to the action immediately
    // We want to create a variable after the user has selected the source
    const action: WorkflowAction = {
      id: actionId,
      // Change download action to a click, but leave the options
      actionType: actualActionType,
      targetId,
      ...(!isDocumentUploadAction ? { variableId } : {}),
      ...(isArbitraryAction
        ? {
            title: 'Checkpoint',
          }
        : {}),
      options: {
        adminManual: isArbitraryAction,
        adminOnly: isArbitraryAction || isWaitAction || isRefreshAction,
        ...(isDownload ? { download: [] } : {}),
      },
    };

    const newActionOrder = [...node.data.actionOrder, actionId];
    const newActionData = {
      ...node.data.actionData,
      [actionId]: action,
    };
    if (isMagicLoopAction) {
      const magicLoopEndAction: WorkflowAction = {
        id: uuid(),
        actionType: ActionsEnum.MagicLoop,
        targetId,
        variableId,
        options: {
          terminal: true,
        },
      };
      newActionData[magicLoopEndAction.id] = magicLoopEndAction;
      newActionOrder.push(magicLoopEndAction.id);
    }
    updateNodeData({
      actionOrder: newActionOrder,
      actionData: newActionData,
    });
    addTarget(createTarget(targetId));

    if (!isDocumentUploadAction) {
      const newVariable = constructVariable(
        variableId,
        actualActionType,
        options,
        action,
      );

      if (newVariable) {
        addVariable(newVariable);
      }
    }

    setIsCreatingAction(false);
  };

  const deleteNode = () => {
    const { nodes: filteredNodes, edges: filteredEdges } = removeNode(
      nodes,
      edges,
      node.id,
    );
    setSearchParams({});
    setSelectedNode(null);
    setNodes(filteredNodes);
    setEdges(filteredEdges);
  };

  const selectedNodeData: WorkflowNode | undefined = useMemo(() => {
    return nodes.find((_node) => _node.id === selectedNode);
  }, [nodes, selectedNode]);

  const onBulkDeleteActions = useCallback(() => {
    const actionsForDelete = selectedActions
      .map((actionID) => actions.find((a) => a.id === actionID))
      .filter((a) => a) as WorkflowAction[];

    if (!actionsForDelete.length) return;

    const actionIds = actionsForDelete.map((a) => a.id).filter((a) => a);
    let actionData = omit(node.data.actionData, actionIds);
    let actionOrder = node.data.actionOrder.filter(
      (id) => !actionIds.includes(id),
    );

    actionsForDelete.forEach((action) => {
      if (action.actionType === ActionsEnum.MagicLoop) {
        actionData = omitBy(
          actionData,
          (item: WorkflowAction) => item.variableId === action.variableId,
        );
        actionOrder = actionOrder.filter((id) => id in actionData);
      }
    });

    updateNodeData({
      actionData,
      actionOrder,
    });
    setSelectedActions([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedActions]);

  return (
    <>
      <ActionHeader
        node={selectedNodeData}
        onDeleteNode={deleteNode}
        setSelectedNode={setSelectedNode}
      />
      <div className="actions-list-container flex flex-col flex-1 space-y-6">
        <div className="flex flex-row justify-between items-center">
          <h2 className="font-medium text-lg">Actions</h2>

          {isAdmin ? (
            <div className="flex flex-row gap-2">
              <Button
                className="h-9"
                color="error"
                variant="outlined"
                disabled={!selectedActions.length}
                onClick={onBulkDeleteActions}
              >
                DELETE ACTIONS
              </Button>
              <ActionsMenu
                allActions={actions}
                selectedActions={selectedActions}
                updateNodeData={updateNodeData}
                setSelectedActions={setSelectedActions}
                node={node}
                onUpdateActionOptions={updateAction}
                startBulkMoveAction={onBulkMoveAction}
                startBulkEditTargets={onBulkEditTargets}
                onBulkDeleteActions={onBulkDeleteActions}
              />
            </div>
          ) : null}
        </div>
        <DragDropContext
          onDragEnd={(results) => {
            handleOnDragEnd(results);
          }}
        >
          <Droppable
            direction="vertical"
            droppableId="droppable-action"
            type="ACTIONS"
            isDropDisabled={!isAdmin}
          >
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {actions.map((action, i) => (
                  <Draggable
                    draggableId={`draggable-${action.id}`}
                    index={i}
                    key={action.id}
                    isDragDisabled={!isAdmin}
                  >
                    {(providedD, snapshot) => (
                      <div
                        className="mt-4 relative"
                        ref={providedD.innerRef}
                        {...providedD.draggableProps}
                      >
                        <Action
                          action={action}
                          allowDeleteAction={isAdmin}
                          downloadEditEnabled={downloadEditEnabled || isAdmin}
                          i={i + 1}
                          imageNodeEventChannel={imageNodeEventChannel}
                          isDragging={snapshot.isDragging}
                          includedForBulkDelete={selectedActions.includes(
                            action.id,
                          )}
                          onAddAction={onAddAction}
                          onBulkDeleteCheck={addOrRemoveForDelete}
                          onDeleteAction={() => {
                            onDeleteAction(action);
                          }}
                          onEditClick={() => {
                            setSelectedAction({
                              ...action,
                              i: i + 1,
                            });
                          }}
                          onEditTarget={
                            onEditTarget
                              ? () => {
                                  onEditTarget({ ...action, i: i + 1 });
                                }
                              : undefined
                          }
                          onMoveAction={
                            onMoveAction
                              ? () => {
                                  onMoveAction({ ...action, i: i + 1 });
                                }
                              : undefined
                          }
                          onUpdateActionOptions={updateAction}
                          showManualHandleOption
                          updateVariableData={updateVariableData}
                          globalVariablesMap={globalVariables}
                          variablesMap={variables}
                          targets={targets}
                        >
                          {isAdmin ? (
                            <div
                              {...providedD.dragHandleProps}
                              className={clsx(
                                'rotate-90 text-gray-400 cursor-grab z-20 flex items-center justify-center w-8 h-8 hover:text-gray-800',
                                snapshot.isDragging && 'text-gray-800',
                              )}
                            >
                              <DragIndicator />
                            </div>
                          ) : null}
                        </Action>
                        {isAdmin ? <div>{action.id}</div> : null}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        {isCreatingAction ? (
          <div className="border rounded-lg flex justify-between items-center">
            <div className="flex flex-col text-sm w-full p-4">
              <div className="mb-6">
                <Select
                  color="secondary"
                  fullWidth
                  getLabel={(opt: string) => {
                    if (actionTypeToTitleMap[opt])
                      return actionTypeToTitleMap[opt];
                    return opt;
                  }}
                  getValue={(opt: string) => opt}
                  label="Select action type"
                  name="actionType"
                  onChange={(event: SelectChangeEvent) => {
                    setActionType(event.target.value as ActionsEnum);
                  }}
                  options={actionTypeOptions.sort()}
                  value={actionType}
                />
              </div>
              <CreateActionButtons
                actionType={actionType}
                onCancel={() => {
                  setIsCreatingAction(false);
                }}
                onSubmit={(options) => {
                  createActionV2(options);
                }}
              />
            </div>
          </div>
        ) : null}
        {!isCreatingAction && isAdmin ? (
          <Button
            color="secondary"
            onClick={() => {
              setIsCreatingAction(true);
            }}
            variant="outlined"
          >
            Add Action
          </Button>
        ) : null}

        <span className="flex-1" />
        {!node.hideFromUser ? (
          <NodeCheck
            isChecked={node.data.nodeStatus === NodeStatusEnum.Checked}
            updateNodeStatus={(status: NodeStatusEnum) => {
              updateNodeData({ nodeStatus: status });
            }}
          />
        ) : null}
      </div>
    </>
  );
}

export default ActionsList;
