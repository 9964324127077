"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NodeSelectionModeEnums = exports.WorkflowEdge = exports.WorkflowNode = exports.TemporalNodeWorkflowArgs = exports.WorkflowTemporalNode = exports.TemporalRetryPolicy = exports.WorkflowRetryNode = exports.WorkflowStopNode = exports.WorkflowEmailNode = exports.EmailProvider = exports.EmailProviderEnum = exports.EmailType = exports.EmailTypeEnum = exports.EmailActionType = exports.EmailActionEnum = exports.WorkflowRequestNode = exports.RequestPayloadType = exports.RequestIntegrationType = exports.RequestIntegrationTypeEnum = exports.RequestMethod = exports.RequestMethodEnum = exports.WorkflowDocumentNode = exports.WorkflowContinueNode = exports.StopNodeStatus = exports.StopNodeStatusEnum = exports.WorkflowFreeformNode = exports.WorkflowConditionalNode = exports.BranchData = exports.BranchMode = exports.BranchModeEnum = exports.WorkflowNewNode = exports.WorkflowSourceNode = exports.WorkflowImageNode = exports.KeyValuePair = exports.NodeData = exports.NodeImageData = exports.NodeStatus = exports.NodeStatusEnum = exports.ActionMap = exports.SelectedAction = exports.TargetStateData = exports.TargetMap = exports.Target = exports.BulkCheckableNodeTypes = exports.NodeTypes = exports.NodeTypesEnum = void 0;
var zod_1 = require("zod");
var extensionTypes_1 = require("../extensionTypes");
var logic_schema_1 = require("../logicTypes/logic.schema");
var helper_types_1 = require("../helper-types");
var react_flow_schema_1 = require("./react-flow.schema");
var variable_schema_1 = require("./variable.schema");
var legacy_schema_1 = require("./actions/legacy.schema");
var v2_1 = require("./actions/v2");
var NodeTypesEnum;
(function (NodeTypesEnum) {
    NodeTypesEnum["Image"] = "image";
    NodeTypesEnum["Source"] = "source";
    NodeTypesEnum["New"] = "new";
    NodeTypesEnum["Conditional"] = "conditional";
    NodeTypesEnum["Freeform"] = "freeform";
    NodeTypesEnum["Continue"] = "continue";
    NodeTypesEnum["Retry"] = "retry";
    NodeTypesEnum["Stop"] = "stop";
    NodeTypesEnum["Request"] = "request";
    NodeTypesEnum["Document"] = "document";
    NodeTypesEnum["Temporal"] = "temporal";
    NodeTypesEnum["Email"] = "email";
})(NodeTypesEnum || (exports.NodeTypesEnum = NodeTypesEnum = {}));
exports.NodeTypes = zod_1.z.nativeEnum(NodeTypesEnum);
exports.BulkCheckableNodeTypes = [
    NodeTypesEnum.Image,
    NodeTypesEnum.Continue,
    NodeTypesEnum.Document,
    NodeTypesEnum.Freeform,
    NodeTypesEnum.Conditional,
    NodeTypesEnum.Stop,
    NodeTypesEnum.Retry,
    NodeTypesEnum.Request,
    NodeTypesEnum.Temporal,
    NodeTypesEnum.Email,
];
exports.Target = zod_1.z.object({
    id: zod_1.z.string(),
    name: zod_1.z.string().optional(),
    ref: extensionTypes_1.QueryPaths,
    coordinates: extensionTypes_1.RelativeCoordinates,
});
exports.TargetMap = zod_1.z.record(exports.Target);
exports.TargetStateData = zod_1.z.object({
    targets: exports.TargetMap,
});
exports.SelectedAction = zod_1.z.object({
    nodeId: zod_1.z.string(),
    actionId: zod_1.z.string(),
});
exports.ActionMap = zod_1.z.record(zod_1.z.union([legacy_schema_1.WorkflowAction, v2_1.WorkflowActionV2]));
var NodeStatusEnum;
(function (NodeStatusEnum) {
    NodeStatusEnum["NotViewed"] = "NotViewed";
    NodeStatusEnum["Viewed"] = "Viewed";
    NodeStatusEnum["Checked"] = "Checked";
    NodeStatusEnum["Autolinked"] = "Autolinked";
    NodeStatusEnum["Warning"] = "Warning";
    NodeStatusEnum["Error"] = "Error";
    NodeStatusEnum["Imported"] = "Imported";
})(NodeStatusEnum || (exports.NodeStatusEnum = NodeStatusEnum = {}));
exports.NodeStatus = zod_1.z.nativeEnum(NodeStatusEnum);
exports.NodeImageData = zod_1.z.object({
    imageId: zod_1.z.string(),
});
exports.NodeData = zod_1.z.object({
    imageData: exports.NodeImageData,
    actionData: exports.ActionMap,
    actionOrder: zod_1.z.array(zod_1.z.string()),
    nodeUrls: zod_1.z.array(zod_1.z.string()),
    nodeStatus: exports.NodeStatus,
    selected: zod_1.z.boolean().optional(),
    // MARK: Desktop support
    // TODO: Make this required when backwards compatibility is removed (?)
    // TODO: Set this on web image nodes
    platform: helper_types_1.ActionPlatform.optional(),
});
exports.KeyValuePair = zod_1.z.object({
    key: variable_schema_1.VariableIdContainer,
    value: variable_schema_1.VariableIdContainer,
});
exports.WorkflowImageNode = react_flow_schema_1.RfNode.extend({
    type: zod_1.z.literal(NodeTypesEnum.Image),
    data: exports.NodeData,
});
exports.WorkflowSourceNode = react_flow_schema_1.RfNode.extend({
    type: zod_1.z.literal(NodeTypesEnum.Source),
    data: zod_1.z
        .object({
        variableId: zod_1.z.string(),
        description: zod_1.z.string().optional(),
    })
        .merge(exports.NodeData.pick({ nodeStatus: true })),
});
exports.WorkflowNewNode = react_flow_schema_1.RfNode.extend({
    type: zod_1.z.literal(NodeTypesEnum.New),
    data: exports.NodeData.pick({ nodeStatus: true }),
});
var BranchModeEnum;
(function (BranchModeEnum) {
    BranchModeEnum["Rule"] = "Rule";
    BranchModeEnum["Instruction"] = "Instruction";
})(BranchModeEnum || (exports.BranchModeEnum = BranchModeEnum = {}));
exports.BranchMode = zod_1.z.nativeEnum(BranchModeEnum);
exports.BranchData = zod_1.z.object({
    branchId: zod_1.z.string(),
    rule: logic_schema_1.Rule.optional(),
    instruction: variable_schema_1.VariableIdContainer,
    selectedMode: exports.BranchMode,
});
exports.WorkflowConditionalNode = react_flow_schema_1.RfNode.extend({
    type: zod_1.z.literal(NodeTypesEnum.Conditional),
    data: exports.NodeData.pick({ nodeStatus: true, selected: true }).extend({
        branchesData: zod_1.z.array(exports.BranchData).optional(),
        aiConfig: zod_1.z
            .object({
            model: zod_1.z.string(),
        })
            .optional(),
    }),
    // TODO: remove this -- this is for a specific M&M feature
    errorOverlay: zod_1.z.boolean().optional(),
});
exports.WorkflowFreeformNode = react_flow_schema_1.RfNode.extend({
    type: zod_1.z.literal(NodeTypesEnum.Freeform),
    data: exports.NodeData.pick({ nodeStatus: true, selected: true }).extend({
        instructions: variable_schema_1.VariableIdContainer,
    }),
});
var StopNodeStatusEnum;
(function (StopNodeStatusEnum) {
    StopNodeStatusEnum["Failure"] = "failure";
    StopNodeStatusEnum["Success"] = "success";
})(StopNodeStatusEnum || (exports.StopNodeStatusEnum = StopNodeStatusEnum = {}));
exports.StopNodeStatus = zod_1.z.nativeEnum(StopNodeStatusEnum);
exports.WorkflowContinueNode = react_flow_schema_1.RfNode.extend({
    type: zod_1.z.literal(NodeTypesEnum.Continue),
    data: exports.NodeData.pick({ nodeStatus: true, selected: true }),
});
exports.WorkflowDocumentNode = react_flow_schema_1.RfNode.extend({
    type: zod_1.z.literal(NodeTypesEnum.Document),
    data: exports.NodeData.pick({ nodeStatus: true, selected: true }).extend({
        surfaceOutputVariables: zod_1.z.boolean(),
    }),
});
var RequestMethodEnum;
(function (RequestMethodEnum) {
    RequestMethodEnum["Get"] = "GET";
    RequestMethodEnum["Post"] = "POST";
    RequestMethodEnum["Put"] = "PUT";
    RequestMethodEnum["Patch"] = "PATCH";
    RequestMethodEnum["Delete"] = "DELETE";
})(RequestMethodEnum || (exports.RequestMethodEnum = RequestMethodEnum = {}));
exports.RequestMethod = zod_1.z.nativeEnum(RequestMethodEnum);
var RequestIntegrationTypeEnum;
(function (RequestIntegrationTypeEnum) {
    RequestIntegrationTypeEnum["None"] = "None";
    RequestIntegrationTypeEnum["Salesforce"] = "Salesforce";
})(RequestIntegrationTypeEnum || (exports.RequestIntegrationTypeEnum = RequestIntegrationTypeEnum = {}));
exports.RequestIntegrationType = zod_1.z.nativeEnum(RequestIntegrationTypeEnum);
exports.RequestPayloadType = zod_1.z.object({
    key: variable_schema_1.VariableIdContainer,
    value: variable_schema_1.VariableIdContainer,
});
exports.WorkflowRequestNode = react_flow_schema_1.RfNode.extend({
    type: zod_1.z.literal(NodeTypesEnum.Request),
    data: exports.NodeData.pick({ nodeStatus: true, selected: true }).extend({
        integrationType: exports.RequestIntegrationType.optional(),
        description: zod_1.z.string().optional(),
        changesResolved: zod_1.z.boolean().default(true).optional(),
        method: exports.RequestMethod,
        url: variable_schema_1.VariableIdContainer,
        urlParameters: zod_1.z.array(exports.RequestPayloadType),
        headers: zod_1.z.array(exports.RequestPayloadType),
        body: zod_1.z.array(exports.RequestPayloadType).or(variable_schema_1.VariableIdContainer),
        auth: zod_1.z.object({
            authKey: zod_1.z.object({
                enabled: zod_1.z.boolean(),
                value: variable_schema_1.VariableIdContainer,
            }),
            forUserId: zod_1.z.object({
                enabled: zod_1.z.boolean(),
                value: variable_schema_1.VariableIdContainer,
            }),
        }),
        responseVariableId: zod_1.z.string(),
        blocking: zod_1.z.boolean(),
    }),
});
var EmailActionEnum;
(function (EmailActionEnum) {
    EmailActionEnum["SendEmail"] = "SendEmail";
    EmailActionEnum["CreateContact"] = "CreateContact";
    EmailActionEnum["CreateDraft"] = "CreateDraft";
    EmailActionEnum["ForwardEmail"] = "ForwardEmail";
    EmailActionEnum["MoveMailToFolder"] = "MoveMailToFolder";
})(EmailActionEnum || (exports.EmailActionEnum = EmailActionEnum = {}));
exports.EmailActionType = zod_1.z.nativeEnum(EmailActionEnum);
var EmailTypeEnum;
(function (EmailTypeEnum) {
    EmailTypeEnum["NewEmail"] = "NewEmail";
    EmailTypeEnum["ReplyToEmailThatTriggeredWorkflow"] = "ReplyToEmailThatTriggeredWorkflow";
    EmailTypeEnum["ReplyAllToEmailThatTriggeredWorkflow"] = "ReplyAllToEmailThatTriggeredWorkflow";
    EmailTypeEnum["NewDraft"] = "NewDraft";
})(EmailTypeEnum || (exports.EmailTypeEnum = EmailTypeEnum = {}));
exports.EmailType = zod_1.z.nativeEnum(EmailTypeEnum);
var EmailProviderEnum;
(function (EmailProviderEnum) {
    EmailProviderEnum["Gmail"] = "gmail";
    EmailProviderEnum["Outlook"] = "outlook";
})(EmailProviderEnum || (exports.EmailProviderEnum = EmailProviderEnum = {}));
exports.EmailProvider = zod_1.z.nativeEnum(EmailProviderEnum);
exports.WorkflowEmailNode = react_flow_schema_1.RfNode.extend({
    type: zod_1.z.literal(NodeTypesEnum.Email),
    data: exports.NodeData.pick({ nodeStatus: true, selected: true }).extend({
        emailAction: exports.EmailActionType.optional(),
        description: zod_1.z.string().optional(),
        emailProvider: exports.EmailProvider.optional(),
        sendEmailDetails: zod_1.z
            .object({
            emailType: exports.EmailType,
            recipients: variable_schema_1.VariableIdContainer,
            ccRecipients: variable_schema_1.VariableIdContainer.optional(),
            bccRecipients: variable_schema_1.VariableIdContainer.optional(),
            sender: variable_schema_1.VariableIdContainer,
            subject: variable_schema_1.VariableIdContainer,
            emailBody: variable_schema_1.VariableIdContainer,
            attachments: variable_schema_1.VariableIdContainer.optional(),
            additionalHeaders: zod_1.z.array(exports.KeyValuePair).optional(),
        })
            .optional(),
        createContactDetails: zod_1.z
            .object({
            givenName: variable_schema_1.VariableIdContainer,
            lastName: variable_schema_1.VariableIdContainer,
            email: variable_schema_1.VariableIdContainer,
            additionalDetails: zod_1.z.array(exports.KeyValuePair).optional(),
        })
            .optional(),
    }),
});
exports.WorkflowStopNode = react_flow_schema_1.RfNode.extend({
    type: zod_1.z.literal(NodeTypesEnum.Stop),
    data: exports.NodeData.pick({ nodeStatus: true, selected: true }).extend({
        status: exports.StopNodeStatus.optional(),
    }),
});
exports.WorkflowRetryNode = react_flow_schema_1.RfNode.extend({
    type: zod_1.z.literal(NodeTypesEnum.Retry),
    data: exports.NodeData.pick({ nodeStatus: true, selected: true }).extend({
        // TODO: rename to retryIntervalMinutes
        // TODO: make retryInterval required
        retryInterval: zod_1.z.number().nullable(),
        // TODO: make maxAttempts required
        maxAttempts: zod_1.z.number().nullable(),
    }),
});
exports.TemporalRetryPolicy = zod_1.z.object({
    /**
     * Coefficient used to calculate the next retry interval.
     * The next retry interval is previous interval multiplied by this coefficient.
     */
    backoffCoefficient: zod_1.z.number().optional(),
    /**
     * Interval of the first retry.
     * If coefficient is 1 then it is used for all retries
     */
    initialInterval: helper_types_1.DurationString.optional(),
    /**
     * Maximum number of attempts. When exceeded, retries stop (even if {@link ActivityOptions.scheduleToCloseTimeout}
     * hasn't been reached).
     */
    maximumAttempts: zod_1.z.number().optional(),
    /**
     * Maximum interval between retries.
     * Exponential backoff leads to interval increase.
     * This value is the cap of the increase.
     */
    maximumInterval: helper_types_1.DurationString.optional(),
    /**
     * List of application failures types to not retry.
     */
    nonRetryableErrorTypes: zod_1.z.array(zod_1.z.string()).optional(),
});
exports.WorkflowTemporalNode = react_flow_schema_1.RfNode.extend({
    type: zod_1.z.literal(NodeTypesEnum.Temporal),
    data: exports.NodeData.pick({
        nodeStatus: true,
        selected: true,
        platform: true,
    }).extend({
        workflowType: variable_schema_1.VariableIdContainer,
        taskQueue: variable_schema_1.VariableIdContainer,
        // TODO: Support multiple args (?)
        // workflowArgs: z.array(ListOfKeyValuePairs),
        // TODO: Support string args (?)
        // workflowArgs: z.union([ListOfKeyValuePairs, VariableIdContainer]),
        workflowArgs: zod_1.z.array(exports.KeyValuePair),
        // TODO: Consider allowing for assigning response values to variables
        // TODO: Add temporal platform type enum
        retryPolicy: exports.TemporalRetryPolicy.nullish(),
    }),
});
// TODO: Rename to TemporalTypescriptNodeWorkflowArgs (?) + add python version
exports.TemporalNodeWorkflowArgs = zod_1.z.intersection(
// Default arguments
zod_1.z.object({
    workflowId: zod_1.z.string(),
    executionId: zod_1.z.string(),
    nodeId: zod_1.z.string(),
    // TODO: Add optional actionId ?
    attempt: zod_1.z.number(),
    sessionId: zod_1.z.string().optional(),
}), 
// Additional user-provided arguments configured in editor
zod_1.z.record(zod_1.z.string(), zod_1.z.any()));
// TODO: BACKWARDS COMPATIBILITY:
// END BACKWARDS COMPATIBILITY
exports.WorkflowNode = zod_1.z.union([
    exports.WorkflowImageNode,
    exports.WorkflowSourceNode,
    exports.WorkflowNewNode,
    exports.WorkflowConditionalNode,
    exports.WorkflowFreeformNode,
    exports.WorkflowStopNode,
    exports.WorkflowRetryNode,
    exports.WorkflowContinueNode,
    exports.WorkflowRequestNode,
    exports.WorkflowDocumentNode,
    exports.WorkflowTemporalNode,
    exports.WorkflowEmailNode,
]);
exports.WorkflowEdge = react_flow_schema_1.RfEdge.extend({
    data: zod_1.z
        .object({
        actionIndex: zod_1.z.number(),
    })
        .optional(),
});
var NodeSelectionModeEnums;
(function (NodeSelectionModeEnums) {
    NodeSelectionModeEnums["BulkCheck"] = "BulkCheck";
    NodeSelectionModeEnums["MergeNodes"] = "MergeNodes";
})(NodeSelectionModeEnums || (exports.NodeSelectionModeEnums = NodeSelectionModeEnums = {}));
