import {
  WorkflowRunVariables,
  type WorkflowRunVariablesProps,
} from './WorkflowRunVariables';
import { Button } from 'ui-kit';

interface RunWorkflowProps extends WorkflowRunVariablesProps {
  handleSubmit: () => void;
  onClose: () => void;
}

function RunWorkflow({
  variables,
  isFetchingNodeViewData,
  isPendingTest,
  setVariableState,
  variableState,
  handleSubmit,
  onClose,
}: RunWorkflowProps) {
  return (
    <>
      <div className="flex flex-col px-10 overflow-y-auto flex-1">
        <WorkflowRunVariables
          className="!overflow-y-hidden !min-h-min"
          variables={variables}
          isFetchingNodeViewData={isFetchingNodeViewData}
          isPendingTest={isPendingTest}
          setVariableState={setVariableState}
          variableState={variableState}
        />
      </div>

      <div className="flex flex-col pt-6 border-t border-gray-200 pb-10 px-10">
        <span className="text-gray-500 text-sm">
          By clicking “Run workflow” the workflow test will start running and
          you’ll be redirected to the execution list page.
        </span>
        <div className="!mt-5 flex space-x-4">
          <Button
            color="secondary"
            loading={isPendingTest}
            onClick={handleSubmit}
            disabled={isFetchingNodeViewData}
            variant="contained"
          >
            Run Workflow
          </Button>
          <Button color="secondary" onClick={onClose} variant="outlined">
            Cancel
          </Button>
        </div>
      </div>
    </>
  );
}

export default RunWorkflow;
