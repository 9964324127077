"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubVariableExtractor = void 0;
var logicTypes_1 = require("../logicTypes");
var legacy_schema_1 = require("./actions/legacy.schema");
var variable_schema_1 = require("./variable.schema");
var workflow_schema_1 = require("./workflow.schema");
/**
 * A class to handle variable extraction and processing.
 */
var SubVariableExtractor = /** @class */ (function () {
    function SubVariableExtractor(variableMap, handleException) {
        this.variableMap = variableMap;
        this.handleException = handleException;
    }
    /**
     * Extracts a variable reference and its related variables.
     * @param variableId - The ID of the variable to extract.
     * @returns A record of extracted variables.
     */
    SubVariableExtractor.prototype.extractVariableRef = function (variableId) {
        var _a;
        var _b, _c;
        // Extract the variable from the variable map
        var variable = this.variableMap[variableId];
        if (!variable_schema_1.Variable.safeParse(variable).success) {
            this.handleException(new Error('Invalid variable reference'), {
                name: 'Invalid variable reference',
                source: 'extract-variables.helper/extractVariableRef',
                extra: {
                    variableId: variableId,
                    variableMap: this.variableMap,
                },
            });
            return {};
        }
        var extractedVariables = (_a = {},
            _a[variable.id] = variable,
            _a);
        // Extract variables from the query if it exists
        if ((_c = (_b = variable.dashboardData) === null || _b === void 0 ? void 0 : _b.transformInputs) === null || _c === void 0 ? void 0 : _c.query) {
            Object.assign(extractedVariables, this.extractVariablesFromTemplateData(variable.dashboardData.transformInputs.query));
        }
        // Extract subvariables
        var subVariables = this.extractSubVariablesByType(variable);
        Object.assign(extractedVariables, subVariables);
        return extractedVariables;
    };
    /**
     * Extracts variables from template data.
     * @param templateData - The template data to process.
     * @returns A record of extracted variables.
     */
    SubVariableExtractor.prototype.extractVariablesFromTemplateData = function (templateData) {
        var _this = this;
        var extractedVariables = {};
        templateData.forEach(function (item) {
            var variableRef = variable_schema_1.VariableRef.safeParse(item);
            if (variableRef.success) {
                Object.assign(extractedVariables, _this.extractVariableRef(variableRef.data.id));
            }
        });
        return extractedVariables;
    };
    SubVariableExtractor.prototype.extractVariablesFromGroup = function (group) {
        var extractedVariables = {};
        for (var _i = 0, _a = group.elements; _i < _a.length; _i++) {
            var element = _a[_i];
            if ((0, logicTypes_1.isGroup)(element)) {
                Object.assign(extractedVariables, this.extractVariablesFromGroup(element));
            }
            else if ((0, logicTypes_1.isCondition)(element)) {
                Object.assign(extractedVariables, this.extractVariablesFromCondition(element));
            }
        }
        return extractedVariables;
    };
    SubVariableExtractor.prototype.extractVariablesFromCondition = function (condition) {
        var extractedVariables = {};
        // Extract variables from the field
        Object.assign(extractedVariables, this.extractVariableRef(condition.field.variableId));
        // Extract variables from the value
        Object.assign(extractedVariables, this.extractVariableRef(condition.value.variableId));
        return extractedVariables;
    };
    SubVariableExtractor.prototype.extractVariablesFromRule = function (rule) {
        var extractedVariables = {};
        // Extract variables from the rule's data (Group)
        Object.assign(extractedVariables, this.extractVariablesFromGroup(rule.data));
        // Extract variables from the rule's output
        for (var _i = 0, _a = rule.output; _i < _a.length; _i++) {
            var output = _a[_i];
            if (variable_schema_1.VariableIdContainer.safeParse(output).success) {
                // This is a VariableIdContainer
                Object.assign(extractedVariables, this.extractVariableRef(variable_schema_1.VariableIdContainer.parse(output).variableId));
            }
            // Note: We don't need to extract variables from BranchRef as it doesn't contain any variable references
        }
        return extractedVariables;
    };
    /**
     * Extracts subvariables by parent variable type.
     * NOTE: This method only handles the specific subvariables within nested variables, should not be called directly
     * @param variable - The variable to process.
     * @param extractedVariables - The extracted variables.
     * @returns A record of extracted variables.
     */
    SubVariableExtractor.prototype.extractSubVariablesByType = function (variable) {
        switch (variable.type) {
            case variable_schema_1.VariableTypeEnum.Query:
                return this.extractVariablesFromTemplateData(variable.data.query);
            // These variables are all subtypes of TemplateVariable
            case variable_schema_1.VariableTypeEnum.Template:
            case variable_schema_1.VariableTypeEnum.Select:
            case variable_schema_1.VariableTypeEnum.MultiSelect:
            case variable_schema_1.VariableTypeEnum.MultiChoice:
                return this.extractVariablesFromTemplateData(variable.data);
            case variable_schema_1.VariableTypeEnum.Document:
                if (variable.data.url) {
                    return this.extractVariablesFromTemplateData(variable.data.url);
                }
                return {};
            case variable_schema_1.VariableTypeEnum.Tab:
                return this.extractVariablesFromTemplateData(variable.data.url);
            // These variables all have no subvariables
            case variable_schema_1.VariableTypeEnum.Execution:
            case variable_schema_1.VariableTypeEnum.Global:
            case variable_schema_1.VariableTypeEnum.Source:
            case variable_schema_1.VariableTypeEnum.Scrape:
            case variable_schema_1.VariableTypeEnum.EmailTrigger:
                return {};
        }
    };
    SubVariableExtractor.prototype.extractVariablesFromNode = function (node, omitSkippedVariables) {
        if (omitSkippedVariables === void 0) { omitSkippedVariables = false; }
        var extractedVariables = {};
        switch (node.type) {
            case workflow_schema_1.NodeTypesEnum.Image:
                Object.assign(extractedVariables, this.extractVariablesFromImageNode(node, omitSkippedVariables));
                break;
            case workflow_schema_1.NodeTypesEnum.Source:
                Object.assign(extractedVariables, this.extractVariableRef(node.data.variableId));
                break;
            case workflow_schema_1.NodeTypesEnum.Conditional:
                Object.assign(extractedVariables, this.extractVariablesFromConditionalNode(node));
                break;
            case workflow_schema_1.NodeTypesEnum.Freeform:
                Object.assign(extractedVariables, this.extractVariableRef(node.data.instructions.variableId));
                break;
            case workflow_schema_1.NodeTypesEnum.Request:
                Object.assign(extractedVariables, this.extractVariablesFromRequestNode(node));
                break;
            case workflow_schema_1.NodeTypesEnum.Temporal:
                Object.assign(extractedVariables, this.extractVariablesFromTemporalNode(node));
                break;
            case workflow_schema_1.NodeTypesEnum.Email:
                Object.assign(extractedVariables, this.extractVariablesFromEmailTriggerNode(node));
                break;
            // These nodes don't have variables to extract
            case workflow_schema_1.NodeTypesEnum.Document:
            case workflow_schema_1.NodeTypesEnum.Continue:
            case workflow_schema_1.NodeTypesEnum.New:
            case workflow_schema_1.NodeTypesEnum.Retry:
            case workflow_schema_1.NodeTypesEnum.Stop:
                break;
        }
        return extractedVariables;
    };
    SubVariableExtractor.prototype.extractVariablesFromImageNodeAction = function (action, omitSkippedVariables) {
        var _a;
        if (omitSkippedVariables === void 0) { omitSkippedVariables = false; }
        var extractedVariables = {};
        if (action.criteria === legacy_schema_1.ActionValueCriteriaEnum.Condition) {
            if (action.rules) {
                for (var _i = 0, _b = action.rules; _i < _b.length; _i++) {
                    var rule = _b[_i];
                    Object.assign(extractedVariables, this.extractVariablesFromRule(rule));
                }
            }
        }
        else if (action.variableId &&
            (!omitSkippedVariables || !((_a = action.options) === null || _a === void 0 ? void 0 : _a.hidden))) {
            Object.assign(extractedVariables, this.extractVariableRef(action.variableId));
        }
        else {
            this.handleException(new Error("Invalid criteria for action value: ".concat(String(action.criteria), " and variable id: ").concat(String(action.variableId))), {
                name: 'Invalid criteria for action value',
                source: 'extract-variables.helper/extractVariablesFromImageNodeAction',
                extra: { action: action },
            });
        }
        return extractedVariables;
    };
    SubVariableExtractor.prototype.extractVariablesFromImageNode = function (node, omitSkippedVariables) {
        if (omitSkippedVariables === void 0) { omitSkippedVariables = false; }
        var extractedVariables = {};
        var actionData = node.data.actionData;
        for (var _i = 0, _a = Object.values(actionData); _i < _a.length; _i++) {
            var action = _a[_i];
            Object.assign(extractedVariables, this.extractVariablesFromImageNodeAction(action, omitSkippedVariables));
        }
        return extractedVariables;
    };
    SubVariableExtractor.prototype.extractVariablesFromConditionalNode = function (node) {
        var extractedVariables = {};
        var branchesData = node.data.branchesData;
        if (!branchesData) {
            return {};
        }
        for (var _i = 0, branchesData_1 = branchesData; _i < branchesData_1.length; _i++) {
            var branch = branchesData_1[_i];
            var selectedMode = branch.selectedMode, instruction = branch.instruction, rule = branch.rule;
            if (selectedMode === workflow_schema_1.BranchModeEnum.Instruction) {
                Object.assign(extractedVariables, this.extractVariableRef(instruction.variableId));
            }
            else if (rule) {
                Object.assign(extractedVariables, this.extractVariablesFromRule(rule));
            }
            else {
                this.handleException(new Error('Invalid branch type'), {
                    name: 'Invalid branch type',
                    source: 'extract-variables.helper/extractVariablesFromConditionalNode',
                    extra: { node: node, branch: branch },
                });
                return {};
            }
        }
        return extractedVariables;
    };
    SubVariableExtractor.prototype.extractVariablesFromRequestNode = function (node) {
        var extractedVariables = {};
        // Extract variables from URL
        Object.assign(extractedVariables, this.extractVariableRef(node.data.url.variableId));
        // Extract variables from URL parameters
        for (var _i = 0, _a = node.data.urlParameters; _i < _a.length; _i++) {
            var param = _a[_i];
            Object.assign(extractedVariables, this.extractVariableRef(param.key.variableId), this.extractVariableRef(param.value.variableId));
        }
        // Extract variables from headers
        for (var _b = 0, _c = node.data.headers; _b < _c.length; _b++) {
            var header = _c[_b];
            Object.assign(extractedVariables, this.extractVariableRef(header.key.variableId), this.extractVariableRef(header.value.variableId));
        }
        // Extract variables from body
        if (Array.isArray(node.data.body)) {
            for (var _d = 0, _e = node.data.body; _d < _e.length; _d++) {
                var bodyItem = _e[_d];
                Object.assign(extractedVariables, this.extractVariableRef(bodyItem.key.variableId), this.extractVariableRef(bodyItem.value.variableId));
            }
        }
        else {
            Object.assign(extractedVariables, this.extractVariableRef(node.data.body.variableId));
        }
        // Extract variables from auth
        if (node.data.auth.authKey.enabled) {
            Object.assign(extractedVariables, this.extractVariableRef(node.data.auth.authKey.value.variableId));
        }
        if (node.data.auth.forUserId.enabled) {
            Object.assign(extractedVariables, this.extractVariableRef(node.data.auth.forUserId.value.variableId));
        }
        // Extract response variable
        Object.assign(extractedVariables, this.extractVariableRef(node.data.responseVariableId));
        return extractedVariables;
    };
    SubVariableExtractor.prototype.extractVariablesFromTemporalNode = function (node) {
        var extractedVariables = {};
        // Extract workflow type variable
        Object.assign(extractedVariables, this.extractVariableRef(node.data.workflowType.variableId));
        // Extract task queue variable
        Object.assign(extractedVariables, this.extractVariableRef(node.data.taskQueue.variableId));
        // Extract workflow args variables
        for (var _i = 0, _a = node.data.workflowArgs; _i < _a.length; _i++) {
            var arg = _a[_i];
            Object.assign(extractedVariables, this.extractVariableRef(arg.key.variableId), this.extractVariableRef(arg.value.variableId));
        }
        return extractedVariables;
    };
    /**
     * Helper method to extract variables from an array of variable containers
     */
    SubVariableExtractor.prototype.extractFromContainers = function (containers) {
        var _this = this;
        var extractedVariables = {};
        containers.forEach(function (container) {
            if (container === null || container === void 0 ? void 0 : container.variableId) {
                Object.assign(extractedVariables, _this.extractVariableRef(container.variableId));
            }
        });
        return extractedVariables;
    };
    /**
     * Helper method to extract variables from an array of key-value pairs
     */
    SubVariableExtractor.prototype.extractFromKeyValuePairs = function (pairs) {
        var _this = this;
        var extractedVariables = {};
        if (!pairs)
            return extractedVariables;
        pairs.forEach(function (pair) {
            Object.assign(extractedVariables, _this.extractFromContainers([pair.key, pair.value]));
        });
        return extractedVariables;
    };
    SubVariableExtractor.prototype.extractVariablesFromEmailTriggerNode = function (node) {
        var extractedVariables = {};
        if (node.data.sendEmailDetails) {
            var sendEmailDetails = node.data.sendEmailDetails;
            // Required fields first
            Object.assign(extractedVariables, this.extractFromContainers([
                sendEmailDetails.recipients,
                sendEmailDetails.sender,
                sendEmailDetails.subject,
                sendEmailDetails.emailBody,
            ]), 
            // Optional fields
            this.extractFromContainers([
                sendEmailDetails.ccRecipients,
                sendEmailDetails.bccRecipients,
                sendEmailDetails.attachments,
            ]), this.extractFromKeyValuePairs(sendEmailDetails.additionalHeaders));
        }
        if (node.data.createContactDetails) {
            var createContactDetails = node.data.createContactDetails;
            Object.assign(extractedVariables, this.extractFromContainers([
                createContactDetails.givenName,
                createContactDetails.lastName,
                createContactDetails.email,
            ]), this.extractFromKeyValuePairs(createContactDetails.additionalDetails));
        }
        return extractedVariables;
    };
    SubVariableExtractor.prototype.extractVariablesFromNodes = function (nodes, omitSkippedVariables) {
        if (omitSkippedVariables === void 0) { omitSkippedVariables = false; }
        var extractedVariables = {};
        for (var _i = 0, nodes_1 = nodes; _i < nodes_1.length; _i++) {
            var node = nodes_1[_i];
            Object.assign(extractedVariables, this.extractVariablesFromNode(node, omitSkippedVariables));
        }
        return extractedVariables;
    };
    return SubVariableExtractor;
}());
exports.SubVariableExtractor = SubVariableExtractor;
