import { useState } from 'react';
import { EditorStore } from '../../../store/EditorState';
import { Input, Button } from 'ui-kit';
import {
  type NodeData,
  NodeTypesEnum,
  WorkflowImageNode,
  type Variable,
  type ScreenshotAction,
  VariableTypeEnum,
} from 'types-shared/workflowTypes';

export function EditScreenshotAction({
  action,
  node,
  onSaveAndExit,
  updateVariable,
}: {
  action: ScreenshotAction;
  node: WorkflowImageNode;
  onSaveAndExit: () => void;
  updateVariable: (variable: Variable) => void;
}) {
  const editorData = EditorStore();
  const { nodes, setNodes } = editorData;

  const [screenshotAction, setScreenshotAction] =
    useState<ScreenshotAction>(action);

  const updateNodeActionData = (updatedData: Pick<NodeData, 'actionData'>) => {
    setNodes(
      nodes.map((_node) => {
        if (_node.type !== NodeTypesEnum.Image) return _node;
        const updateNode = WorkflowImageNode.parse(_node);
        if (updateNode.id === node.id) {
          return {
            ...updateNode,
            data: {
              ...updateNode.data,
              ...updatedData,
            },
          };
        }
        return updateNode;
      }),
    );
  };

  const updatePreviewVariable = () => {
    const variableId = action.variableId;
    if (!variableId) {
      throw new Error(`No variableId set for action ${action.id}`);
    }

    updateVariable({
      id: variableId,
      type: VariableTypeEnum.Template,
      data: [
        screenshotAction.parameters.delay
          ? screenshotAction.parameters.delay
          : 'None',
      ],
    });
  };

  const updateAction = () => {
    updateNodeActionData({
      actionData: {
        ...node.data.actionData,
        [screenshotAction.id]: screenshotAction,
      },
    });
    updatePreviewVariable();
  };

  const handleSaveAndExit = () => {
    updateAction();
    onSaveAndExit();
  };

  return (
    <div className="mt-10 flex flex-col gap-4 h-full justify-between">
      <div className="flex flex-col gap-4">
        <div className="!mt-4">
          <Input
            floatingLabel
            label="Delay"
            value={screenshotAction.parameters.delay}
            onChange={(val) => {
              setScreenshotAction({
                ...screenshotAction,
                parameters: {
                  delay: val,
                },
              });
            }}
          />
        </div>
      </div>

      <div className="w-full">
        <Button
          className="!text-info !border-info !mt-5 w-full"
          color="secondary"
          onClick={handleSaveAndExit}
          variant="outlined"
        >
          Save & Exit
        </Button>
      </div>
    </div>
  );
}
