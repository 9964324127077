"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createScreenshotStep = void 0;
const replay_1 = require("@puppeteer/replay");
const createScreenshotStep = (id, parameters = {}) => {
    return {
        id,
        type: replay_1.StepType.CustomStep,
        name: 'screenshot',
        parameters,
    };
};
exports.createScreenshotStep = createScreenshotStep;
