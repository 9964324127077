import { useEffect, useState } from 'react';
import {
  KeyboardShortcutAction,
  type NodeData,
  NodeTypesEnum,
  type Variable,
  VariableTypeEnum,
  type WorkflowActionV2Base,
  WorkflowImageNode,
} from 'types-shared';
import {
  EXCEL_SHORCUTS,
  EXCEL_DATATAB_SHORCUTS,
  EXCEL_HOMETAB_SHORCUTS,
  KEYBOARD_SHORCUTS,
  FILE_EXPLORER_SHORCUTS,
} from 'desktop-shared';
import { EditorStore } from '../../../store/EditorState';
import { Input, Button, Select } from 'ui-kit';
import { captureException } from '@sentry/react';

const ALL_SHORTCUTS = [
  ...KEYBOARD_SHORCUTS,
  ...EXCEL_SHORCUTS,
  ...EXCEL_DATATAB_SHORCUTS,
  ...EXCEL_HOMETAB_SHORCUTS,
  ...FILE_EXPLORER_SHORCUTS,
];

const DEFAULT_ACTION = KEYBOARD_SHORCUTS[0];

export function EditKeyboardShortcutAction({
  action,
  node,
  onSaveAndExit,
  updateVariable,
}: {
  action: WorkflowActionV2Base;
  node: WorkflowImageNode;
  onSaveAndExit: () => void;
  updateVariable: (variable: Variable) => void;
}) {
  const editorData = EditorStore();
  const { nodes, setNodes } = editorData;

  const [selectedAction, setSelectedAction] = useState<KeyboardShortcutAction>({
    ...DEFAULT_ACTION,
    id: action.id,
  });

  const updateNodeActionData = (updatedData: Pick<NodeData, 'actionData'>) => {
    setNodes(
      nodes.map((_node) => {
        if (_node.type !== NodeTypesEnum.Image) return _node;
        const updateNode = WorkflowImageNode.parse(_node);
        if (updateNode.id === node.id) {
          return {
            ...updateNode,
            data: {
              ...updateNode.data,
              ...updatedData,
            },
          };
        }
        return updateNode;
      }),
    );
  };

  const updatePreviewVariable = () => {
    const variableId = action.variableId;
    if (!variableId) {
      throw new Error(`No variableId set for action ${action.id}`);
    }

    updateVariable({
      id: variableId,
      type: VariableTypeEnum.Template,
      data: [selectedAction.shortcutType],
    });
  };

  const updateAction = () => {
    const { variableId, targetId } = action;
    updateNodeActionData({
      actionData: {
        ...node.data.actionData,
        [selectedAction.id]: KeyboardShortcutAction.parse({
          ...selectedAction,
          // NOTE: Needed for backwards compatability
          variableId,
          targetId,
        }),
      },
    });
    updatePreviewVariable();
  };

  const handleSaveAndExit = () => {
    updateAction();
    onSaveAndExit();
  };

  useEffect(() => {
    const parsedAction = KeyboardShortcutAction.safeParse(action);
    if (parsedAction.success) {
      setSelectedAction(parsedAction.data);
    } else {
      captureException(parsedAction.error);
    }
  }, [action]);

  return (
    <div className="mt-10 flex flex-col gap-4 h-full justify-between">
      <div className="flex flex-col gap-4">
        <div>
          <Select
            label="Name"
            options={ALL_SHORTCUTS.map(({ shortcutType }) => shortcutType)}
            getLabel={(opt: string) => opt}
            getValue={(opt: string) => opt}
            value={selectedAction.shortcutType}
            placeholder="Select Shortcut"
            onChange={(evt) => {
              const actionToSet = ALL_SHORTCUTS.find(
                ({ shortcutType }) => shortcutType === evt.target.value,
              );
              if (!actionToSet) {
                throw new Error(`No action found for ${evt.target.value}`);
              }

              setSelectedAction({
                ...actionToSet,
                id: action.id,
              });
            }}
          />
        </div>

        <div className="!mt-4">
          <Input
            floatingLabel
            label="Group"
            value={selectedAction.shortcutGroup}
            disabled
          />
        </div>

        <div className="!mt-4">
          <Input
            floatingLabel
            label="Key Sequences"
            value={selectedAction.parameters.keySequences
              .map((seq) => seq.join(' + '))
              .join(' | ')}
            disabled
          />
        </div>

        <div className="!mt-4">
          <Input
            floatingLabel
            label="Platform"
            value={selectedAction.parameters.platform}
            disabled
          />
        </div>
      </div>

      <div className="w-full">
        <Button
          className="!text-info !border-info !mt-5 w-full"
          color="secondary"
          onClick={handleSaveAndExit}
          variant="outlined"
        >
          Save & Exit
        </Button>
      </div>
    </div>
  );
}
