import {
  Button,
  Modal,
  CustomTypography,
  Spinner,
  RemoveCircleOutlineIcon,
} from 'ui-kit';
import { clsx } from 'clsx';
import type {
  IConnectCredential,
  ParagonIntegrationEnum,
} from 'dashboard-shared';
import { useAccountIdentifiers, useAccountOperations } from '../hooks';

interface Props {
  open: boolean;
  onClose: () => void;
  platform: string;
  integrationType: ParagonIntegrationEnum;
  accounts: IConnectCredential[];
  isLoading?: boolean;
  iconUrl?: string;
}

export default function AccountsModal({
  open,
  onClose,
  platform,
  integrationType,
  accounts,
  isLoading = false,
  iconUrl,
}: Props) {
  const { data: accountIdentifiers = {}, isLoading: isLoadingIdentifiers } =
    useAccountIdentifiers(accounts, integrationType);

  const {
    disconnectingIds,
    reconnectingIds,
    isAddingAccount,
    handleDisconnect,
    handleAddAccount,
    handleReconnect,
  } = useAccountOperations(integrationType);

  // Show loading state if any of these conditions are true
  const isLoadingState = isLoading || isLoadingIdentifiers;

  return (
    <Modal
      open={open}
      onClose={onClose}
      className="!w-[480px]"
      containerClassName="!p-0"
      showCloseIcon={false}
    >
      <div className="flex flex-col w-full">
        {/* Header */}
        <div className="border-b border-gray-200 bg-gray-50 rounded-t-lg">
          <div className="px-5 py-4 flex items-center gap-3">
            {/* Platform Icon */}
            <div className="rounded-lg bg-white p-2 border border-gray-200 shadow-sm">
              {iconUrl ? (
                <img
                  src={iconUrl}
                  alt={platform}
                  className="w-6 h-6 object-contain"
                />
              ) : null}
            </div>
            <div className="flex-1">
              <CustomTypography variant="h5" className="text-gray-900">
                {platform}
              </CustomTypography>
              <CustomTypography variant="body2" className="text-gray-600">
                Manage connected accounts
              </CustomTypography>
            </div>
          </div>
        </div>

        {isLoadingState ? (
          <div className="px-5 py-12 flex flex-col items-center justify-center">
            <Spinner size={32} className="mb-3" />
            <CustomTypography variant="body2" color="text.secondary">
              Loading accounts...
            </CustomTypography>
          </div>
        ) : (
          <div className="flex flex-col">
            {/* Account List */}
            <div className="px-5 py-8">
              {accounts.length > 0 || isAddingAccount ? (
                <div className="space-y-2.5 w-full">
                  {accounts.map((account) => {
                    const isDisconnecting = disconnectingIds.has(account.id);
                    const isLoadingAccount = !(
                      account.id in accountIdentifiers
                    );
                    const isInvalid =
                      account.status ===
                      ('INVALID' as IConnectCredential['status']);

                    return (
                      <div
                        key={account.id}
                        className={clsx(
                          'px-4 py-2.5 bg-white rounded-lg border transition-all hover:bg-gray-50',
                          {
                            'opacity-50': isDisconnecting,
                            'border-error': isInvalid,
                            'border-gray-200': !isInvalid,
                          },
                        )}
                      >
                        <div className="flex items-center justify-between gap-3">
                          <div className="flex flex-col flex-1 min-w-0">
                            {isLoadingAccount ? (
                              <div className="flex items-center justify-center w-full">
                                <Spinner size={20} />
                              </div>
                            ) : (
                              <>
                                <CustomTypography
                                  variant="body2"
                                  className="text-gray-900 font-medium truncate leading-snug"
                                >
                                  {accountIdentifiers[account.id].primary}
                                </CustomTypography>
                                {accountIdentifiers[account.id].secondary ? (
                                  <CustomTypography
                                    variant="body2"
                                    className="text-gray-500 truncate leading-snug"
                                  >
                                    {accountIdentifiers[account.id].secondary}
                                  </CustomTypography>
                                ) : null}
                              </>
                            )}
                          </div>
                          <div className="flex items-center gap-2">
                            {isInvalid ? (
                              <Button
                                variant="text"
                                color="primary"
                                onClick={() => {
                                  void handleReconnect(account.id);
                                }}
                                className="!min-w-0 !p-2 text-sm"
                                disabled={reconnectingIds.has(account.id)}
                              >
                                {reconnectingIds.has(account.id) ? (
                                  <Spinner size={16} />
                                ) : (
                                  'Reconnect'
                                )}
                              </Button>
                            ) : null}
                            <Button
                              variant="text"
                              color="error"
                              onClick={() => {
                                void handleDisconnect(account.id);
                              }}
                              className="!min-w-0 !p-0 w-6 h-6"
                              disabled={disconnectingIds.has(account.id)}
                            >
                              {disconnectingIds.has(account.id) ? (
                                <Spinner size={16} />
                              ) : (
                                <RemoveCircleOutlineIcon className="w-4 h-4" />
                              )}
                            </Button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {isAddingAccount ? (
                    <div className="px-4 py-2.5 bg-white rounded-lg border border-gray-200 transition-all">
                      <div className="flex items-center justify-center w-full">
                        <Spinner size={20} />
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : (
                <div className="py-12 text-center">
                  <CustomTypography
                    variant="body1"
                    className="text-gray-900 font-medium mb-2"
                  >
                    No accounts connected
                  </CustomTypography>
                  <CustomTypography variant="body2" color="text.secondary">
                    Connect an account to start syncing your data
                  </CustomTypography>
                </div>
              )}
            </div>

            {/* Action Button */}
            <div className="px-5 py-6 border-t border-gray-200">
              <Button
                variant="contained"
                color="primary"
                onClick={() => void handleAddAccount()}
                className="w-full h-10 flex items-center justify-center"
                disabled={isAddingAccount}
              >
                <span className="leading-none">
                  {isAddingAccount ? 'CONNECTING...' : 'CONNECT NEW ACCOUNT'}
                </span>
              </Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}
