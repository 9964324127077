import { createContext, useContext, type ReactNode } from 'react';
import { client } from 'v2-api-client';
import { useAuth } from '../utils/helper';
import { adminAccessToken, isAdmin, v2ApiEndpoint } from '../utils/env';

const V2ApiContext = createContext(client);

export const useV2Api = () => {
  return useContext(V2ApiContext);
};

interface V2ApiProviderProps {
  children: ReactNode;
}

export function V2ApiProvider({ children }: V2ApiProviderProps) {
  const { getAccessTokenSilently } = useAuth();

  // Configure the client
  client.setConfig({
    baseURL: v2ApiEndpoint,
    headers: {}, // Initialize empty headers
    throwOnError: true,
    auth: async () => {
      // MARK: Admin Auth
      if (isAdmin) {
        if (!adminAccessToken) {
          throw new Error('v2-api: admin api key is required');
        }
        return adminAccessToken;
      }

      // MARK: User Auth
      return getAccessTokenSilently();
    },
  });

  return (
    <V2ApiContext.Provider value={client}>{children}</V2ApiContext.Provider>
  );
}
