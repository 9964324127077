import { Select, Input } from 'ui-kit';
import { useState, useEffect } from 'react';
import { isAdmin } from '../../utils/env';
import type { SelectChangeEvent } from '@mui/material/Select';

enum ModelSelectionMode {
  Default = 'Default',
  Custom = 'Custom',
}

interface ModelSelectProps {
  value: string | undefined;
  onChange: (value: string | undefined) => void;
}

export function ModelSelect({ value, onChange }: ModelSelectProps) {
  const [isCustom, setIsCustom] = useState(Boolean(value));
  const [customValue, setCustomValue] = useState(value ?? '');

  useEffect(() => {
    if (value) {
      setCustomValue(value);
      setIsCustom(true);
    } else {
      setIsCustom(false);
      setCustomValue('');
    }
  }, [value]);

  if (!isAdmin) return null;

  const handleModelChange = (event: SelectChangeEvent<ModelSelectionMode>) => {
    const selectedMode = event.target.value as ModelSelectionMode;
    const isCustomSelected = selectedMode === ModelSelectionMode.Custom;

    if (isCustomSelected) {
      setIsCustom(true);
    } else {
      setIsCustom(false);
      setCustomValue('');
      onChange(undefined);
    }
  };

  const handleCustomValueChange = (val: string) => {
    const trimmed = val.trim();
    setCustomValue(trimmed);
    onChange(trimmed || undefined);
  };

  return (
    <div className="flex flex-col gap-2 mb-6">
      <Select
        label="Model"
        value={
          isCustom ? ModelSelectionMode.Custom : ModelSelectionMode.Default
        }
        onChange={handleModelChange}
        options={Object.values(ModelSelectionMode)}
        getLabel={(mode) => mode}
        getValue={(mode) => mode}
      />
      {isCustom ? (
        <Input
          placeholder="Enter custom model name"
          value={customValue}
          onChange={handleCustomValueChange}
        />
      ) : null}
    </div>
  );
}
