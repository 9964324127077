import {
  type TypedDocumentNode,
  useQuery,
  type ApolloError,
} from '@apollo/client';
import {
  graphql,
  type TeamMember,
  type Get_TeamsQueryVariables as GetTeamsQueryVariables,
  type Get_TeamsQuery as GetTeamsQuery,
} from 'hasura-gql';
import { useTeamDetails } from '../../hooks/useTeamDetails.gql';
import pick from 'lodash/pick';

const GET_TEAMS = graphql(`
  query GET_TEAMS {
    organizations_teams {
      id
      name
      clusterConfiguration {
        clusterId
      }
    }
  }
`) as TypedDocumentNode<GetTeamsQuery, GetTeamsQueryVariables>;
interface Member {
  user: {
    email: string;
    id: string;
    workflows_aggregate: {
      aggregate?: {
        count: number;
      };
    };
  };
  roles: {
    role: {
      identifier: string;
      description?: string;
    };
  }[];
}

const GET_TEAM_MEMBERS = graphql(`
  query GetTeamMembers($teamId: String!) {
    organizations_team_memberships(where: { teamId: { _eq: $teamId } }) {
      user {
        email
        id
        workflows_aggregate(where: { deletedAt: { _is_null: true } }) {
          aggregate {
            count
          }
        }
      }
      roles {
        role {
          identifier
          description
        }
      }
    }
  }
`) as TypedDocumentNode<
  { organizations_team_memberships: Member[] },
  { teamId: string }
>;

export const useTeamMembers = (
  userId = '',
): {
  data?: TeamMember[];
  loading: boolean;
  error?: ApolloError;
  isAdmin: boolean;
} => {
  const {
    data: teamDetails,
    loading: teamDetailsLoading,
    error: teamDetailsError,
  } = useTeamDetails(userId);

  const teamId = teamDetails?.teamId ?? '';

  const { data, loading, error } = useQuery(GET_TEAM_MEMBERS, {
    variables: { teamId },
    skip: !teamId,
  });

  return {
    data: data?.organizations_team_memberships.map((item: Member) => ({
      id: item.user.id,
      email: item.user.email,
      role: item.roles[0]?.role.description ?? 'Member',
      workflowCount: item.user.workflows_aggregate.aggregate?.count ?? 0,
    })),
    loading: loading || teamDetailsLoading,
    error: error ?? teamDetailsError,
    isAdmin: teamDetails?.isAdmin ?? false,
  };
};

type Team = Pick<
  GetTeamsQuery['organizations_teams'][number],
  'id' | 'name'
> & {
  clusterId?: string;
};

export const useGetTeams = (): {
  data?: Team[];
  loading: boolean;
  error?: ApolloError;
} => {
  const { data, loading, error } = useQuery(GET_TEAMS);

  const teams = data?.organizations_teams.map((team) => ({
    ...pick(team, ['id', 'name']),
    clusterId: team.clusterConfiguration?.clusterId,
  }));

  return {
    data: teams,
    loading,
    error,
  };
};
