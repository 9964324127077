// NOTE: Duplicated from v2-api
export const validateVariables = (
  vars: unknown,
): Record<string, string> | undefined => {
  if (vars === undefined || vars === null) return undefined;

  if (typeof vars !== 'object' || Array.isArray(vars)) {
    throw new Error('Variables must be an object');
  }

  const entries = Object.entries(vars);
  const stringEntries = entries.map(([key, value]) => {
    if (typeof value !== 'string') {
      throw new Error(
        `Variable "${key}" must be a string, got ${typeof value}`,
      );
    }
    return [key, value] as [string, string];
  });

  return Object.fromEntries(stringEntries);
};
