"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createOpenStep = exports.parseOpenStep = exports.OpenStepParameters = void 0;
const replay_1 = require("@puppeteer/replay");
const types_shared_1 = require("types-shared");
const steps_1 = require("../../types/steps");
exports.OpenStepParameters = types_shared_1.z
    .object({
    application: types_shared_1.z.string().optional(),
    uri: types_shared_1.z.string().optional(),
    flags: types_shared_1.z.array(types_shared_1.z.string()).optional(),
    arguments: types_shared_1.z.array(types_shared_1.z.string()).optional(),
    waitDuration: types_shared_1.DurationString.optional(),
})
    .refine((params) => params.application || params.uri, {
    message: "At least one of 'application' or 'uri' is required.",
})
    .refine((params) => !(params.application && params.uri), {
    message: "Both 'application' and 'uri' are provided",
    path: ['parameters'],
});
const parseOpenStep = (id, step) => {
    return {
        id,
        type: replay_1.StepType.CustomStep,
        name: steps_1.DESKTOP_CUSTOM_STEP_TYPES.open,
        parameters: exports.OpenStepParameters.parse(step.parameters),
    };
};
exports.parseOpenStep = parseOpenStep;
const createOpenStep = (id, parameters) => {
    return {
        id,
        type: replay_1.StepType.CustomStep,
        name: steps_1.DESKTOP_CUSTOM_STEP_TYPES.open,
        parameters,
    };
};
exports.createOpenStep = createOpenStep;
