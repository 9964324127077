import { clsx } from 'clsx';
import { Button } from 'ui-kit';

export enum EmptyStateEnum {
  WORKFLOW = 'workflow',
  EXECUTION = 'execution',
  OUTPUTS = 'outputs',
  DONUT_GRAPH = 'donut-graph',
  BAR_GRAPH = 'bar-graph',
}

const EmptyStateComponentMapping = {
  [EmptyStateEnum.WORKFLOW]: (
    <img
      className="!w-96 !h-60 object-contain"
      src="/empty_workflow.svg"
      alt="Empty Workflow"
    />
  ),
  [EmptyStateEnum.EXECUTION]: (
    <img
      className="!w-96 !h-60 object-contain"
      src="/empty_execution.svg"
      alt="Empty Execution"
    />
  ),
  [EmptyStateEnum.OUTPUTS]: (
    <img
      className="!w-96 !h-60 object-contain"
      src="/empty_outputs.svg"
      alt="Empty Outputs"
    />
  ),
  [EmptyStateEnum.DONUT_GRAPH]: (
    <img
      className="!w-96 !h-40 mb-5 object-contain"
      src="/empty_donut_graph.svg"
      alt="Empty Outputs"
    />
  ),
  [EmptyStateEnum.BAR_GRAPH]: (
    <img
      className="!w-96 !h-40 mb-5 object-contain"
      src="/empty_bar_graph.svg"
      alt="Empty Outputs"
    />
  ),
};

const EmptyStateTitleMapping = {
  [EmptyStateEnum.WORKFLOW]: () => "No workflows yet? Let's get started!",
  [EmptyStateEnum.EXECUTION]: () =>
    'Run your workflow to track executions here',
  [EmptyStateEnum.OUTPUTS]: (isInput: boolean) =>
    `No ${isInput ? 'inputs' : 'outputs'} available for this execution`,
};

const EmptyStateDescriptionMapping = {
  [EmptyStateEnum.WORKFLOW]: () =>
    'Use a Sola recorder to capture your workflow process, then import it here to edit and run.',
  [EmptyStateEnum.EXECUTION]: () =>
    "Your workflow hasn't been executed yet. Once it runs, you'll be able to track its activity and results here.",
  [EmptyStateEnum.OUTPUTS]: (isInput: boolean) =>
    isInput
      ? 'This execution may not have any input values.'
      : 'This workflow is still running, or it may not have any output values.',
  [EmptyStateEnum.DONUT_GRAPH]: 'Run more executions to see insights',
  [EmptyStateEnum.BAR_GRAPH]: (isMonthly: boolean) =>
    `Run more executions ${isMonthly ? 'this month' : ''} to see insights`,
};

const EmptyStateButtonMapping = {
  [EmptyStateEnum.WORKFLOW]: 'Import workflow',
  [EmptyStateEnum.EXECUTION]: 'Edit workflow',
};

interface EmptyStatePlaceholderProps {
  className?: string;
  type: EmptyStateEnum;
  isInput?: boolean;
  isMonthly?: boolean;
  buttonProps?: {
    disabled?: boolean;
    loading?: boolean;
    onClick?: () => void;
  };
}

export default function EmptyStatePlaceholder({
  className,
  type,
  buttonProps,
  isInput = false,
  isMonthly = false,
}: EmptyStatePlaceholderProps) {
  const Component = EmptyStateComponentMapping[type];
  const title =
    type in EmptyStateTitleMapping
      ? EmptyStateTitleMapping[type as keyof typeof EmptyStateTitleMapping](
          isInput,
        )
      : '';
  const description =
    typeof EmptyStateDescriptionMapping[type] === 'function'
      ? EmptyStateDescriptionMapping[type](isInput || isMonthly)
      : EmptyStateDescriptionMapping[type];
  const buttonText =
    type in EmptyStateButtonMapping
      ? EmptyStateButtonMapping[type as keyof typeof EmptyStateButtonMapping]
      : undefined;

  return (
    <div
      className={clsx(
        'flex flex-col items-center justify-center p-8 text-center',
        className,
      )}
    >
      <div>{Component}</div>
      <div className="flex flex-col items-center justify-center max-w-[600px]">
        {title ? (
          <h3 className="text-xl text-info-dark font-semibold mb-2">{title}</h3>
        ) : null}
        {description ? <p className="text-gray-600">{description}</p> : null}
        {buttonText && buttonProps ? (
          <Button
            className="!mt-6"
            color="secondary"
            variant="contained"
            {...buttonProps}
          >
            {buttonText}
          </Button>
        ) : null}
      </div>
    </div>
  );
}
