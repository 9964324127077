import ReactECharts from 'echarts-for-react';
import { ChartColors } from '../../../utils/constants';
import { useMemo } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { useGetWorkflowExecutionMetrics } from '../hooks/useMetrics';
import dayjs from '../../../config/dayjs';
import EmptyStatePlaceholder, {
  EmptyStateEnum,
} from '../../../components/EmptyStatePlaceholder';

interface Props {
  autoRefresh: boolean;
  hasMinExecutionForCharts: boolean;
  workflowId: string;
}

export default function WorkflowExecutionMetrics({
  autoRefresh,
  hasMinExecutionForCharts,
  workflowId,
}: Props) {
  const { data, isLoading, error } = useGetWorkflowExecutionMetrics(
    workflowId,
    hasMinExecutionForCharts,
    autoRefresh,
  );

  const metricsData = useMemo(() => {
    if (!data) return;
    return data as {
      totalExecutions: number;
      executionsCount: Record<string, number>;
      monthlyExecutions: Record<string, number>[];
      hourlyExecutions: Record<string, number>[];
    };
  }, [data]);

  const hasMonthlyData = useMemo(() => {
    if (!metricsData?.monthlyExecutions) return false;
    return metricsData.monthlyExecutions.some((execution) =>
      Object.values(execution).some((val) => val > 0),
    );
  }, [metricsData?.monthlyExecutions]);

  const hasHourlyData = useMemo(() => {
    if (!metricsData?.hourlyExecutions) return false;
    return metricsData.hourlyExecutions.some((execution) =>
      Object.values(execution).some((val) => val > 0),
    );
  }, [metricsData?.hourlyExecutions]);

  const pieChartOptions = useMemo(
    () => ({
      tooltip: {
        trigger: 'item',
      },
      color: [ChartColors.SUCCESS, ChartColors.RUNNING, ChartColors.FAILURE],
      legend: {
        bottom: '5%',
        left: 'center',
        itemGap: 40,
      },
      series: [
        {
          type: 'pie',
          radius: ['40%', '65%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
          },
          top: '-30',
          data: [
            { value: metricsData?.executionsCount.SUCCESS, name: 'Success' },
            {
              value: metricsData?.executionsCount.RUNNING,
              name: 'Running & Queued',
            },
            { value: metricsData?.executionsCount.FAILED, name: 'Failure' },
          ],
        },
      ],
    }),
    [metricsData?.executionsCount],
  );

  const executionsMonthlyBarChartOptions = useMemo(() => {
    const rawData = ['SUCCESS', 'RUNNING', 'FAILED'].map((status) => {
      return metricsData?.monthlyExecutions.map((map) => map[status]);
    });

    const series = ['Success', 'Running & Queued', 'Failure'].map((name, i) => {
      return {
        name,
        type: 'bar',
        stack: 'total',
        barWidth: '70%',
        data: rawData[i],
        label: {
          show: false,
        },
        ...(i === 1
          ? {}
          : {
              itemStyle: {
                normal: {
                  barBorderRadius: i === 0 ? [0, 0, 3, 3] : [3, 3, 0, 0],
                },
              },
            }),
      };
    });

    const monthsList = [
      ...[5, 4, 3, 2, 1].map((diff) => {
        const month = dayjs().subtract(diff, 'month');
        return month.format('MMM');
      }),
      dayjs().format('MMM'),
    ];

    return {
      tooltip: {
        trigger: 'item',
      },
      legend: {
        bottom: '5%',
        left: 'center',
        itemGap: 40,
      },
      color: [ChartColors.SUCCESS, ChartColors.RUNNING, ChartColors.FAILURE],
      xAxis: {
        type: 'category',
        data: monthsList,
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
      },
      yAxis: {
        type: 'value',
        show: false,
      },
      series,
    };
  }, [metricsData?.monthlyExecutions]);

  const executionsHourlyBarChartOptions = useMemo(() => {
    const rawData = ['SUCCESS', 'RUNNING', 'FAILED'].map((status) => {
      return metricsData?.hourlyExecutions.map((map) => map[status]);
    });

    const series = ['Success', 'Running & Queued', 'Failure'].map((name, i) => {
      return {
        name,
        type: 'bar',
        stack: 'total',
        barWidth: '70%',
        data: rawData[i],
        label: {
          show: false,
        },
        ...(i === 1
          ? {}
          : {
              itemStyle: {
                normal: {
                  barBorderRadius: i === 0 ? [0, 0, 3, 3] : [3, 3, 0, 0],
                },
              },
            }),
      };
    });

    return {
      tooltip: {
        trigger: 'item',
      },
      legend: {
        bottom: '5%',
        left: 'center',
        itemGap: 40,
      },
      color: [ChartColors.SUCCESS, ChartColors.RUNNING, ChartColors.FAILURE],
      xAxis: {
        type: 'category',
        data: ['12am', '4am', '8am', '12pm', '4pm', '8pm'],
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
      },
      yAxis: {
        type: 'value',
        show: false,
      },
      series,
    };
  }, [metricsData?.hourlyExecutions]);

  return (
    <div className="mt-8 flex w-full space-x-4 items-stretch">
      <div className="flex flex-col flex-1 px-6 py-4 rounded-lg border">
        <div className="flex w-full justify-between items-center">
          <p className="text-base font-medium">
            Total executions: {metricsData?.totalExecutions ?? 0}
          </p>
          <span className="text-sm text-gray-500">Overall</span>
        </div>
        {isLoading ? (
          <div className="flex flex-col items-center justify-center space-y-4 m-auto">
            <Skeleton variant="circular" height={225} width={225} />
            <Skeleton
              variant="text"
              sx={{ fontSize: '1rem' }}
              className="w-full"
              height={30}
            />
          </div>
        ) : null}
        {metricsData?.totalExecutions && !isLoading ? (
          <ReactECharts
            className="zoom-countered-element"
            option={pieChartOptions}
          />
        ) : null}

        {(!metricsData?.totalExecutions || error) && !isLoading ? (
          <EmptyStatePlaceholder
            className="!mt-auto"
            type={EmptyStateEnum.DONUT_GRAPH}
          />
        ) : null}
      </div>
      <div className="flex flex-col space-y-1 flex-1 px-6 py-4 rounded-lg border">
        <p className="text-base font-medium">
          Busy hours
          <span className="text-gray-500 ml-1">last 30 days</span>
        </p>
        {isLoading ? (
          <div className="flex flex-col items-center justify-center space-y-4 w-full !mt-4">
            <Skeleton className="w-[90%]" variant="rectangular" height={225} />
            <Skeleton
              variant="text"
              sx={{ fontSize: '1rem' }}
              className="w-[90%]"
              height={30}
            />
          </div>
        ) : null}
        {hasHourlyData && !isLoading ? (
          <ReactECharts
            className="zoom-countered-element"
            option={executionsHourlyBarChartOptions}
          />
        ) : null}
        {(!hasHourlyData || error) && !isLoading ? (
          <EmptyStatePlaceholder
            className="!mt-auto"
            type={EmptyStateEnum.BAR_GRAPH}
            isMonthly
          />
        ) : null}
      </div>
      <div className="flex flex-col space-y-1 flex-1 px-6 py-4 rounded-lg border">
        <p className="text-base font-medium">Execution status over time</p>
        {isLoading ? (
          <div className="flex flex-col items-center justify-center space-y-4 w-full !mt-4">
            <Skeleton className="w-[90%]" variant="rectangular" height={225} />
            <Skeleton
              variant="text"
              sx={{ fontSize: '1rem' }}
              className="w-[90%]"
              height={30}
            />
          </div>
        ) : null}
        {hasMonthlyData && !isLoading ? (
          <ReactECharts
            className="zoom-countered-element"
            option={executionsMonthlyBarChartOptions}
          />
        ) : null}
        {(!hasMonthlyData || error) && !isLoading ? (
          <EmptyStatePlaceholder
            className="!mt-auto"
            type={EmptyStateEnum.BAR_GRAPH}
          />
        ) : null}
      </div>
    </div>
  );
}
