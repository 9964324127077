import { ParagonIntegrationEnum } from 'dashboard-shared';

/**
 * Integrations that should be hidden from the UI
 * TODO: Move this behind PostHog feature flags for better control
 */
export const HIDDEN_INTEGRATIONS = new Set<ParagonIntegrationEnum>([
  ParagonIntegrationEnum.SALESFORCE,
]);

/**
 * Integrations that should be shown but disabled (grayed out)
 * TODO: Move this behind PostHog feature flags for better control
 */
export const DISABLED_INTEGRATIONS = new Set<ParagonIntegrationEnum>([
  ParagonIntegrationEnum.GOOGLE_DRIVE,
]);
