import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { type z } from 'types-shared';
import { handleException } from 'sentry-browser-shared';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';

type ZodUrlParams<T extends z.ZodObject<z.ZodRawShape>> = z.infer<T>;

const getQueryParams = <T extends z.ZodObject<z.ZodRawShape>>(schema: T) => {
  const searchParamsEntries = new URLSearchParams(
    window.location.search,
  ).entries();
  const queryParamsObject = Object.fromEntries(searchParamsEntries);

  const parsedParams: Record<string, unknown> = {};

  for (const [key, fieldSchema] of Object.entries(schema.shape)) {
    if (key in queryParamsObject) {
      try {
        const decodedValue = decodeURIComponent(queryParamsObject[key]);
        const parseResult = fieldSchema.safeParse(JSON.parse(decodedValue));
        if (parseResult.success) {
          parsedParams[key] = parseResult.data as unknown;
        } else {
          handleException(new Error(), {
            name: 'URL query params failed schema validation',
            source: 'usePersistedURLState',
            extra: {
              key,
              value: parseResult,
              schema: fieldSchema,
            },
          });
        }
      } catch (error) {
        handleException(error, {
          name: 'URL query param data is invalid',
          source: 'usePersistedURLState',
          extra: {
            key,
            value: queryParamsObject[key],
          },
        });
      }
    }
  }

  return parsedParams;
};

const convertObjectToQuery = (
  obj: Record<string, unknown>,
): URLSearchParams => {
  const newQuery = new URLSearchParams();
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if (value === undefined) {
      return;
    }
    newQuery.append(key, encodeURIComponent(JSON.stringify(value)));
  });
  return newQuery;
};

export default function usePersistedURLState<
  T extends z.ZodObject<z.ZodRawShape>,
>(
  schema: T,
  initialState: Partial<ZodUrlParams<T>>,
  storageKey: string,
): [ZodUrlParams<T>, (state: Partial<ZodUrlParams<T>>) => void] {
  const navigate = useNavigate();
  const queryParamsRef = useRef<ZodUrlParams<T>>({} as ZodUrlParams<T>);

  const persistedStateObject = useMemo(() => {
    const persistedState = localStorage.getItem(storageKey);
    if (persistedState) {
      const parsedState = JSON.parse(persistedState) as Record<string, unknown>;
      const parseResult = schema.safeParse(parsedState);
      if (parseResult.success) {
        return parseResult.data;
      }
      const errorName = 'Persisted URL state is invalid';
      handleException(new Error(errorName), {
        name: errorName,
        source: 'usePersistedURLState',
        extra: {
          persistedState,
          parseResult,
        },
      });
    }
    return {} as ZodUrlParams<T>;
  }, [schema, storageKey]);

  const initialStateFromURL = useMemo(
    () => ({
      ...initialState,
      ...(!isEmpty(getQueryParams(schema))
        ? getQueryParams(schema)
        : persistedStateObject),
    }),
    [schema, initialState, persistedStateObject],
  );
  queryParamsRef.current = initialStateFromURL;

  const [state, setState] = useState<ZodUrlParams<T>>(initialStateFromURL);

  const updateState = (newState: Partial<ZodUrlParams<T>>) => {
    const parseResult = schema.partial().safeParse(newState);
    if (parseResult.success) {
      const validatedState = omitBy(parseResult.data, isNil);
      const newQuery = convertObjectToQuery(validatedState);
      navigate({ search: newQuery.toString() }, { replace: true });
      setState(validatedState);
      localStorage.setItem(storageKey, JSON.stringify(validatedState));
    } else {
      handleException(new Error(), {
        name: 'URL state update failed',
        source: 'usePersistedURLState',
        extra: {
          parseResult,
          newState,
          schema,
        },
      });
    }
  };

  useEffect(() => {
    const currentParams = getQueryParams(schema);
    const initialParams = queryParamsRef.current;
    const newParams: Partial<ZodUrlParams<T>> = {};
    for (const key in initialParams) {
      if (!(key in currentParams) && !isNil(initialParams[key])) {
        newParams[key] = initialParams[key];
      }
    }
    if (!isEmpty(newParams)) {
      navigate(
        { search: convertObjectToQuery(newParams).toString() },
        { replace: true },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [state, updateState];
}
