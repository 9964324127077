import { useGetParagonUserToken, useParagonAuth } from './hooks';
import {
  ContentContainer,
  ScrollableContainer,
  CustomTypography,
  Flex,
} from 'ui-kit';
import { NavigationPanel } from '../../components/NavigationPanel';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FeatureFlag } from '../../utils/constants';
import IntegrationTile from './components/IntegrationTile';
import ComingSoonIntegrationTile from './components/ComingSoonIntegrationTile';
import { useFeatureFlag } from '../../utils/helper';
import { useAPI } from '../../hooks/useApi';
import type {
  AuthenticatedConnectUser,
  IConnectCredential,
  ParagonIntegrationEnum,
} from 'dashboard-shared';
import { clsx } from 'clsx';

const getIntegrationAccounts = (
  user: AuthenticatedConnectUser | undefined,
  integrationType: ParagonIntegrationEnum,
): IConnectCredential[] => {
  const integration = user?.integrations[integrationType];

  if (!integration?.enabled) {
    return [];
  }

  // Return all credentials, including invalid ones
  return integration.allCredentials;
};

function IntegrationTileSkeleton() {
  return (
    <div className="min-w-80 max-w-80 h-52 rounded-lg border border-gray-200 bg-gray-50 animate-pulse relative overflow-hidden">
      <div className="w-full h-full flex items-center justify-center">
        <div className="w-20 h-20 bg-gray-200 rounded-lg" />
      </div>
      {/* Add a subtle shimmer effect */}
      <div className="absolute top-0 -left-full w-full h-full bg-gradient-to-r from-transparent via-white/20 to-transparent animate-[shimmer_2s_infinite] transform translate-x-full" />
      {/* Add skeleton for the bottom status bar */}
      <div className="absolute inset-x-0 bottom-0 bg-gray-100 border-t border-gray-200">
        <div className="flex items-center justify-between px-4 py-3">
          <div className="w-20 h-4 bg-gray-200 rounded" />
        </div>
      </div>
    </div>
  );
}

function IntegrationContent({
  children,
  isLoading,
}: {
  children: React.ReactNode;
  isLoading: boolean;
}) {
  const [shouldRender, setShouldRender] = useState(false);

  // Delay rendering slightly to ensure CSS transitions work
  useEffect(() => {
    if (!isLoading) {
      const timer = setTimeout(() => {
        setShouldRender(true);
      }, 50);
      return () => {
        clearTimeout(timer);
      };
    }
    setShouldRender(false);
  }, [isLoading]);

  return (
    <div
      className={clsx(
        'transition-all duration-500 ease-in-out',
        shouldRender
          ? 'opacity-100 transform translate-y-0'
          : 'opacity-0 transform translate-y-4',
      )}
    >
      {children}
    </div>
  );
}

export default function Integrations() {
  const navigate = useNavigate();
  const integrationsPageEnabled = useFeatureFlag(FeatureFlag.IntegrationsPage);
  const { data: paragonUserToken, isLoading: isLoadingToken } =
    useGetParagonUserToken();
  const { data: user, error } = useParagonAuth(paragonUserToken?.token);
  const { paragonSDK: sdk } = useAPI();
  const [isTransitioning, setIsTransitioning] = useState(true);

  const isInitializing = isLoadingToken || !user;
  const hasError = Boolean(error);

  // Handle transition state
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isInitializing) {
      setIsTransitioning(true);
    } else {
      // Add a small delay to ensure smooth transition
      timer = setTimeout(() => {
        setIsTransitioning(false);
      }, 300);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [isInitializing]);

  const integrationTiles = useMemo(() => {
    if (!user || error) return null;

    return sdk.getIntegrationMetadata().map((integration) => {
      const accounts = getIntegrationAccounts(
        user,
        integration.type as ParagonIntegrationEnum,
      );
      const integrationData = user.integrations[integration.type];

      const hasInvalidCredentials = accounts.some(
        (credential) =>
          credential.status === ('INVALID' as IConnectCredential['status']),
      );

      const isConnected = Boolean(
        integrationData?.enabled &&
          accounts.length > 0 &&
          !hasInvalidCredentials,
      );

      return (
        <IntegrationTile
          iconUrl={integration.icon}
          isConnected={isConnected}
          hasInvalidCredentials={hasInvalidCredentials}
          key={integration.type}
          displayName={integration.name}
          integrationType={integration.type as ParagonIntegrationEnum}
          accounts={accounts}
        />
      );
    });
  }, [user, error, sdk]);

  useEffect(() => {
    if (integrationsPageEnabled === false) {
      navigate('/');
    }
  }, [integrationsPageEnabled, navigate]);

  let content: React.ReactNode;
  if (isInitializing || isTransitioning) {
    content = (
      <Flex className="gap-2 flex-wrap">
        <IntegrationTileSkeleton />
        <IntegrationTileSkeleton />
        <IntegrationTileSkeleton />
      </Flex>
    );
  } else if (hasError) {
    content = (
      <div className="w-full text-center py-8 text-gray-500 transition-opacity duration-300">
        Unable to load integrations. Please try again later.
      </div>
    );
  } else {
    content = (
      <IntegrationContent isLoading={false}>
        <Flex className="gap-2 flex-wrap">
          {integrationTiles}
          <ComingSoonIntegrationTile />
        </Flex>
      </IntegrationContent>
    );
  }

  return (
    <ScrollableContainer>
      <div className="w-full h-full flex">
        <NavigationPanel />
        <ContentContainer>
          <div className="flex justify-between w-full items-center">
            <div>
              <CustomTypography variant="h4">Integrations</CustomTypography>
              <div className="mt-2">
                <span className="font-normal text-sm">
                  Configure external platforms and connect them with your
                  workflows. You can connect multiple accounts for each
                  integration.
                </span>
              </div>
            </div>
          </div>
          <div className="mt-11">{content}</div>
        </ContentContainer>
      </div>
    </ScrollableContainer>
  );
}
