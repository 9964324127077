import type {
  LlmTransformResponse,
  QueueTransformWithQueriesResponse,
  RetrieveTransformWithQueriesResponse,
  ZodFetcher,
} from 'api-types-shared';
import {
  llmTransformSchema,
  queueTransformWithQueriesSchema,
  retrieveTransformWithQueriesSchema,
} from 'api-types-shared';
import type { KyInstance, Options } from 'ky';
import type { NodeEnv } from 'ui-kit';
import { apiEndpoints } from 'ui-kit';
import { createZodKyFetcher } from '../fetcher';
import { handleException } from 'sentry-browser-shared';

export class TransformSDK {
  readonly endpoint: string;
  private _kyFetcher: ZodFetcher<KyInstance>;

  constructor(env: NodeEnv, kyOpts?: Options) {
    this.endpoint = apiEndpoints[env].transformApiV1;
    this._kyFetcher = createZodKyFetcher(kyOpts);
  }

  async transform({
    data,
    prompt,
    example,
    model,
    cache = true,
  }: {
    data: string;
    prompt: string;
    example?: { input: string; output: string };
    model: string;
    cache?: boolean;
  }): Promise<LlmTransformResponse | null> {
    const request = llmTransformSchema.request.parse({
      params: {},
      query: {},
      body: {
        data,
        prompt,
        cache,
        example,
        model,
      },
    });
    const response = await this._kyFetcher(
      llmTransformSchema.response,
      `${this.endpoint}/llm-transform`,
      {
        method: 'post',
        json: request.body,
      },
    ).catch((err: unknown) => {
      handleException(err, {
        name: 'Transform data fetch failed',
        source: 'TransformSDK.transform',
        extra: { request },
      });
      // Throw all errors, so we can handle them in the UI
      if (err) {
        throw new Error(
          'Transformation is still processing, please try again later.',
        );
      }
      return null;
    });
    return response;
  }

  async queueTransformWithQueries({
    fileId,
    prompts,
  }: {
    fileId: string;
    prompts: Record<string, string>;
  }): Promise<QueueTransformWithQueriesResponse> {
    const request = queueTransformWithQueriesSchema.request.parse({
      params: { fileId },
      query: {},
      body: { prompts },
    });

    const response = await this._kyFetcher(
      queueTransformWithQueriesSchema.response,
      `${this.endpoint}/${fileId}/transform-with-queries`,
      {
        method: 'post',
        json: request.body,
      },
    ).catch((err: unknown) => {
      handleException(err, {
        name: 'Queue transform with queries failed',
        source: 'TransformSDK.queueTransformWithQueries',
        extra: { request },
      });
      throw err;
    });
    return response;
  }

  async retrieveTransformWithQueries({
    fileId,
    jobId,
  }: {
    fileId: string;
    jobId: string;
  }): Promise<RetrieveTransformWithQueriesResponse> {
    const request = retrieveTransformWithQueriesSchema.request.parse({
      params: { fileId, jobId },
      query: {},
      body: {},
    });

    const response = await this._kyFetcher(
      retrieveTransformWithQueriesSchema.response,
      `${this.endpoint}/${fileId}/transform-with-queries/${jobId}`,
      {
        method: 'get',
      },
    ).catch((err: unknown) => {
      handleException(err, {
        name: 'Retrieve transform with queries failed',
        source: 'TransformSDK.retrieveTransformWithQueries',
        extra: { request },
      });
      throw err;
    });
    return response;
  }
}
